import React from 'react';
import {Bar} from 'react-chartjs-2';
import { useAuths } from '../../../context';


const Bar_Chart = () => {
  const {state} = useAuths()
  const states = {
    labels: 'top',
  datasets: [{
    label: 'Top reasons for recruiter rejection',
    data: [65, 59, 80, 81, 56, 55, 40],
    fill: false,
    backgroundColor: [
      '#DDF9FF'
    ],
    borderColor: [
      '#DDF9FF'
    ],
    barPercentage: 1.0,
    borderWidth: 1
  }]
  };

  return(
    <div className='barchart'>
       <Bar
          data={states}
          options={{
            indexAxis: 'y',
            title:{
              display:true,
              text:'Top reasons for recruiter rejection',
              fontSize:20
            },
            legend:{
              display:false,
              position:'right'
            }
          }}
        />
    </div>
  )
}

export default Bar_Chart