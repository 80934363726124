import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import '../../assets/todo'
import LineChart from './Charts/Line_chart';
import moment from 'moment';
import numeral from 'numeral';
import { useAuths } from '../../context';

const Dashboard = () => {
    const {get_dasboard_data, state, completeTodo} = useAuths()


    useEffect(() => {
        get_dasboard_data("weekly")
    }, [])
    return (
        <main>
        <div>
            <div className="mb-7">
                <h1 className="main-title">Dashboard</h1>
            </div>
            <div className="row">
                <div className="col-12 col-md-8 col-xs-12">
                     {/* cards  */}
                    <div className="cards">
                        <div className="card-single" >
                            <div className="lf card-icon cl-blue">
                                <span className="iconify-inline"><Icon icon="gridicons:multiple-users"/></span>
                              </div>
                            <div className="card-title lf">
                                <h1>{numeral(state.total_candidates).format('0a')}</h1>
                                <span>Candidates</span>
                            </div>
                        </div>
                        
                        <div className="card-single" >
                            <div className="lf card-icon cl-green">
                                <span className="iconify-inline"><Icon icon="ic:round-voice-chat"/></span>
                              </div>
                            <div className="card-title lf">
                                <h1>{numeral(state.interviews.length).format('0a')}</h1>
                                <span>Interviews</span>
                            </div>
                        </div>

                        <div className="card-single" >
                            <div className="lf card-icon cl-yellow">
                                <span className="iconify-inline"><Icon icon="fluent:person-feedback-24-filled"/></span>
                              </div>
                            <div className="card-title lf">
                                <h1>{numeral(state.awaiting_feedback).format('0a')}</h1>
                                <span>Awaiting Feedback</span>
                            </div>
                        </div>
                    </div>

                     {/* candidate   */}
                    <div className="graph mb-4">
                        <div className="d-flex justify-content-between">
                            <h2 className="main-sub-title">
                                Candidates
                            </h2>
                            <select className="form-select transparent" aria-label="chart" onChange={e => {get_dasboard_data(e.target.value)}}>
                                <option selected value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                              </select>
                        </div>
                        <div className="graph-chart">
                                  <div>
                                    {/* <canvas id="performaneLine"></canvas> */}
                                    <LineChart/>
                                  </div>
                            </div>
                    </div>

                    {/* activity  */}
                    <div className="activities mt-4">
                        <h2 className="main-sub-title">
                            Activities
                        </h2>
                        <div className="activity bg-whitee">
                            <ul>
                            <div className="card-title activity-date">
                                    <span>{moment().format("D MMMM Y")}</span>
                                </div>
                                {state.recentActivities.map(i => (moment(i.createdAt).format("D MMMM Y") === moment().format("D MMMM Y") ? <li>
                                <div className="card-title activity-date">
                                </div>
                                    <div className="activity-tag">
                                        <div className="activity-icon">
                                            <span className="iconify-inline">{i.type === "email" ? <Icon icon="bi:send-check"/> : <Icon icon="carbon:chat"/>}</span>
                                        </div>
                                        <div className="activity-text">
                                            {i.narration}
                                        </div>
                                    </div>
                                </li> : null))}
                            </ul>
                            
                            <ul>
                                <div className="card-title activity-date">
                                    <span>{moment().subtract(1, "day").format("D MMMM Y")}</span>
                                </div>
                                {state.recentActivities.map(i => (moment(i.createdAt).format("D MMMM Y") === moment().subtract(1, "day").format("D MMMM Y") ? <li>
                                <div className="card-title activity-date">
                                </div>
                                    <div className="activity-tag">
                                        <div className="activity-icon">
                                            <span className="iconify-inline">{i.type === "email" ? <Icon icon="bi:send-check"/> : <Icon icon="carbon:chat"/>}</span>
                                        </div>
                                        <div className="activity-text">
                                            {i.narration}
                                        </div>
                                    </div>
                                </li> : null))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-xs-12">
                   
                    <div className="todo">
                        <div className="card-head">
                            <div className="card-header-title">
                                <h2>Todo List</h2>
                            </div>
                            <div>
                                <Link to="/calendar" className="btn-cal cl-blue">Calendar<span className="iconify-inline"><Icon icon="bx:bx-chevron-right"/></span> </Link>
                            </div>
                        </div>

                        <hr/>

                        <div className="list-wrapper card-body-card">
                            <ul className="todo-list todo-list-rounded">

                              {state.todos.map(i => (<li>
                                <div className="todo-tag form-check w-100 ">
                                    <div className="todo-tag-left">
                                        <label className="form-check-label">
                                            <input className="checkbox todo-checkbox" checked={i.done} type="checkbox" id="todo-check" onChange={() => {completeTodo(i._id, !i.done)}}/> 
                                             <label className="unticked" for="todo-check"><span className="iconify-inline"><Icon icon="icomoon-free:radio-unchecked"/></span> </label>
                                            <label  className="ticked" for="todo-check"><span className="iconify-inline"><Icon icon="teenyicons:tick-circle-solid"/></span></label>
                                          </label>

                                        <div className="tg-r">
                                            <h3 className="tag-text">{i.title}</h3>
                                            <span>{moment(i.startDate).format("D MMMM Y")}</span>
                                        </div>
                                    </div>
                                    <div className="todo-tag-right">
                                        <p>{moment(i.startDate).format("hh:mma")} - {moment(i.endDate).format("hh:mma")}</p>
                                    </div>
                                </div>
                            </li>))}

                            </ul>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
        </div>
    </main>
    )
}

export default Dashboard