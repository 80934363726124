import React from 'react'
import Dragable from './draggleble';
import { useAuths} from '../../context';
import { useDrop } from 'react-dnd'
import { Droppable } from 'react-beautiful-dnd';


function Stage({id, name, data, job, index}) {
 //const { dropData } = useAuths()
 

 /*const [{isApplicationOver}, dropApplication] = useDrop(() => ({
      accept: "div",
      drop: (item) => dropData(id, item.id, job, data),
      collect: (monitor) => ({
            isApplicationOver: !!monitor.isOver()
        })
    }))*/

//const newData = data.filter(i => (!i.rejected && !i.withdraw && !i.delete))

 var colors = ['#6CB6FF', '#E2F1FF', '#D4B2FF', '#A7FFC0', '#FFE2F3']
  return (
    <Droppable droppableId={id}>
    {(provided)=> (
            <div 
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="boards" //style={{border: isApplicationOver ? '1px solid green' : 'none'}} ref={dropApplication}
            >
            <div className="boarder-header d-flex align-items-center">{name}<span className="ml-1 mt-1" style={{backgroundColor: colors[Math.floor(Math.random() * colors.length)], width: "21px", height: "21px", borderRadius: "10px", textAlign: "center"}}>{data.filter(i => (!i.delete && !i.withdraw && !i.rejected)).length}</span></div>
                {
                 data.map((i) => (!i.delete && !i.withdraw && !i.rejected ?  <div className="pt-6 draggable" key={i._id}>
                        <Dragable tags={i.tag} job={job} index={index}  key={i._id} id={i._id} name={i.owner ? i.owner.first_name+" "+i.owner.last_name : i.candidate_name} img={i.owner ? i.owner.image : i.candidate_image} appliedDate={i.createdAt} exId={id}/>
                        </div>
                    : null))
            }
            {provided.placeholder}
            </div>)}
    </Droppable>
  
  );
}

export default Stage;
