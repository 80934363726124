import React, { useEffect } from 'react'
import { Icon } from '@iconify/react'
//import { Link } from 'react-router-dom'
//import { InputGroup, Input} from 'reactstrap'
//import profile from "../../assets/profile-img.png"
//import  { useAuths }  from '../../context';
//import hass from "../../assets/hassan.png"
//import funmi from "../../assets/funmi.png"
//import charlene from "../../assets/charlene.png"
//import nelson from "../../assets/nelson.png"
import MyInbox from './Mailbox/my_inbox'
import MySent from './Mailbox/my_sent'
import MyDraft from './Mailbox/my_draft'
import TemplatesTag from './Mailbox/templates'
import { useAuths } from '../../context'

export default function Mailbox() {

const { fetchEmails, currentUser } = useAuths()
useEffect(() => {
    fetchEmails();
    
}, [])

    return (
        <main className="nopad-main">
                <div className="mb-7">
                    <h1 className="main-title">Mailbox</h1>
                </div>
                
            {/* <div className='new-mailbox '>
                <div className='new-mailbox-sidemenu'>
                    Mailbox Sidemenu
                </div>
                <div className='new-mailbox-items'>
                    <div className='new-mailbox-list'>
                        Mailbox list of mails
                    </div>
                    <div className='new-mailbox-opened'>
                        mailbox email opened/thread
                    </div>
                </div>
                
            </div> */}

          <div className="border new-mailbox">
                    <div className="p-0 col-lg-2  border-right new-mailbox-sidemenu">
                        <div className="nav  tab-link mt-5 flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                          
                                <div className="pt-2 pb-2 ml-4">
                                    <p>My Email</p>
                                </div>
                                <a className="nav-link  active" id="v-pills-myinbox-tab" data-toggle="pill" href="#v-pills-myinbox" role="tab" aria-controls="v-pills-myinbox" aria-selected="true"><Icon icon="fluent:mail-inbox-28-filled"/> Inbox</a>
                                <a className="nav-link" id="v-pills-mysent-tab" data-toggle="pill" href="#v-pills-mysent" role="tab" aria-controls="v-pills-mysent" aria-selected="false"><Icon icon="bi:send-fill"/> Sent</a>
                                <a className="nav-link" id="v-pills-mydraft-tab" data-toggle="pill" href="#v-pills-mydraft" role="tab" aria-controls="v-pills-mydraft" aria-selected="false"><Icon icon="ri:draft-fill"/> Draft</a>
                                <div className="pt-2 pb-2 ml-4">
                                    <p>Email Settings</p>
                                </div>
                                <a className="nav-link" id="v-pills-template-tab" data-toggle="pill" href="#v-pills-template" role="tab" aria-controls="v-pills-template" aria-selected="false"><Icon icon="fluent:mail-template-16-regular"/>  Templates</a>
                                <hr/>
                        </div>
                    </div>

                     <div className="tab-content w-100" id="v-pills-tabContent">
                        <div className="tab-pane fade show active" id="v-pills-myinbox" role="tabpanel" aria-labelledby="v-pills-myinbox-tab"><MyInbox/></div>
                        <div className="tab-pane fade" id="v-pills-mydraft" role="tabpanel" aria-labelledby="v-pills-mydraft-tab"><MyDraft/></div>
                        <div className="tab-pane fade" id="v-pills-mysent" role="tabpanel" aria-labelledby="v-pills-mysent-tab"><MySent/></div>
                        <div className="tab-pane fade" id="v-pills-teaminbox" role="tabpanel" aria-labelledby="v-pills-teaminbox-tab"><MyInbox/></div>
                        <div className="tab-pane fade" id="v-pills-teamsent" role="tabpanel" aria-labelledby="v-pills-teamsent-tab"><MyInbox/></div>
                        <div className="tab-pane fade" id="v-pills-template" role="tabpanel" aria-labelledby="v-pills-template-tab"><TemplatesTag/></div>
                        </div>
                    </div>
                </main>
    )
}
