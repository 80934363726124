import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import moment from 'moment'
import { useAuths } from '../../context'

const Calender = () => {
    const {state} = useAuths()
    const getEvents = state.todos.map(i  => {
        return {
            title: i.title,
            date: `${moment(i.startDate).format("Y-MM-D")}`
        }
    })
    return (
        <main>
            <div className="mb-7">
                <h1 className="main-title">Calendar</h1>
            </div>
            <div className="bg-whitee p-5">
             
                    <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        weekends={true}
                        events={getEvents}
                        />
            </div>
        </main>
   
    )
}

export default Calender