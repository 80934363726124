import React, { useEffect } from 'react'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import { InputGroup, Input} from 'reactstrap'
//import profile from "../../../assets/profile-img.png"
//import  { useAuths }  from '../../../context';
import empty from "../../../assets/empty.png"
import { format } from 'timeago.js';
import { useAuths, ACTIONS } from '../../../context'
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner } from "reactstrap"
  import CreatableSelect from 'react-select/creatable';
  import { Editor } from "react-draft-wysiwyg";
  import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function My_inbox() {
  const {dispatch, state, sentCurrentSent, handleSelectedEmail, handleSelectedDocuments, removeDocument, onEditorStateChange, sendEmail, deleteEmail, searchEmails} = useAuths()

  useEffect(() => {
    state.sent_emails.length > 0 &&  sentCurrentSent(state.sent_emails[0]._id)
  }, [])

  const DeleteModal = () =>{
    
    return(
             <div>
               {!state.loading ? <div className="modal-content p-2 border-0 text-center">
                            <div >
                                <div className='mb-4'>
                                    <h4>Delete Email?</h4>
                                </div>
                                <div className='d-flex justify-content-between'>
                                   
                                <button className='btn btn-dark' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: ""}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}}>Abort</button>
                                    <button className='btn btn-danger' style={{borderRadius: "20px"}} onClick={e => {e.preventDefault(); deleteEmail(state.current_sent.application._id, state.emailId)}}>Confirm</button>
                                </div></div></div>: <Spinner/>}
             </div>
    )

   }

    return (
      <div>
        <div className='new-mailbox-items'>
        <div className="new-mailbox-list   mb-4 border-right bg-white">
                <div className="border-bottom pb-3 pt-3 px-3">
                  <div className="search-wrapper w-100 lf">
                        <InputGroup>
                        <span className="iconify-inline"><Icon icon="bx:bx-search"/></span>
                            <Input onChange={e => {searchEmails("sentbox", e.target.value)}} placeholder="Search mail"/>
                        </InputGroup>
                         </div>

                </div>
                <div className="scroll_segment pt-3 p-2">
                {state.sent_emails.map(i => ( i.application ?<div className={i._id === state.current_sent._id ? "mail-list opened-mail border-bottom mail-list-active" : "mail-list opened-mail border-bottom"} onClick={e => {e.preventDefault(); sentCurrentSent(i._id)}}>
                 <div className="sender-img">
                 <img width="43px" height="43px" style={{borderRadius: "20px"}} src={i.application.owner && i.application.owner.image ? i.application.owner.image : i.application.candidate_image ? i.application.candidate_image : empty} alt="" />
                 </div>
                  <div className="content">
                    <p className="sender-name">{i.application.owner ? i.application.owner.first_name+' '+i.application.owner.last_name : i.application.candidate_name}</p>
                    <p className="mail-subject">{i.subject}</p>
                    <p className="message_text" >{i.thread.length > 0 ? <div dangerouslySetInnerHTML={{ __html: i.thread[0].text }} /> : null}</p>
                  </div>
                  <div className="details">
                    <p className='date'>{format(i.createdAt)}</p>
                  </div>
                </div>: null))}
                </div>
              </div>

              <div className="new-mailbox-opened">
              {state.email_loading ? <div className='email-loading-top text-center'>Loading...</div> : null}

                <div className="row d-flex  align-items-center p-5">
                  <div>
                  <img className="img-sm rounded-circle mr-3" width="70px" height="70px" src={state.current_sent.application && state.current_sent.application.owner ? state.current_sent.application.owner.image : state.current_sent.application && state.current_sent.application.candidate_image ? state.current_sent.application.candidate_image : empty} alt=""/>
                  </div>
                  <div>
                     <p className="msg-sender ">
                       {state.current_sent.application ? state.current_sent.application.owner ? state.current_sent.application.owner.first_name+' '+state.current_sent.application.owner.last_name : state.current_sent.application.candidate_name : "N/A"}
                      </p>
                     <p> <span className="active-label mr-2"></span>{state.current_sent.application ? state.current_sent.application.job.jobrole : "N/A"}</p>
                  </div>
                </div>
                
                <ul>
                  {/* first email  */}
                  <li >
                  {/* <div className="d-flex  align-items-center  justify-content-between pl-2 pr-5 mr-5">
                  <div className="mail-title">
                    <h3>{state.current_sent.subject ? state.current_sent.subject : "N/A"}</h3>
                  </div>
                  <div className="mail-toolbar">
                    <div className="btn-group mail-icons">
                      <a href=" " onClick={e => {e.preventDefault(); const emails = state.current_sent.receipents[0].split(","); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: true}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id}); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: [{label: emails[0], value: emails[0]}]})}} className="pr-2"><Icon icon="bi:reply-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); const emails = state.current_sent.receipents[0].split(","); const getMails = emails.map(i => {return {value: i, label: i}}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: true}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id});dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: getMails}) }}><Icon icon="bi:reply-all-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: []}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id}) ;dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: true})}}><Icon icon="bi:forward-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id}) ;dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}}><Icon icon="fluent:delete-24-filled"/>  </a>
                    </div>
                  </div>
                </div> */}

                <div className="message-body  p-3">
                {/* <div className="d-flex  align-items-center  justify-content-between pl-2 pr-5">
                  <div className="mail-title">
                    <div className="row d-flex  align-items-center pl-3 pr-3 pt-5">
                      <div className="mailer-icon rounded-circle bg-primary text-white p-3 mr-2">
                        <h3>{state.current_sent.application ? state.current_sent.application.owner ? state.current_sent.application.owner.first_name.split('')[0]+''+state.current_sent.application.owner.last_name.split('')[0] :state.current_sent.application.candidate_name.split(" ")[1] ? state.current_sent.application.candidate_name.split(" ")[0].split("")[0]+""+state.current_sent.application.candidate_name.split(" ")[1].split("")[0] : state.current_sent.application.candidate_name.split(" ")[0].split("")[0] : "N/A"}</h3>
                      </div>
                      <div>
                       <p className="msg-sender ">
                       {state.current_sent.application ? state.current_sent.application.owner ? state.current_sent.application.owner.first_name+' '+state.current_sent.application.owner.last_name : state.current_sent.application.candidate_name : "N/A"}
                          </p>
                        <p>To: {state.current_sent.receipents ? state.current_sent.receipents[0] : "N/A"}</p>
                      </div>
                    </div>
                  </div>

                  <div className="mail-toolbar">
                    <p>{format(state.current_sent.createdAt)}</p>
                  </div>
                </div> */}
                
                { state.current_sent.thread ? state.current_sent.thread.map(i => (<div key={i._id} >
                    {/* <p>To: {i.receipents ? i.receipents[0] : "N/A"}</p> */}
                  <div className="message-content">
                    
                  <div className="d-flex  align-items-center  justify-content-between pl-3 pr-3 pb-4 ">
                  <div className="mail-title">
                    <h3>{state.current_sent.subject ? state.current_sent.subject : "N/A"}</h3>
                  </div>
                  <div className="mail-toolbar">
                    <div className="btn-group mail-icons">
                      <a href=" " onClick={e => {e.preventDefault(); const emails = state.current_sent.receipents[0].split(","); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: true}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id}); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: [{label: emails[0], value: emails[0]}]})}} className="pr-2"><Icon icon="bi:reply-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); const emails = state.current_sent.receipents[0].split(","); const getMails = emails.map(i => {return {value: i, label: i}}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: true}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id});dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: getMails}) }}><Icon icon="bi:reply-all-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: []}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id}) ;dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: true})}}><Icon icon="bi:forward-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: state.current_sent._id}) ;dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}}><Icon icon="fluent:delete-24-filled"/>  </a>
                    </div>
                  </div>
                </div>

                <div className="message-body-top pl-2 pr-5 mr-5">
                  
                  <div className="mail-title message-body-left">
                      <div className="mailer-icon  bg-primary text-white p-3 mr-2">
                        <h3>{state.current_sent.application ? state.current_sent.application.owner ? state.current_sent.application.owner.first_name.split('')[0]+''+state.current_sent.application.owner.last_name.split('')[0] :state.current_sent.application.candidate_name.split(" ")[1] ? state.current_sent.application.candidate_name.split(" ")[0].split("")[0]+""+state.current_sent.application.candidate_name.split(" ")[1].split("")[0] : state.current_sent.application.candidate_name.split(" ")[0].split("")[0] : "N/A"}</h3>
                      </div>
                      <div>
                       <h4 className='m-0'>{state.current_sent.application ? state.current_sent.application.owner ? state.current_sent.application.owner.first_name+' '+state.current_sent.application.owner.last_name : state.current_sent.application.candidate_name : "N/A"} </h4>
                       <div className="dropdown">
                          <div className="dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <p className='sender_id'>To: <span className='just-text'>{state.current_sent.receipents ? state.current_sent.receipents[0] : "N/A"}</span></p>  
                          </div>
                          <div className="dropdown-menu p-2 " aria-labelledby="dropdownMenu2">
                            <p className='recipients_name'>{state.current_sent.receipents ? state.current_sent.receipents[0] : "N/A"}</p>
                          </div>
                        </div>
                        {/* <p>To: {state.current_sent.receipents ? state.current_sent.receipents[0] : "N/A"}</p> */}
                      </div>
                  </div>

                  <div className="mail-toolbar">
                     <p>{format(state.current_sent.createdAt)}</p>
                  </div>
                </div>
                   <div className='email-text' dangerouslySetInnerHTML={{__html: i.text}} />
                  </div>
                    {i.files && i.files.length > 0 ?
                  <div className="attachments-sections">
                    <ul className="d-flex">
                    {i.files.map(f => ( <li className="w-25" key={f._id}>
                        <div className="thumb"><i className="mdi mdi-file-pdf"></i></div>
                        <div className="details">
                          <p className="file-names">{f.name}</p>
                          <div className="buttons">
                            <p className="file-size">{f.size}</p>
                            <a href={f.path} target="_blank" className="view">View</a>
                            <a href={f.path} target="_blank" download={true} className="download">Download</a>
                          </div>
                        </div>
                      </li>))}
                    </ul>
                  </div> : null}
                  <hr className='mb-2'/>
                </div>)) : null}
                </div>
                
                  </li>
                </ul>

               
              </div>
              </div>
              <Modal
        size="lg"
        isOpen={state.openEmailModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
        <div className="modal-dialog modal-lg">
                        {!state.loading ? <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h2>New Email</h2>
                                </div>
                                <div>
                        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: false}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: ""}); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: []})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-between'>
                
                  <div className='pipe-add-input col-12 pt-3'>
                               <p><b>Recipients</b></p>
                   <CreatableSelect
                    isMulti
                    value={state.email_receipent}
                    onChange={handleSelectedEmail}
                    options={state.emails}
                    isClearable={false}
                    isSearchable
                    placeholder="Search or type Email"
                />
                  </div>
                  
                  </div>
                            <div className='pt-3'>
                            </div>
                               <div className='pipe-add-input pt-3 mb-3'>
                               <p><b>Subject</b></p>
                  <input type="text" placeholder='Email Subject' value={state.email_subject} onChange={e => {dispatch({type: ACTIONS.SET_EMAIL_SUBJECT, payload: e.target.value})}}/>
                  </div>
                  <p><b>Body</b></p>
                  <Editor
                    wrapperClassName="wrapper-class border-right border-left"
                    editorClassName="editor-class m-2"
                    toolbarClassName="toolbar-class"
                    editorState={state.editorState}
                    toolbar={{
                      options: [
                        'inline',
                        'fontSize',
                        'fontFamily',
                        'textAlign',
                        'list',
                        'link'
                      ],
                      inline: {
                        options: ['italic', 'bold'],
                      },
                      blockType: {
                        className: 'demo-option-custom-wide',
                        dropdownClassName: 'demo-dropdown-custom',
                      },
                      fontSize: { className: 'demo-option-custom-medium' },
                    }}
                    toolbarCustomButtons={[<label htmlFor='select' className='btn mt-2'> <Icon icon="akar-icons:file" fontSize={18} /></label>]}
                    onEditorStateChange={ editorState => {onEditorStateChange(editorState)}}
                    />
                    <div className=''>
                    <div className='border-right border-bottom border-left' style={{minHeight: "50px"}}>
                  <div>
                  <input className='m-1' type="file" hidden id='select' onChange={e => {handleSelectedDocuments(e.target.files)}}/>
                  </div>
                  <div>
                  {
                    state.selected_documents.map(i => (
                       <div className='border p-2 mb-2 rounded d-flex justify-content-between' key={Math.random()}><a href={URL.createObjectURL(i[0])}>{i[0].name}</a> <a onClick={e => {e.preventDefault(); removeDocument(i[0].name)}} href=''> <Icon fontSize={25} className='ml-3 ' icon="bi:x"/></a></div> 
                    ))
                  }</div>
                    </div>
                    </div>
                  <div className='pt-3'>
            <button className='btn btn-primary' onClick={() => {sendEmail(false, "")}}>Send</button>
                  </div>

                        </div>: <Spinner/>}

                    </div>

        </ModalBody>
        <ModalFooter>
        <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: false}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: ""}); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: []})}}>Close</a>
        </ModalFooter>
      </Modal>
      <Modal
      size="sm"
      isOpen={state.openEmailDeleteModal}
      centered
      backdrop="static"
      keyboard={false}>
          <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
        </ModalHeader>
        <ModalBody>
        <DeleteModal />
        </ModalBody>
      </Modal>
      <Modal
      size="sm"
      isOpen={state.openEmailDeleteModal}
      centered
      backdrop="static"
      keyboard={false}>
          <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
        </ModalHeader>
        <ModalBody>
        <DeleteModal />
        </ModalBody>
      </Modal>
      <Modal
        size={state.loading ? "sm" : "lg"}
        isOpen={state.openEmailForwardModal}
        centered
        backdrop="static"
        keyboard={false}
        //onClosed={() => {dispatch({type: ACTIONS.SET_CURRENT_SENT_EMAIL, payload: {}})}}
      >
        <ModalBody>
        <div className="">
                        {!state.loading ? <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h5>Forward {state.current_sent ? state.current_sent.subject : "No Subject"} to :</h5>
                                </div>
                                <div>
                                    <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-between'>
                
                  <div className='pipe-add-input col-12 pt-3'>
                               <p><b>Recipients</b></p>
                   <CreatableSelect
                    isMulti
                    value={state.email_receipent}
                    onChange={handleSelectedEmail}
                    options={state.emails}
                    isClearable={false}
                    isSearchable
                    placeholder="Search or type Email"
                />
                  </div>
                  </div>
                  <div className='pt-3'>
            <button className='btn btn-primary' onClick={(e) => {e.preventDefault(); sendEmail(false, "")}}>Send</button>
                  </div>

                        </div>: <div className='text-center'> <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner></div>}

                    </div>

        </ModalBody>
      
        <ModalFooter>
        {!state.loading ? <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: ""}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: false})}}>Close</a>: null}
        </ModalFooter>
      </Modal>
        </div>
    ) }