import React from 'react'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAuths, ACTIONS } from "../../../../context"

/*
createTemplate,
   fetchTemplates,
   getOneTemplate
*/
function Interview() {
const {addPlaceholder, onEditorTemplateStateChange, state, createTemplate, dispatch} = useAuths()

    return (
                    <div className='p-3 pt-5'>
                        <div className='d-flex justify-content-between border-bottom'>
                            <h3>{state.template_title ? state.template_title : "No title"}</h3>
                            <div className='d-flex pb-4'>
                                <a onClick={createTemplate} href="/" className='btn btn-dark'>{state.selected_template._id ? "Update" : "Save"}</a>
                                <td>
                                    <div className="dropdown">
                                    <div type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Icon icon="carbon:overflow-menu-vertical"/>
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <Link to="" className="dropdown-item">Disqualify</Link>
                                        <Link to="" className="dropdown-item">Requalify</Link>
                                        <Link to="" className="dropdown-item text-danger">Delete</Link>
                                    </div>
                                    </div>
                                </td>
                            </div>
                        </div>
                        <div className='interview-email p-2 scroll_segment'>
                        <div className='pipe-add-input pt-3 mb-3'>
                               <p><b>Title</b></p>
                  <input type="text" placeholder='Rejection Email' value={state.template_title} onChange={e => {dispatch({type: ACTIONS.SET_TEMPLATE_TITLE, payload: e.target.value})}}/>
                  </div>
                  <div className='interview-email-subject pipe-add-input '>
                               <p><b>Subject: </b></p>
                  <input type="text" placeholder='Employment Notice' value={state.template_subject} onChange={e => {dispatch({type: ACTIONS.SET_TEMPLATE_SUBJECT, payload: e.target.value})}}/>
                  </div>
                            <div className='interview-wysiwyg'>
                            <Editor 
                            toolbarCustomButtons={[
                                <div className='interview-placeholders border-0'>
                                <h4><span> Placeholders: </span></h4>
                                    <ul className='interview_placeholders-item'>
                                        <li><a href='?' className='template-placeholder' onClick={e => {e.preventDefault(); addPlaceholder("{job_offer}")}}>{`{job_offer}`} </a> </li>
                                        <li><a href='?' className='template-placeholder' onClick={e => {e.preventDefault(); addPlaceholder("{first_name}")}}>{`{first_name}`} </a> </li>
                                        <li><a href='?' className='template-placeholder' onClick={e => {e.preventDefault(); addPlaceholder("{last_name}")}}>{`{last_name}`} </a> </li>
                                        <li><a href='?' className='template-placeholder' onClick={e => {e.preventDefault(); addPlaceholder("{company_name}")}}>{`{company_name}`} </a> </li>
                                    </ul>
                                </div>
                            ]}
                            wrapperClassName="wrapper-class border"
                            editorClassName="editor-class m-2 border-0"
                            editorState={state.templateEditorState}
                            editorStyle={{ border: "1px solid #E3E8F1", minHeight: '300px' }}
                            onEditorStateChange={editorState => {
                                onEditorTemplateStateChange(editorState)
                            }}
                             />
                            </div>
                         

                        </div>

                    </div>
    )
}

export default Interview
