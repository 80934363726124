import React, { useEffect } from 'react'
import { InputGroup, Input, Form, Fade, ModalHeader, Modal, ModalBody, Spinner } from 'reactstrap'
import Dropdowns from 'react-multilevel-dropdown';
import { Icon } from '@iconify/react'
import Paginating from 'components/pagination'
import { Link } from 'react-router-dom'
import { useAuths, ACTIONS } from '../../context'
import moment from 'moment'


export default function Jobs() {
    const {fetchUserJobs, state, dispatch, handleSelectedApplications, close_job} = useAuths()
    const [dataPerPage] = React.useState(5)
const [currentPage, setCurrentPage] = React.useState(1)
const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = state.jobs.slice(indexOfFirstPage, indexOfLastPost);
  
const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}
    useEffect(() => {
        fetchUserJobs(1000000, "")

        return()=> {
        fetchUserJobs(1000000, "")
        }
    }, [])


    return (
        <main>
            <div>
                <div className="mb-7">
                    <h1 className="main-title">Jobs</h1>
                </div>
                
                <div className="d-flex justify-content-between align-items-center">
                    <div className="candidate-meta">
                        {/* <p>1 - 7 of 20 candidates</p> */}
                    </div>
                    <div className="candidate-toolbar d-flex">
                        <div className="search-wrapper w-100 lf">
                            <Form onSubmit={(e) => {e.preventDefault(); fetchUserJobs(undefined, ""); dispatch({type: ACTIONS.SET_JOB_FILTER_TEXT, payload: ""})}}>
                        <InputGroup >
                        <span className="iconify-inline"><Icon icon="bx:bx-search"/></span>
                            <Input placeholder="Search..." onChange={e => {dispatch({type: ACTIONS.SET_SEARCH_JOB_QUERY, payload: e.target.value})}} required/>
                        </InputGroup>
                        </Form>
                         </div>
                         <div className="trial-btn lf">
                         <Dropdowns
                wrapperClassName=""
                className="btn btn-dark"
                title={<div><span className="iconify-inline"><Icon icon="bx:bx-filter"/></span> Filter <span className="iconify-inline"><Icon icon="bx:bx-chevron-down"/></span></div>}
                >
    {state.job_filter_text ? <Dropdowns.Item onClick={e=> {e.preventDefault(); fetchUserJobs(undefined, ""); dispatch({type: ACTIONS.SET_JOB_FILTER_TEXT, payload: ""})}}>
    None
    </Dropdowns.Item>: null}
      <Dropdowns.Item onClick={e=> {e.preventDefault(); fetchUserJobs(undefined, "week")}}>
    This Week
    </Dropdowns.Item>
    <Dropdowns.Item onClick={e=> {e.preventDefault(); fetchUserJobs(undefined, "month")}}>
    This Month
    </Dropdowns.Item>
    <Dropdowns.Item onClick={e=> {e.preventDefault(); fetchUserJobs(undefined, "year")}}>
    This Year
    </Dropdowns.Item>
    <Dropdowns.Item onClick={e=> {e.preventDefault(); fetchUserJobs(undefined, "last year")}}>
    Last Year
    </Dropdowns.Item>
            </Dropdowns> 
                        </div>
                    </div>
                </div>
                    <div className="container m-auto pt-3 pb-3">
                      <div className="display-jb-alert p-3"><span><Icon icon="gridicons:notice-outline"/></span><span className="p-2">Jobs shown here are jobs that have been posted from your account on the Jobboards, to post a new job, visit the <a target="_blank" rel="noreferrer" href="https://jobboard.strivehumanresources.com/">job board</a></span></div>
                    </div>
                    <Fade in={state.job_filter_text ? true : false}><span> You are filtering with {state.job_filter_text}</span></Fade>
              <Fade className='mt-3' in={state.selected_jobs.length > 0 ? true : false}><Dropdowns
                wrapperClassName=""
                className="btn btn-dark"
                title={<div><span className="iconify-inline"></span> Bulk Action <span className="iconify-inline"><Icon icon="bx:bx-chevron-down"/></span></div>}
                >
      <Dropdowns.Item onClick={e=> {e.preventDefault(); close_job(state.selected_jobs, "open")}}>
    Open Selected
    </Dropdowns.Item>
    <Dropdowns.Item onClick={e=> {e.preventDefault(); close_job(state.selected_jobs, "close")}}>
    Close Selected
    </Dropdowns.Item>
    <Dropdowns.Item onClick={e=> {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}}>
    Delete Selected
    </Dropdowns.Item>
            </Dropdowns> </Fade> 
                <div className="row">
                    <div className="col-12 col-md-12 col-xs-12 bg-whitee">
                       <div className="table-responsive">
                         <table className="table">
                          <thead>
                            <tr>
                               <th scope="col" className="border-0 font-size-4 font-weight-bold">
                                <input className="checkbox " checked={state.selected_jobs.length > 0 && state.selected_jobs.length === state.jobs.length ? true : false} type="checkbox" onChange={() => {handleSelectedApplications("all", "job")}} /> 
                                </th>
                                <th>Position</th>
                                <th>Type</th>
                                <th>Posted Date</th>
                                <th>Status</th>
                                <th>Candidates</th>
                                <th></th>
                                <th></th>
                            </tr>
                          </thead>
                          <tbody className="">
                                {currentData.map(i => (<tr key={i._id}>
                                  <td><input className="checkbox " type="checkbox" checked={state.selected_jobs.includes(i._id)} onChange={() => {handleSelectedApplications(i._id, "job")}}/> </td>
                                    <td>{i.jobrole}</td>
                                    <td>{i.type ? i.type.type : ""}</td>
                                    <td>{moment(i.createdAt).format("MMMM Do YYYY")}</td>
                                    <td>{!i.closed ? <><span className="active-label mr-1"></span>Active</> : <><span className="inactive-label mr-1"></span>Inactive</>}</td>
                                    <td>{i.applicant.filter(j => (!j.delete && !j.withdraw && !j.rejected)).length}</td>
                                    <td><Link to="/pipeline" state={{job : i._id, name: i.jobrole}} className="btn-dark ">View Pipeline</Link></td>
                                    <td>
                                     <div className="dropdown">
                                        <Dropdowns
                                        wrapperClassName=""
                                        className="bg-transparent text-dark border-0"
                                        title={<Icon icon="carbon:overflow-menu-vertical"/>}
                                            >
                                {i.closed ?<Dropdowns.Item onClick={e=> {e.preventDefault(); close_job(i._id, "open")}}>
                                Open
                                </Dropdowns.Item>:
                                <Dropdowns.Item onClick={e=> {e.preventDefault(); close_job(i._id, "close")}}>
                                Close
                                </Dropdowns.Item>}
                                <Dropdowns.Item onClick={e=> {e.preventDefault(); handleSelectedApplications(i._id, "job"); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}}>
                                Delete
                                </Dropdowns.Item>
                                        </Dropdowns> 
                                        </div>
                                    </td>
                                </tr>))}
                               
                            </tbody>
                         </table>
                         <Paginating dataPerPage={dataPerPage} totalData={state.jobs.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
                         {/*<button className='btn btn-dark' onClick={async e => {e.preventDefault(); fetchUserJobs(state.jobs.length+5, state.job_filter_text ? state.job_filter_text : "")}}>Load More</button>*/}
                       </div>
                    </div>
                </div>
            </div>
            <Modal
      size="sm"
      isOpen={state.openEmailDeleteModal}
      centered
      backdrop="static"
      keyboard={false}>
          <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_SELECTED_JOBS, payload: []}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
        </ModalHeader>
        <ModalBody>
        <div>
               {!state.loading ? <div className="modal-content p-2 border-0 text-center">
                            <div >
                                <div className='mb-4'>
                                    <h4>Delete Job?</h4>
                                </div>
                                <div className='d-flex justify-content-between'>
                                   
                                <button className='btn btn-dark' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_SELECTED_JOBS, payload: []}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}}>Abort</button>
                                    <button className='btn btn-danger' style={{borderRadius: "20px"}} onClick={e => {e.preventDefault(); close_job(state.selected_jobs, "delete")}}>Confirm</button>
                                </div></div></div>: <Spinner/>}
             </div>
        </ModalBody>
      </Modal>
        </main>
    )
}
