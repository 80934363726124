(function($) {
    
    $(function() {
      var todoListItem = $('.todo-list');
      //var todoListInput = $('.todo-list-input');
      
      todoListItem.on('change', '.checkbox', function() {
        if ($(this).attr('checked')) {
          $(this).removeAttr('checked');
        } else {
          $(this).attr('checked', 'checked');
        }
  
        $(this).closest("li").toggleClass('todo-done');
  
      });
  
      todoListItem.on('click', '.remove', function() {
        $(this).parent().remove();
      });
  
    });
  // eslint-disable-next-line no-undef
  })(jQuery);