import React, { useEffect } from 'react'
//import {Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner } from "reactstrap"
  import { useAuths, ACTIONS } from '../../../context';
import CreatableSelect from 'react-select/creatable';
import { format } from 'timeago.js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";



export default function Email_tab({candidateId}) {
  const {dispatch, state, handleSelectedEmail, handleSelectedDocuments, removeDocument, onEditorStateChange, sendEmail, deleteEmail, sentCurrentSent} = useAuths()

 
  const DeleteModal = () =>{
    
    return(
             <div>
               {!state.loading ? <div className="modal-content p-2 border-0 text-center">
                            <div >
                                <div className='mb-4'>
                                    <h4>Delete Email?</h4>
                                </div>
                                <div className='d-flex justify-content-between'>
                                   
                                <button className='btn btn-dark' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: ""}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}}>Abort</button>
                                    <button className='btn btn-danger' style={{borderRadius: "20px"}} onClick={e => {e.preventDefault(); deleteEmail(candidateId, state.emailId)}}>Confirm</button>
                                </div></div></div>: <Spinner/>}
             </div>
    )

   }


    return (
        <div>
            <div className="d-flex justify-content-between">
                <div>

                </div>
                <div>
                <button className="btn btn-dark"to="" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: true})}}><span><Icon icon="bi:plus-lg"/></span>New Email</button>
                </div>
            </div>
        {state.candidate_email.map(i => (!i.draft ? <div key={i._id} className="bg-whitee">
            
        <div className="d-flex  align-items-center  justify-content-between pl-2 pr-5">
                  <div className="mail-title">
                    <h3>{i.subject}</h3>
                  </div>
                  <div className="mail-toolbar">
                    <div className="btn-group mail-icons">
                      <a href=" " onClick={e => {e.preventDefault(); const emails = i.receipents[0].split(","); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: true}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: i._id}); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: [{label: emails[0], value: emails[0]}]})}} className="pr-2"><Icon icon="bi:reply-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); const emails = i.receipents[0].split(","); const getMails = emails.map(i => {return {value: i, label: i}}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: true}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: i._id});dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: getMails}) }}><Icon icon="bi:reply-all-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); sentCurrentSent(i._id); dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: []}); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: i._id}) ;dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: true})}}><Icon icon="bi:forward-fill"/>  </a>
                      <a href=" " onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: i._id}) ;dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}}><Icon icon="fluent:delete-24-filled"/>  </a>
                    </div>
                  </div>
                </div>

                <div className="message-body">
                <div className="d-flex  align-items-center  justify-content-between pl-2 pr-5">
                  <div className="mail-title">
                    <div className="row d-flex  align-items-center pl-3 pr-3 pt-5">
                      <div className="mailer-icon rounded-circle bg-primary text-white p-3 mr-2">
                        <h3>{i.application ? i.application.owner ? i.application.owner.first_name.split('')[0]+''+i.application.owner.last_name.split('')[0] :i.application.candidate_name.split(" ")[1] ? i.application.candidate_name.split(" ")[0].split("")[0]+""+i.application.candidate_name.split(" ")[1].split("")[0] : i.application.candidate_name.split(" ")[0].split("")[0] : "N/A"}</h3>
                      </div>
                      <div>
                        <p className="msg-sender ">
                       {i.application ? i.application.owner ? i.application.owner.first_name+' '+i.application.owner.last_name : i.application.candidate_name : "N/A"}
                          </p>
                        <p>To: {i.receipents ? i.receipents[0] : "N/A"}</p>
                      </div>
                    </div>
                  </div>

                  <div className="mail-toolbar">
                    <p>{format(i.updatedAt)}</p>
                  </div>
                </div>
                {i.thread.map(t => (<div key={t._id}>
                  <div className="message-content pt-3 p-2">
                  <div dangerouslySetInnerHTML={{__html: t.text}} />
                  </div>
                  <div className="attachments-sections pt-3 p-2">
                    <ul className="d-flex">
                      {t.files ? t.files.map(el => (<li key={el._id} className="w-50  p-2 mr-2 border">
                        <div className="thumb"><i className="mdi mdi-file-pdf"></i></div>
                        <div className="details">
                          <p className="file-name">{el.name}</p>
                          <div className="buttons">
                            <p className="file-size">{el.size}</p>
                            <a href={el.path} target="_blank" rel="noreferrer" className="view">View</a>
                            <a href={el.path} target="_blank" rel="noreferrer" className="download" download={true}>Download</a>
                          </div>
                        </div>
                      </li>)) : null}
                    </ul>
                  </div>
                  </div>))}
                </div>  
        </div> : null))}
         <Modal
        size={state.loading ? "sm" : "lg"}
        isOpen={state.openEmailModal}
        centered
        onClosed={() => { if(state.email_text || state.email_subject || state.email_receipent.length > 0){sendEmail(true, "")}}}
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
        <div className="modal-dialog modal-lg">
                        {!state.loading ? <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h2>New Email</h2>
                                </div>
                                <div>
                                    <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-between'>
                
                  <div className='pipe-add-input col-12 pt-3'>
                               <p><b>Recipients</b></p>
                   <CreatableSelect
                    isMulti
                    value={state.email_receipent}
                    onChange={handleSelectedEmail}
                    options={state.emails}
                    isClearable={false}
                    isSearchable
                    placeholder="Search or type Email"
                />
                  </div>
                 
                  </div>
                            <div className='pt-3'>
                            </div>
                               <div className='pipe-add-input pt-3 mb-3'>
                               <p><b>Subject</b></p>
                  <input type="text" placeholder='Email Subject' value={state.email_subject} onChange={e => {dispatch({type: ACTIONS.SET_EMAIL_SUBJECT, payload: e.target.value})}}/>
                  </div>
                  <p><b>Body</b></p>
                  <Editor
                    wrapperClassName="wrapper-class border-right border-left"
                    editorClassName="editor-class m-2"
                    toolbarClassName="toolbar-class"
                    editorState={state.editorState}
                    toolbar={{
                      options: [
                        'inline',
                        'fontSize',
                        'fontFamily',
                        'textAlign',
                        'list',
                        'link'
                      ],
                      inline: {
                        options: ['italic', 'bold'],
                      },
                      blockType: {
                        className: 'demo-option-custom-wide',
                        dropdownClassName: 'demo-dropdown-custom',
                      },
                      fontSize: { className: 'demo-option-custom-medium' },
                    }}
                    toolbarCustomButtons={[<label htmlFor='select' className='btn mt-2'> <Icon icon="akar-icons:file" fontSize={18} /></label>]}
                    onEditorStateChange={ editorState => {onEditorStateChange(editorState)}}
                    />
                    <div className=''>
                    <div className='border-right border-bottom border-left' style={{minHeight: "50px"}}>
                  <div>
                  <input className='m-1' type="file" hidden id='select' onChange={e => {handleSelectedDocuments(e.target.files)}}/>
                  </div>
                  <div>
                  {
                    state.selected_documents.map(i => (
                       <div className='border p-2 mb-2 rounded d-flex justify-content-between' key={Math.random()}><a href={URL.createObjectURL(i[0])}>{i[0].name}</a> <a onClick={e => {e.preventDefault(); removeDocument(i[0].name)}} href=''> <Icon fontSize={25} className='ml-3 ' icon="bi:x"/></a></div> 
                    ))
                  }</div>
                    </div>
                    </div>
                  <div className='pt-3'>
            <button className='btn btn-primary' onClick={(e) => {e.preventDefault(); sendEmail(false, "")}}>Send</button>
                  </div>

                        </div>: <Spinner/>}

                    </div>

        </ModalBody>
      
        <ModalFooter>
        <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: ""}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: false})}}>Close</a>
        </ModalFooter>
      </Modal>
      <Modal
        size={state.loading ? "sm" : "lg"}
        isOpen={state.openEmailForwardModal}
        centered
        backdrop="static"
        keyboard={false}
        onClosed={() => {dispatch({type: ACTIONS.SET_CURRENT_SENT_EMAIL, payload: {}})}}
      >
        <ModalBody>
        <div className="">
                        {!state.loading ? <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h5>Forward {state.current_sent ? state.current_sent.subject : "No Subject"} to :</h5>
                                </div>
                                <div>
                                    <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-between'>
                
                  <div className='pipe-add-input col-12 pt-3'>
                               <p><b>Recipients</b></p>
                   <CreatableSelect
                    isMulti
                    value={state.email_receipent}
                    onChange={handleSelectedEmail}
                    options={state.emails}
                    isClearable={false}
                    isSearchable
                    placeholder="Search or type Email"
                />
                  </div>
                  </div>
                  <div className='pt-3'>
            <button className='btn btn-primary' onClick={(e) => {e.preventDefault(); sendEmail(false, "")}}>Send</button>
                  </div>

                        </div>: <div className='text-center'> <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner></div>}

                    </div>

        </ModalBody>
      
        <ModalFooter>
        {!state.loading ? <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_EMAIL_ID, payload: ""}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: false})}}>Close</a>: null}
        </ModalFooter>
      </Modal>
      <Modal
      size="sm"
      isOpen={state.openEmailDeleteModal}
      centered
      backdrop="static"
      keyboard={false}>
          <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
        </ModalHeader>
        <ModalBody>
        <DeleteModal />
        </ModalBody>
      </Modal>
    </div>
    )
}