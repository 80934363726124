import React, { useContext, useEffect, useReducer } from "react";
import axios from 'axios'
import Emailreply from './components/employers/Emailreply';
import { post as postt } from 'axios'
import moment  from 'moment'
import { toast, Slide } from 'react-toastify'
import { GoogleLoginResponse } from 'react-google-login';
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { ActionMeta, OnChangeValue } from 'react-select';
import { EditorState, convertToRaw, Modifier, SelectionState, ContentState, convertFromHTML } from 'draft-js';
import socketClient  from "socket.io-client";
import draftToHtml from 'draftjs-to-html'
import DOMPurify from "dompurify";
toast.configure()
const AuthContext = React.createContext()
const url = process.env.REACT_APP_SERVER_URL

const SERVER = "https://strivejb.herokuapp.com"
//"https://strivejb.herokuapp.com"




export function useAuths() {
    return useContext(AuthContext)
}

export const withdraw_reason_list = [
    "Accepted offer elsewhere",
    "Salary range too low",
    "Personal reason",
    "Unwilling to relocate",
    "Commute/travel to and from work",
    "Job description was confusing",
    "Decided to stay with current company",
    "No longer interested in the company/position",
    "Hybrid schedule not offered",
    "Remote schedule not offered",
    "Benefits not competitive",
    "Hired on another position within the company",
    "Others"
]
export const rejection_reason_list = [
    "Not willing to work required hours/shift",
    "Position is no longer being filled",
    "Desired pay out of range",
    "Bad contact information",
    "Didn't meet desired qualifications",
    "Didn't meet minimum qualifications",
    "Application incomplete",
    "Not eligible to work in location (will need work permit)",
    "Will need relocation assistance",
    "Qualifications were misinterpreted",
    "More qualified candidate was selected",
    "No response to interview request",
    "No show for interview",
    "Candidate unresponsive",
    "Application not reviewed",
    "Better fit for another position",
    "Candidate rejected offer",
    "Former employee not eligible for rehire",
    "Others"
]
export const ACTIONS = {
    SET_CANDIDATE_NAME: "SET_CANDIDATE_NAME:",
    SET_CANDIDATE_EMAIL: "SET_CANDIDATE_EMAIL",
    SET_CANDIDATE_PHONE: "SET_CANDIDATE_PHONE",
    SET_CANDIDATE_LINK: "SET_CANDIDATE_LINK",
    SET_CANDIDATE_RESUME: "SET_CANDIDATE_RESUME",
    SET_CANDIDATE_IMAGE: "SET_CANDIDATE_IMAGE",
    RESET_ADD_CANDIDATE_FORM: "RESET_ADD_CANDIDATE_FORM",
    SET_EMAIL: "SET_EMAIL",
    SET_EMAILS: "SET_EMAILS",
    SET_PASSWORD: "SET_PASSWORD",
    SET_CANDIDATE: "SET_CANDIDATE",
    SET_CURRENT_DRAFT: "SET_CURRENT_DRAFT",
    SET_DASHBOARD_LABEL: "SET_DASHBOARD_LABEL",
    SET_DASHBOARD_LABELS: "SET_DASHBOARD_LABELS",
    SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
    SET_PIPELINE: "SET_PIPELINE",
    SET_USER_JOBS: "SET_USER_JOBS",
    SET_LOADING: "SET_LOADING",
    SET_CANDIDATES: "SET_CANDIDATES",
    SET_SELECTED_EMAILS: "SET_SELECTED_EMAILS",
    SET_OPEN_EVENT_MODAL: "SET_OPEN_EVENT_MODAL",
    SET_OPEN_EMAIL_MODAL: "SET_OPEN_EMAIL_MODAL",
    SET_TOTAL_CANDIDATE: "SET_TOTAL_CANDIDATE",
    SET_JOB_LOAD: "SET_JOB_LOAD",
    SET_EMAIL_ID: "SET_EMAIL_ID",
    SET_EVENT_TITLE: "SET_EVENT_TITLE",
    SET_EVENT_DATE: "SET_EVENT_DATE",
    SET_EVENT_TIME: "SET_EVENT_TIME",
    SET_EVENT_END_DATE: "SET_EVENT_END_DATE",
    SET_EVENT_DURATION: "SET_EVENT_DURATION",
    SET_EVENT_TYPE: "SET_EVENT_TYPE",
    SET_SELECTED_DOCUMENTS: "SET_SELECTED_DOCUMENTS",
    SET_EVENT_DESCRIPTION: "SET_EVENT_DESCRIPTION",
    SET_EVENT_START_DATE: "SET_EVENT_START_DATE",
    SET_EVENT_ATTENDIES: "SET_EVENT_ATTENDIES",
    SET_PIPELINE_NAME: "SET_PIPELINE_NAME",
    SET_PIPELINE_ID: "SET_PIPELINE_ID",
    SET_OPEN_ACTIVE_PIPELINE_MODAL: "SET_OPEN_ACTIVE_PIPELINE_MODAL",
    SET_PIPELINE_DROPDOWN: "SET_PIPELINE_DROPDOWN",
    SET_JOB : "SET_JOB",
    SET_OPEN_PIPELINE_MODAL: "SET_OPEN_MODAL",
    SET_EMAIL_TEXT: "SET_EMAIL_TEXT",
    SET_EMAIL_RECEIPENT : "SET_EMAIL_RECEIPENT",
    SET_EMAIL_SUBJECT : "SET_EMAIL_SUBJECT",
    SET_EMAIL_NAME: "SET_EMAIL_NAME",
    SET_EMAIL_RECEIVERS: "SET_EMAIL_RECEIVERS",
    SET_EDITOR_STATE: "SET_EDITOR_STATE",
    SET_TODOS: "SET_TODOS",
    SET_OPEN_ADD_RECRUITER_MODAL: "SET_OPEN_ADD_RECRUITER_MODAL",
    SET_INTERVIEWS: "SET_INTERVIEWS",
    SET_RECENT_ACTIVITIES: "SET_RECENT_ACTIVITIES",
    SET_CANDIDATE_EMAILS: "SET_CANDIDATE_EMAILS",
    SET_INBOX_EMAILS: "SET_INBOX_EMAILS",
    SET_SENT_EMAILS: "SET_SENT_EMAILS",
    SET_ALLEMAILS: "SET_ALLEMAILS",
    SET_CURRENT_SENT_EMAIL: "SET_CURRENT_SENT_EMAIL",
    SET_CURRENT_INBOX_EMAIL: "SET_CURRENT_INBOX_EMAIL",
    SET_RECRUITER_EMAIL: "SET_RECRUITER_EMAIL",
    SET_RECRUITER_NAME: "SET_RECRUITER_NAME",
    SET_RECRUITER_POSITION: "SET_RECRUITER_POSITION",
    SET_RECRUITER_ROLE: "SET_RECRUITER_ROLE",
    SET_RECRUITERS: "SET_RECRUITERS",
    SET_CONFIRM_NEW_PASSWORD: "SET_CONFIRM_NEW_PASSWORD",
    SET_NEW_PASSWORD: "SET_NEW_PASSWORD",
    SET_SELECTED_RECRUITER: "SET_SELECTED_RECRUITER",
    SET_TEMPLATE_TEXT: "SET_TEMPLATE_TEXT",
    SET_TEMPLATE_SUBJECT: "SET_TEMPLATE_SUBJECT",
    SET_TEMPLATE_TITLE: "SET_TEMPLATE_TITLE",
    SET_AWAITING_FEEDBACK: "SET_AWAITING_FEEDBACK",
    SET_TEMPLATE_EDITOR_STATE_CHANGE: "SET_TEMPLATE_EDITOR_STATE_CHANGE",
    SET_TEMPLATE_EDITING: "SET_TEMPLATE_EDITING",
    SET_TEMPLATES: "SET_TEMPLATES",
    SET_SELECTED_TEMPLATE: "SET_SELECTED_TEMPLATE",
    SET_SEARCH_CANDIDATE_QUERY: "SET_SEARCH_CANDIDATE_QUERY",
    SET_SEARCH_JOB_QUERY: "SET_SEARCH_JOB_QUERY",
    SET_EMAIL_DRAFT: "SET_EMAIL_DRAFT",
    SET_DRAFT_EMAILS: "SET_DRAFT_EMAILS",
    SET_CANDIDATE_EMAIL: "SET_CANDIDATE_EMAIL",
    SET_CANDIDATE_FILE: "SET_CANDIDATE_FILE",
    SET_CANDIDATE_ACTIVITY: "SET_CANDIDATE_ACTIVITY",
    SET_OPEN_FILE_MODAL: "SET_OPEN_FILE_MODAL",
    SET_OPEN_EMAIL_DELETE_MODAL: "SET_OPEN_EMAIL_DELETE_MODAL",
    SET_OPEN_EMAIL_FORWARD_MODAL: "SET_OPEN_EMAIL_FORWARD_MODAL",
    SET_CANDIDATE_FILTER_TEXT: "SET_CANDIDATE_FILTER_TEXT",
    SET_JOB_FILTER_TEXT: "SET_JOB_FILTER_TEXT",
    SET_PIPELINE_LIST: "SET_PIPELINE_LIST",
    SET_SELECTED_APPLICATIONS: "SET_SELECTED_APPLICATIONS",
    SET_OPEN_EMAIL_DELETE_APP: "SET_OPEN_EMAIL_DELETE_APP",
    SET_SELECTED_JOBS: "SET_SELECTED_JOBS",
    SET_SENT_BOX_MEMORY: "SENT_BOX_MEMORY",
    SET_IN_BOX_MEMORY: "IN_BOX_MEMORY",
    SET_DRAFT_BOX_MEMORY: "DRAFT_BOX_MEMORY",
    SET_GOOGLE_LOGGED_IN: "SET_GOOGLE_LOGGED_IN",
    SET_NOTE_TEXT: "SET_NOTE_TEXT",
    SET_NOTE_MODAL: "SET_NOTE_MODAL",
    SET_NOTE_ID: "SET_NOTE_ID",
    SET_DISQUALIFY_MODAL: "SET_DISQUALIFY_MODAL",
    SET_CANDIDATE_NOTES: "SET_CANDIDATE_NOTES",
    SET_EMAIL_LOADING: "SET_EMAIL_LOADING",

}


const initialState = {
    selected_applications: [],
    candidate_notes: [],
    google_logged_in: false,
    disqualify_modal: false,
    email_loading: false,
    sent_box_memory: [],
    in_box_memory: [],
    draft_box_memory: [],
    selected_jobs: [],
    templates: [],
    template_text: "",
    note_text: "",
    note_id: "",
    add_note_modal: false,
    candidate_filter_text: {},
    current_draft: {},
    job_filter_text: "",
    template_subject: "",
    template_title: "",
    new_password: "",
    search_job_query: "",
    confirm_new_password: "",
    candidate_name : "", 
    candidate_email : "", 
    candidate_phone : "",
    pipeline_list: [],
    template_editing: false,
    selected_recruiter: "",
    candidate_link : "",
    selected_template: {},
    current_inbox: {},
    current_sent: {},
    inbox_emails: [],
    sent_emails: [],
    draft_emails: [],
    allemails: [],
    candidate_emails: [],
    event_title: "",
    event_end_date: null,
    event_date: new Date(),
    event_start_date: null,
    event_time: new Date(),
    emailId: "",
    event_duration: "",
    recruiters: [],
    event_type: "",
    event_description: "",
    event_attendies: [],
    dashboard_labels : [],
    dashboard_label : "",
    dashboard_data: [],
    selected_documents: [],
    pipeline_name: "",
    email_draft: false,
    interviews: [],
    todos: [],
    openEmailDeleteModal: false,
    openEmailDeleteApp: false,
    openEmailForwardModal: false,
    recentActivities : [],
    pipeline_id: "",
    candidate: {},
    candidate_file: [],
    candidate_activity: [],
    candidate_email: [],
    emails: [{label: "test@test.com", value: "test@test.com"}, {label: "trying@test.com", value: "trying@test.com"}],
    selectedEmails: [],
    loading: true,
    openEventModal: false,
    openEmailModal: false,
    openFileModal: false,
    openAddRecruiterModal: false,
    email : "",
    candidates : [],
    job_load : false,
    total_candidates: 0,
    editorState: EditorState.createEmpty(),
    templateEditorState: EditorState.createEmpty(),
    password : "",
    candidate_resume : undefined,
    candidate_image : undefined,
    pipelineDropDown: '',
    email_text : "",
    email_receipent : [],
    email_subject : "",
    email_name : "",
    email_receivers: [],
    pipelines: [],
    jobs : [],
    recruiter_email: "", 
    recruiter_name: "", 
    recruiter_position: "", 
    recruiter_role: "",
    job : "",
    search_candidate_query: "",
    awaiting_feedback: 0,
    openPipelineModal: false,
    openActivePipelineModal: false
}

function reducer(state, action){
    switch(action.type){
        case ACTIONS.SET_CANDIDATE_NAME:
            return{ ...state, candidate_name: action.payload}
        case ACTIONS.SET_DISQUALIFY_MODAL:
            return{ ...state, disqualify_modal: action.payload}
        case ACTIONS.SET_CANDIDATE_EMAIL:
            return{ ...state, candidate_email: action.payload}
        case ACTIONS.SET_CANDIDATE_PHONE:
            return{ ...state, candidate_phone: action.payload}
        case ACTIONS.SET_CANDIDATE_LINK:
            return{ ...state, candidate_link: action.payload}
        case ACTIONS.SET_USER_JOBS:
            return{ ...state, jobs: action.payload}
        case ACTIONS.SET_EVENT_TITLE:
            return{ ...state, event_title: action.payload}
        case ACTIONS.SET_INBOX_EMAILS:
            return{ ...state, inbox_emails: action.payload}
        case ACTIONS.SET_DRAFT_EMAILS:
            return{ ...state, draft_emails: action.payload}
        case ACTIONS.SET_SENT_EMAILS:
            return{ ...state, sent_emails: action.payload}
        case ACTIONS.SET_EVENT_DATE:
            return{ ...state, event_date: action.payload}
        case ACTIONS.SET_GOOGLE_LOGGED_IN:
            return{ ...state, google_logged_in: action.payload}
        case ACTIONS.SET_EVENT_TIME:
            return{ ...state, event_time: action.payload}
        case ACTIONS.SET_NOTE_TEXT:
            return{ ...state, note_text: action.payload}
        case ACTIONS.SET_NOTE_MODAL:
            return{ ...state, add_note_modal: action.payload}
        case ACTIONS.SET_CURRENT_INBOX_EMAIL:
            return{ ...state, current_inbox: action.payload}
        case ACTIONS.SET_CURRENT_SENT_EMAIL:
            return{ ...state, current_sent: action.payload}
        case ACTIONS.SET_EVENT_END_DATE:
            return{ ...state, event_end_date: action.payload}
        case ACTIONS.SET_EVENT_DURATION:
            return{ ...state, event_duration: action.payload}
        case ACTIONS.SET_EVENT_TYPE:
            return{ ...state, event_type: action.payload}
        case ACTIONS.SET_EVENT_DESCRIPTION:
            return{ ...state, event_description: action.payload}
        case ACTIONS.SET_IN_BOX_MEMORY:
            return{ ...state, in_box_memory: action.payload}
        case ACTIONS.SET_NOTE_ID:
            return{ ...state, note_id: action.payload}
        case ACTIONS.SET_CANDIDATE_NOTES:
            return{ ...state, candidate_notes: action.payload}
        case ACTIONS.SET_SENT_BOX_MEMORY:
            return{ ...state, sent_box_memory: action.payload}
        case ACTIONS.SET_DRAFT_BOX_MEMORY:
            return{ ...state, draft_box_memory: action.payload}
        case ACTIONS.SET_EVENT_ATTENDIES:
            return{ ...state, event_attendies: action.payload}
        case ACTIONS.SET_EVENT_START_DATE:
            return{ ...state, event_start_date: action.payload} 
        case ACTIONS.SET_CANDIDATE_RESUME:
            return{ ...state, candidate_resume: action.payload} 
        case ACTIONS.SET_SELECTED_RECRUITER:
            return{ ...state, selected_recruiter: action.payload} 
        case ACTIONS.SET_EMAIL:
            return{ ...state, email: action.payload} 
        case ACTIONS.SET_OPEN_EMAIL_DELETE_APP:
            return{ ...state, openEmailDeleteApp: action.payload} 
        case ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL:
            return{ ...state, openEmailDeleteModal: action.payload} 
        case ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL:
            return{ ...state, openEmailForwardModal: action.payload}
        case ACTIONS.SET_CANDIDATE_FILTER_TEXT:
            return{ ...state, candidate_filter_text: action.payload} 
        case ACTIONS.SET_JOB_FILTER_TEXT:
            return{ ...state, job_filter_text: action.payload} 
        case ACTIONS.SET_ALLEMAILS:
            return{ ...state, allemails: action.payload} 
        case ACTIONS.SET_OPEN_FILE_MODAL:
            return{ ...state, openFileModal: action.payload} 
        case ACTIONS.SET_EMAILS:
            return{ ...state, emails: action.payload} 
        case ACTIONS.SET_PASSWORD:
            return{ ...state, password : action.payload} 
        case ACTIONS.SET_PIPELINE_LIST:
            return{ ...state, pipeline_list : action.payload} 
        case ACTIONS.SET_CANDIDATE_IMAGE:
            return{ ...state, candidate_image: action.payload}
        case ACTIONS.SET_PIPELINE:
            return{ ...state, pipelines : action.payload}
        case ACTIONS.SET_CURRENT_DRAFT:
            return{ ...state, current_draft : action.payload}
        case ACTIONS.SET_CANDIDATE: 
            return{ ...state, candidate: action.payload}
        case ACTIONS.SET_SELECTED_EMAILS: 
            return{ ...state, selectedEmails: action.payload}
        case ACTIONS.SET_CANDIDATES:
            return{ ...state, candidates: action.payload}
        case ACTIONS.SET_PIPELINE_NAME:
            return{ ...state, pipeline_name: action.payload}
        case ACTIONS.SET_CONFIRM_NEW_PASSWORD:
            return{ ...state, confirm_new_password: action.payload}
        case ACTIONS.SET_NEW_PASSWORD:
            return{ ...state, new_password: action.payload}
        case ACTIONS.SET_DASHBOARD_DATA:
            return{ ...state, dashboard_data: action.payload}
        case ACTIONS.SET_DASHBOARD_LABEL:
            return{ ...state, dashboard_label: action.payload}
        case ACTIONS.SET_OPEN_EVENT_MODAL:
            return{ ...state, openEventModal: action.payload}
        case ACTIONS.SET_DASHBOARD_LABELS:
            return{ ...state, dashboard_labels: action.payload}
        case ACTIONS.SET_PIPELINE_ID:
            return{ ...state, pipeline_id: action.payload}
        case ACTIONS.SET_LOADING:
            return{ ...state, loading: action.payload}
        case ACTIONS.SET_JOB_LOAD:
            return{ ...state, job_load : action.payload}
        case ACTIONS.SET_TOTAL_CANDIDATE:
            return{ ...state, total_candidates : action.payload}
        case ACTIONS.SET_OPEN_PIPELINE_MODAL:
            return{ ...state, openPipelineModal : action.payload}
        case ACTIONS.SET_OPEN_ACTIVE_PIPELINE_MODAL:
            return{ ...state, openActivePipelineModal : action.payload}
        case ACTIONS.SET_SELECTED_DOCUMENTS:
            return{ ...state, selected_documents : action.payload}
        case ACTIONS.SET_PIPELINE_DROPDOWN:
            return{ ...state, pipelineDropDown : action.payload}
        case ACTIONS.SET_OPEN_EMAIL_MODAL:
            return{ ...state, openEmailModal : action.payload}
        case ACTIONS.SET_SELECTED_TEMPLATE:
            return{ ...state, selected_template : action.payload}
        case ACTIONS.SET_JOB:
            return{ ...state, job : action.payload}
        case ACTIONS.SET_CANDIDATE_EMAIL:
            return{ ...state, candidate_email : action.payload}
        case ACTIONS.SET_CANDIDATE_FILE:
            return{ ...state, candidate_file : action.payload}
        case ACTIONS.SET_CANDIDATE_ACTIVITY:
            return{ ...state, candidate_activity : action.payload}
        case ACTIONS.SET_EMAIL_DRAFT:
            return{ ...state, email_draft : action.payload}
        case ACTIONS.SET_SELECTED_APPLICATIONS:
            return{ ...state, selected_applications : action.payload}
        case ACTIONS.SET_SELECTED_JOBS:
            return{ ...state, selected_jobs : action.payload}
        case ACTIONS.SET_EMAIL_TEXT:
            return{ ...state, email_text : action.payload}
        case ACTIONS.SET_EMAIL_SUBJECT:
            return{ ...state, email_subject : action.payload}
        case ACTIONS.SET_EMAIL_NAME:
            return{ ...state, email_name : action.payload}
        case ACTIONS.SET_TODOS:
            return{ ...state, todos : action.payload}
        case ACTIONS.SET_INTERVIEWS:
            return{ ...state, interviews : action.payload}
        case ACTIONS.SET_RECENT_ACTIVITIES:
            return{ ...state, recentActivities : action.payload}
        case ACTIONS.SET_EMAIL_RECEIPENT:
            return{ ...state, email_receipent : action.payload}
        case ACTIONS.SET_EDITOR_STATE:
            return{ ...state, editorState : action.payload}
        case ACTIONS.SET_RECRUITER_EMAIL:
            return{ ...state, recruiter_email : action.payload}
        case ACTIONS.SET_RECRUITER_NAME:
            return{ ...state, recruiter_name : action.payload}
        case ACTIONS.SET_RECRUITER_POSITION:
            return{ ...state, recruiter_position : action.payload}
        case ACTIONS.SET_RECRUITERS:
            return{ ...state, recruiters : action.payload}
        case ACTIONS.SET_RECRUITER_ROLE:
            return{ ...state, recruiter_role : action.payload}
        case ACTIONS.SET_EMAIL_RECEIVERS:
            return{ ...state, email_receivers : action.payload}
        case ACTIONS.SET_EMAIL_ID:
            return{ ...state, emailId : action.payload}
        case ACTIONS.SET_TEMPLATE_EDITOR_STATE_CHANGE:
            return{ ...state, templateEditorState : action.payload}
        case ACTIONS.SET_AWAITING_FEEDBACK:
            return{ ...state, awaiting_feedback : action.payload}
        case ACTIONS.SET_OPEN_ADD_RECRUITER_MODAL:
            return{ ...state, openAddRecruiterModal : action.payload}
        case ACTIONS.SET_TEMPLATE_TEXT:
            return{ ...state, template_text : action.payload}
        case ACTIONS.SET_TEMPLATE_TITLE:
            return{ ...state, template_title : action.payload}
        case ACTIONS.SET_TEMPLATE_SUBJECT:
            return{ ...state, template_subject : action.payload}
        case ACTIONS.SET_SEARCH_CANDIDATE_QUERY:
            return{ ...state, search_candidate_query : action.payload}
        case ACTIONS.SET_EMAIL_LOADING:
            return{ ...state, email_loading : action.payload}
        case ACTIONS.SET_SEARCH_JOB_QUERY:
            return{ ...state, search_job_query : action.payload}
        case ACTIONS.SET_TEMPLATE_EDITING:
            return{ ...state, template_editing : action.payload}
        case ACTIONS.SET_TEMPLATES:
            return{ ...state, templates : action.payload}
        case ACTIONS.RESET_ADD_CANDIDATE_FORM:
            return{ ...initialState }
        default: 
            return state
    }
}

export function AuthProvider({ children }){ 
const [currentUser, setCurrentUser] = React.useState()
const [isLoggedIn, setIsLoggedIn] = React.useState(false)
const [loading, setLoading] = React.useState(true)
const [state, dispatch] = useReducer(reducer, initialState)
const navigate = useNavigate()


 const generateGreetings = () => {

  var currentHour = moment().format("HH");

  if (currentHour >= 0 && currentHour < 12){
      return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 16){
      return "Good Afternoon";
  }   else if (currentHour >= 16 && currentHour < 19){
      return "Good Evening";
  } else if (currentHour >= 19 && currentHour < 23){
      return "Good Evening";
  } else {
      return "Hello"
  }

}

//AIzaSyB0JvD2tkii9vxWELitkSyLEuyuooqErK0
//743749347932-23gpui24ocnue4vgibs95urrugr688ni.apps.googleusercontent.com

const connectSocket = async (job) => {
    isLoading(true)
var socket = socketClient (SERVER);
    await socket.emit('getpipelines', {job}, (res) => {
    if(res){
    dispatch({type: ACTIONS.SET_PIPELINE, payload: res})
 isLoading(false)}else{
        dispatch({type: ACTIONS.SET_PIPELINE, payload: []})
        isLoading(false)
    }
        
});
}

//i.application.owner ? i.application.owner.first_name+' '+i.application.owner.last_name : i.application.candidate_name
const searchEmails = (type, text)=> {
    if(type === "inbox"){
    if(!text){
    dispatch({type: ACTIONS.SET_INBOX_EMAILS, payload: state.in_box_memory})
    }else{
    const filterData = state.inbox_emails.filter(
    candidate => {
    //let ownerData = (candidate.application.owner.first_name + ' ' + candidate.application.owner.last_name + ' ' + candidate.application.owner.email)
    let contactLowerCase = (
    candidate.application.candidate_email + ' ' + candidate.application.candidate_firstname + ' ' + candidate.application.candidate_lastname + ' ' + candidate.subject).toLowerCase();
    let findTermLowerCase = text.toLowerCase();
    return contactLowerCase.indexOf(findTermLowerCase) > -1
                })
    dispatch({type: ACTIONS.SET_INBOX_EMAILS, payload: filterData})}
    }else if(type === "sentbox"){
    if(!text){
    dispatch({type: ACTIONS.SET_SENT_EMAILS, payload: state.sent_box_memory})
    }else{
    const filterData = state.sent_emails.filter(
    candidate => {
    //let ownerData = (candidate.application.owner.first_name + ' ' + candidate.application.owner.last_name + ' ' + candidate.application.owner.email)
    let contactLowerCase = (
    candidate.application.candidate_email + ' ' + candidate.application.candidate_firstname + ' ' + candidate.application.candidate_lastname + ' ' + candidate.subject).toLowerCase();
    let findTermLowerCase = text.toLowerCase();
    return contactLowerCase.indexOf(findTermLowerCase) > -1
                })
    dispatch({type: ACTIONS.SET_SENT_EMAILS, payload: filterData})}
    }else if(type === "draft"){

    }
}
const currentContent = state.templateEditorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();
  const key = blockMap.last().getKey();
  const length = blockMap.last().getLength();
  const selection = new SelectionState({
    anchorKey: key,
    anchorOffset: length,
    focusKey: key,
    focusOffset: length,
  });

  const addPlaceholder = text => {
      const textWithInsert = Modifier.insertText(
        currentContent,
        selection,
        text,
        null,
      );
      const editorWithInsert = EditorState.push(
        state.templateEditorState,
        textWithInsert,
        'insert-characters',
      );
      const newEditorState = EditorState.moveSelectionToEnd(
        editorWithInsert,
        textWithInsert.getSelectionAfter(),
      );
      dispatch({ type: ACTIONS.SET_TEMPLATE_EDITOR_STATE_CHANGE, payload: newEditorState})
      onEditorTemplateStateChange(newEditorState);
   
  };

 const onEditorTemplateStateChange = (editorTemplateState) => {
    dispatch({type: ACTIONS.SET_TEMPLATE_EDITOR_STATE_CHANGE, payload: editorTemplateState})
     const dirtyHTML = draftToHtml(convertToRaw(editorTemplateState.getCurrentContent()))
  
   const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
  USE_PROFILES: { html: true },
});
dispatch({type: ACTIONS.SET_TEMPLATE_TEXT, payload: cleanHTML})
   }
   
   const getOneTemplate =async (id, draft) => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.get(`${url}get-one-template/${id}`, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then( async res => {
            const blocksFromHTML = convertFromHTML(res.data.html)
            const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
            dispatch({type: ACTIONS.SET_TEMPLATE_EDITOR_STATE_CHANGE, payload: EditorState.createWithContent(content)})
            dispatch({type: ACTIONS.SET_SELECTED_TEMPLATE, payload: res.data})
            dispatch({type: ACTIONS.SET_TEMPLATE_SUBJECT, payload: res.data.subject})
            dispatch({type: ACTIONS.SET_TEMPLATE_TITLE, payload: res.data.title})
           isLoading(false)
            }
        )
    } catch (error) {
        isLoading(false)
    }
   }


 
const createTemplate = async (e) => {
        try {
            e.preventDefault()
        const token = sessionStorage.getItem("token")
        isLoading(true)
        if(state.selected_template._id){
            await axios.patch(`${url}update-template/${state.selected_template._id}`, {html: state.template_text, title: state.template_title, subject: state.template_subject}, 
            {
                headers: {"Authorization": `Bearer ${token}`}
            }).then(
                res => {
                    notify("success", "Template updated successfully")
                    fetchTemplates()
                    templateDefault(e)
                    isLoading(false)
                }
            )
        }else{
            await axios.post(`${url}create-template`, {html: state.template_text, title: state.template_title, subject: state.template_subject}, 
            {
                headers: {"Authorization": `Bearer ${token}`}
            }).then(
                res => {
                    notify("success", "Template created successfully")
                    fetchTemplates()
                    templateDefault(e)
                    isLoading(false)
                }
            )
        }
        } catch (error) {
            isLoading(false)
            alert(error)
            notify("error", "Could not create template")
        }

        }
const templateDefault = (e) => {
    e.preventDefault()
    dispatch({type: ACTIONS.SET_TEMPLATE_EDITOR_STATE_CHANGE, payload: EditorState.createEmpty()})
    dispatch({type: ACTIONS.SET_SELECTED_TEMPLATE, payload: {}})
    dispatch({type: ACTIONS.SET_TEMPLATE_SUBJECT, payload: ""})
    dispatch({type: ACTIONS.SET_TEMPLATE_TITLE, payload: ""})
}
        
const fetchTemplates = async () => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.get(`${url}get-company-templates`, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then( async res => {
            dispatch({type: ACTIONS.SET_TEMPLATES, payload: res.data})
           isLoading(false)
            }
        )
    } catch (error) {
        isLoading(false)
    }
}

const set_event_duration = (data) => {
    if(data === "30 Minutes"){
    const startTime = state.event_date+' '+state.event_time
    const startTimeValue = moment(startTime)
    const endTime = moment(startTime).add(30, 'minutes');
    dispatch({type: ACTIONS.SET_EVENT_START_DATE, payload: startTimeValue})
    dispatch({type: ACTIONS.SET_EVENT_END_DATE, payload: endTime})
    }else if(data === "1 Hour"){
    const startTime = state.event_date+' '+state.event_time
    const startTimeValue = moment(startTime)
    const endTime = moment(startTime).add(1, 'hours');
    dispatch({type: ACTIONS.SET_EVENT_START_DATE, payload: startTimeValue})
    dispatch({type: ACTIONS.SET_EVENT_END_DATE, payload: endTime})
    }else{
    const endTime = moment().add(30, 'minutes');
    dispatch({type: ACTIONS.SET_EVENT_START_DATE, payload: new Date()})
    dispatch({type: ACTIONS.SET_EVENT_END_DATE, payload: endTime})
    }
}


const set_new_password = async (token) => {
    try {
        if(!state.new_password || !state.confirm_new_password)
        return notify("warning", "Please fill all fields");
        if(state.new_password.length < 8 || state.confirm_new_password.length < 8)
        return notify("warning", "Password can't be less than 8 characters")
        if(state.new_password !== state.confirm_new_password)
        return notify("warning", "Password is not equal")
        isLoading(true)
        await axios.put(`${url}update-forgot-password`, {password: state.new_password},{
            headers: {
              'Authorization': `Bearer ${token}`
            },
          }).then(
            res => {
                isLoading(false)
                notify("success", "Password Set Successfully")
                navigate("/login")
            }
        )  
    } catch (error) {
        isLoading(false)
        notify("error", "Token Error, Please contact your admin to send new Email")
    }
  
}

       

const sentCurrentSent = async (emailId) => {
    
    try {
        const id = emailId
        dispatch({type: ACTIONS.SET_EMAIL_LOADING, payload: true})
        const token = sessionStorage.getItem("token")
        await axios.get(`${url}get-one-email?id=${id}`, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(
        res => {
        if(res.data.draft){
        dispatch({type: ACTIONS.SET_CURRENT_DRAFT, payload: res.data})
        if(res.data.receipents.length > 0){
        const ress = res.data.receipents.split(",")
        const resObj = ress.map(i => ({value: i, label: i}))
        dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: resObj})}
        dispatch({type: ACTIONS.SET_EMAIL_SUBJECT, payload: res.data.subject})
        dispatch({type: ACTIONS.SET_CANDIDATE, payload: res.data.application})
        if(res.data.files.length > 0){
        dispatch({type: ACTIONS.SET_SELECTED_DOCUMENTS, payload: res.data.files})}
        if(res.data.text){
        const blocksFromHTML = convertFromHTML(res.data.text)
        const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: EditorState.createWithContent(content)})}
        }else{
        dispatch({type: ACTIONS.SET_CURRENT_SENT_EMAIL, payload: res.data})}
        dispatch({type: ACTIONS.SET_EMAIL_LOADING, payload: false})
            }
        )  
    } catch (error) {
        dispatch({type: ACTIONS.SET_EMAIL_LOADING, payload: false})
    }
}


const handleSelectedApplications = (data, type) => {
    if(type === "application"){
    if(data === "all"){
        if(state.selected_applications.length === state.candidates.length){
        dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: []})
        }else{
        const ids = state.candidates.map(i => (i._id))
        dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: ids})}
    }else{
        if(state.selected_applications.includes(data)){
        const filtered = state.selected_applications.filter(i => {return i !== data})
        dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: filtered})
        }else{
        const newArr = [...state.selected_applications, data]
        dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: newArr})
        }
    }}else if(type === "job"){
        if(data === "all"){
        if(state.selected_jobs.length === state.jobs.length){
        dispatch({type: ACTIONS.SET_SELECTED_JOBS, payload: []})
        }else{
        const ids = state.jobs.map(i => (i._id))
        dispatch({type: ACTIONS.SET_SELECTED_JOBS, payload: ids})}
    }else{
        if(state.selected_jobs.includes(data)){
        const filtered = state.selected_jobs.filter(i => {return i !== data})
        dispatch({type: ACTIONS.SET_SELECTED_JOBS, payload: filtered})
        }else{
        const newArr = [...state.selected_jobs, data]
        dispatch({type: ACTIONS.SET_SELECTED_JOBS, payload: newArr})
        }
        }
    }

}


const getPipelineJobs = async (job) => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.get(`${url}get-job-pipeline?job=${job}`, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(
            res => {
        dispatch({type: ACTIONS.SET_PIPELINE, payload: res.data})
        isLoading(false)
            }
        )  
    } catch (error) {
        isLoading(false)
        dispatch({type: ACTIONS.SET_OPEN_PIPELINE_MODAL, payload: false})
        notify("error", "Could not get pipeline")
    }
  
}

const completeTodo = async (id, data) => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.patch(`${url}update-todo/${id}`, {done: data}, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(
            res => {
                get_dasboard_data("weekly")
            }
        )  
    } catch (error) {
        isLoading(false)
        notify("error", "Could not get pipeline")
    }
  
}

const disconnectSocket = () => {
    var socket = socketClient (SERVER);
    dispatch({type: ACTIONS.SET_PIPELINE, payload: []})           
    socket.emit('disconnectPipeline', {}, (res) => {
       console.log("Disconnected")
    })
}

const handleSelectedEmail = (newValue: OnChangeValue, actionMeta: ActionMeta) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(actionMeta.option && !re.test(String(actionMeta.option.value).toLowerCase())){
        notify("warning", "Please input a valid email")
    }else{
    dispatch({type: ACTIONS.SET_SELECTED_EMAILS, payload: newValue})
    dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: newValue})

}
    
}

//get-sub-recruiter-link'

const sendRecruiterLink = async (id) => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.post(`${url}get-sub-recruiter-link?rid=${id}`,{}, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then( async res => {
            notify("success", "Activation Link Sent")
           isLoading(false)
        })
    } catch (error) {
        isLoading(false)
        notify("error", "Could not send link")
    }
}


const handleSelectRecruiter = (id) => {
    if(state.selected_recruiter === id){
        dispatch({type: ACTIONS.SET_SELECTED_RECRUITER, payload: ""})
    }else {
        dispatch({type: ACTIONS.SET_SELECTED_RECRUITER, payload: id})
    }

}
const fetchEmails = async () => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.get(`${url}get-user-email`, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then( async res => {
            const inbox_ = res.data.filter(i => (i.inbox))
            const sentbox_ = res.data.filter(i => (!i.draft))
            const draftbox_ = res.data.filter(i => (i.draft))
            dispatch({type: ACTIONS.SET_INBOX_EMAILS, payload: inbox_})
            dispatch({type: ACTIONS.SET_IN_BOX_MEMORY, payload: inbox_})
            dispatch({type: ACTIONS.SET_SENT_EMAILS, payload: sentbox_})
            dispatch({type: ACTIONS.SET_SENT_BOX_MEMORY, payload: sentbox_})
            dispatch({type: ACTIONS.SET_DRAFT_EMAILS, payload: draftbox_})
            dispatch({type: ACTIONS.SET_DRAFT_BOX_MEMORY, payload: draftbox_})
            //await dispatch({type: ACTIONS.SET_ALLEMAILS, payload: res.data})
            sentCurrentSent(inbox_[0]._id)
           isLoading(false)
            }
        )
    } catch (error) {
        isLoading(false)
    }
}

const createPipeline = async (job, data) => {
    try {
        if(!state.pipeline_name)return notify("warning", "Please enter a new stage")
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.post(`${url}create-pipeline`, { name: state.pipeline_name, job}, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(
            async res => {
            notify("success", "Stage created successfully")
            if(data && data === "connect"){
                connectSocket(job)
            }
            dispatch({type: ACTIONS.SET_PIPELINE_NAME, payload: ""})
            await getPipelineJobs(job)
            }
        )
    } catch (error) {
        notify("error", "Could not create stage")
        isLoading(false)
        
    }
}

const onEditorStateChange = (editorState) => {
    dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: editorState})
     const dirtyHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  
   const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
  USE_PROFILES: { html: true },
});
dispatch({type: ACTIONS.SET_EMAIL_TEXT, payload: cleanHTML})
   }

const updatePipeline = async (pipelineId, job, data) => {
    try {
        if(!state.pipeline_name)return notify("warning", "All fields required")
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.patch(`${url}update-pipeline/${pipelineId}`, {name: state.pipeline_name}, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(
           async res => {
            notify("success", "Stage updated successfully")
            dispatch({type: ACTIONS.SET_PIPELINE_NAME, payload: ""})
            dispatch({type: ACTIONS.SET_PIPELINE_ID, payload: ""})
            if(data && data === "connect"){
                connectSocket(job)
            }
            await getPipelineJobs(job)
            }
        )

    } catch (error) {
        notify("error", "Could not update stage")
        isLoading(false)
        
    }
}

const fetch_recruiters = async () => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
       await axios.get(`${url}fetch-sub-recruiters`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }}).then(res => {
                isLoading(false)
               dispatch({type: ACTIONS.SET_RECRUITERS, payload: res.data})
            })
    } catch (error) {
        isLoading(false)
    }
}
const create_recruiter = async(e) => {
    try {
        e.preventDefault()
        isLoading(true)
        const token = sessionStorage.getItem("token")
       await axios.post(`${url}create-sub-recruiter`, {email: state.recruiter_email, name: state.recruiter_name, position: state.recruiter_position, role: state.recruiter_role}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }}).then(async res => {
            await fetch_recruiters()
            notify("success", "Successfully added recruiter")
            await isLoading(false)
            })
    } catch (error) {
        isLoading(false)
        notify("error", "Could not create recruiter")
    }
}
//update-todo


const deletePipeline = async (pipelineId, job) => {
    try {
        console.log(pipelineId, job)
        isLoading(true)
        const token = sessionStorage.getItem("token")
        await axios.delete(`${url}delete-pipeline?id=${pipelineId}&job=${job}`, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(
           async res => {
            notify("success", "Stage deleted successfully")
            dispatch({type: ACTIONS.SET_PIPELINE_NAME, payload: ""})
            dispatch({type: ACTIONS.SET_PIPELINE_ID, payload: ""})
            await getPipelineJobs(job)
            }
        )
    } catch (error) {
        if(error.response.status === 403){
        notify("error", "You can't delete a default stage")
        }else{
        notify("error", "Could not delete stage")
        }
        isLoading(false)
        
    }
}

const defaultEmailValue = () => {
    try {
        dispatch({type: ACTIONS.SET_EMAIL_TEXT, payload: ""})
        dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: []})
        dispatch({type: ACTIONS.SET_EMAIL_SUBJECT, payload: ""})
        dispatch({type: ACTIONS.SET_EMAIL_NAME, payload: ""})
        dispatch({type: ACTIONS.SET_EMAIL_RECEIVERS, payload: []})
        dispatch({type: ACTIONS.SET_SELECTED_DOCUMENTS, payload: []})
        dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: false})
        dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: EditorState.createEmpty()})

    } catch (error) {
        notify("error", "An error occured")
    }
}

const deleteEmail = async (application, id) => {
    try {
        const token = sessionStorage.getItem("token")
        isLoading(true)
    axios.delete(`${url}delete-email/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(res => {
        notify("success", "Email Deleted")
        dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})
        dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: ""})
        application && fetchOneCandidate(application)
        !application && defaultEmailValue()
        fetchEmails()
        isLoading(false)
    })
    } catch (error) {
        notify("error", "Could not delete email")
        isLoading(false)
    }
   
}

//create-inbox

const sendInbox = async (id) => {
    try {
        isLoading(true)
        let formData = new FormData();
        const token = await sessionStorage.getItem("temp_token")
        state.selected_documents.forEach(element => {   
        formData.append("file", element[0]);
        });
        formData.append("text", state.email_text);
        await axios.post(`${url}create-inbox?id=${id}`, formData, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(res => {
            notify("success", "Email has been sent")
            defaultEmailValue()
            isLoading(false)
        })
      
    } catch (error) {
        notify("error", "Could not send email")
        isLoading(false)
        
    }
}


const sendEmail = async (data, candidateId) => {
    try {
        !data && isLoading(true)
        const getName = state.candidate.owner ? state.candidate.owner.first_name : state.candidate.candidate_name
        let formData = new FormData();
        const currentCandidate = state.candidate.owner ? {value: state.candidate.owner.email, label: state.candidate.owner.email} : {value: state.candidate.candidate_email, label: state.candidate.candidate_email}
        const gatherAll = currentCandidate && !state.openEmailForwardModal ? [currentCandidate, ...state.email_receipent] : [...state.email_receipent]
        const getEmails = gatherAll.map(i => {return i.value})
        const checkEmailId = state.emailId ? state.emailId : ""
        const token = await sessionStorage.getItem("token")
        state.selected_documents.forEach(element => {   
        formData.append("file", element[0]);
        });
        formData.append("forward", state.openEmailForwardModal ? true : false);
        formData.append("draft", data ? data : "");
        formData.append("emailId", checkEmailId)
        formData.append("application", state.candidate._id)
        formData.append("name", getName);
        formData.append("receipent", getEmails);
        formData.append("subject", state.email_subject);
        formData.append("text", state.email_text);
        formData.append("receivers", state.email_receivers);
        await axios.post(`${url}create-email`, formData, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(res => {
           !data && notify("success", "Email has been sent")
            data && notify("successDrop", "Email saved as draft")
            state.openEmailForwardModal && dispatch({type: ACTIONS.SET_OPEN_EMAIL_FORWARD_MODAL, payload: false})
            fetchOneCandidate(res.data.application)
            fetchEmails()
            defaultEmailValue()
            isLoading(false)
        })

        
      
    } catch (error) {
        !data && notify("error", "Could not send email")
        isLoading(false)
        
    }
}

const uploadFile = async (e) => {
    try {
        isLoading(true)
        e.preventDefault()
        let formData = new FormData();
        const token = await sessionStorage.getItem("token")
        state.selected_documents.forEach(element => {   
        formData.append("file", element[0]);
        });
        formData.append("application", state.candidate._id)
        await axios.post(`${url}create-file`, formData, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(res => {
           notify("success", "File has been uploaded")
           fetchOneCandidate(state.candidate._id)
           dispatch({type: ACTIONS.SET_SELECTED_DOCUMENTS, payload: []})
           dispatch({type: ACTIONS.SET_OPEN_FILE_MODAL, payload: false})
            isLoading(false)
        })
      
    } catch (error) {
        notify("error", "Could not upload file")
        isLoading(false)
        
    }
}

const handleSelectedDocuments = (data) => {
    const fileArray = [...state.selected_documents, data]
    dispatch({type: ACTIONS.SET_SELECTED_DOCUMENTS, payload: fileArray})
}

const removeDocument = (name) => {
    const newArr = state.selected_documents.filter(i => (i[0].name !== name))
    dispatch({type: ACTIONS.SET_SELECTED_DOCUMENTS, payload: newArr})
}
const togglePipeline = (id) => {
    try {
        if(id === state.pipelineDropDown){
        dispatch({type: ACTIONS.SET_PIPELINE_DROPDOWN, payload: ''})
        }else{
        dispatch({type: ACTIONS.SET_PIPELINE_DROPDOWN, payload: id})}
    } catch (error) {
      console.log(error)  
    }
}


const toggleActivePipeline = (id) => {
    try {
        if(id === state.pipelineDropDown){
        dispatch({type: ACTIONS.SET_PIPELINE_DROPDOWN, payload: ''})
        }else{
        dispatch({type: ACTIONS.SET_PIPELINE_DROPDOWN, payload: id})}
    } catch (error) {
      console.log(error)  
    }
}

const get_dasboard_data = async (data) => {
    try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        fetchCandidate(undefined, "")
        await axios.get(`${url}get-ats-dashboard-data?time=${data}`, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then( res => {
           dispatch({type: ACTIONS.SET_DASHBOARD_DATA, payload: res.data.data})
           dispatch({type: ACTIONS.SET_DASHBOARD_LABELS, payload: res.data.labels})
           dispatch({type: ACTIONS.SET_DASHBOARD_LABEL, payload: res.data.label})
           dispatch({type: ACTIONS.SET_TODOS, payload: res.data.todos})
           dispatch({type: ACTIONS.SET_RECENT_ACTIVITIES, payload: res.data.recentActivity})
           dispatch({type: ACTIONS.SET_INTERVIEWS, payload: res.data.interviews})
           dispatch({type: ACTIONS.SET_AWAITING_FEEDBACK, payload: res.data.awaiting_feedback})
           isLoading(false)
            }
        )
    } catch (error) {
        console.log(error)
        isLoading(false)
        
    }
}
const dropData =  async(result) => {
    try {   
        const {source, destination} = result;

        if(!destination)return;
        if(destination.droppableId === source.droppableId)return;

        const pipeline = state.pipelines
        const sourceId = source.droppableId
        const destinationId = destination.droppableId
        const draggableId = result.draggableId
        
        var sourcePipeline = pipeline.find(i => i._id === sourceId)
        var destinationPipeline = pipeline.find(i => i._id === destinationId)
        var draggableData = sourcePipeline.application.filter(i => (i._id === draggableId))
        destinationPipeline.application.push(...draggableData)
        var newData = sourcePipeline.application

        for (let i = 0; i < newData.length; i++) {
            if (newData[i]._id === draggableId) {
                newData.splice(i, 1);
              break;
            }
          }
        var socket = socketClient (SERVER);
        await socket.emit('dropApplication', {sourceId, destinationId, draggableId}, (res) => {
            if(!res){
                notify("errorDrop", "Your changes may not be saved, Please refresh")
            }
       // dispatch({type: ACTIONS.SET_PIPELINE, payload: res})           
            });
   
    } catch (error) {
        if(!error){ 
        notify("errorDrop", "Your changes may not be saved, Please refresh")}else{
            notify("errorDrop", "Your changes may not be saved, Please refresh")
        }
    }
}

const changeStage =  async(sourceId, destinationId, draggableId) => {
    try {   
        var socket = socketClient (SERVER);
        await socket.emit('dropApplication', {sourceId, destinationId, draggableId}, (res) => {
            if(!res){
                notify("errorDrop", "Your changes may not be saved, Please refresh")
            }
            fetchOneCandidate(draggableId)
            });
   
    } catch (error) {
        if(!error){ 
        notify("errorDrop", "Your changes may not be saved, Please refresh")}else{
            notify("errorDrop", "Your changes may not be saved, Please refresh")
        }
    }
}
const dragData = async(item, exId) => {
    try {
        const newPipeline = state.pipelines.filter(obj => {
    obj.application = obj.application.filter(el => el._id !== item);
    return obj.application; // Technically just `return obj.list.length;` would work; I prefer the clarity of the comparison
  })
        
        console.log(newPipeline)
  //  dispatch({type: ACTIONS.SET_PIPELINE, payload: newPipeline})           
    } catch (error) {
        console.log(error)
    }
}
  
const isLoading = (data) => {
  dispatch({type: ACTIONS.SET_LOADING, payload: data})
}

const notify = (type, message) =>{
    switch(type){
        case "success": {
        return toast.success(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "error": {
        return toast.error(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "warning": {
        return toast.warn(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "inform": {
        return toast.info(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
        case "errorDrop": {
        return toast.error(message, {position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: false})
        }
        case "successDrop": {
        return toast.success(message, {position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: false})
        }
        default: {
        return toast.info(message, {position: toast.POSITION.TOP_RIGHT, theme: "colored", transition: Slide,  hideProgressBar: true})
        }
    }
}


const create_application = async () =>{
   
    try {
        if(!state.candidate_name || !state.candidate_phone || !state.candidate_email || !state.candidate_link || !state.candidate_resume || !state.job)
 return notify("warning", "All fields required");
 isLoading(true)
    const token = await sessionStorage.getItem("token")
    let formData = new FormData();
          formData.append("file", state.candidate_resume);
          formData.append("file", state.candidate_image);
          formData.append("candidate_name", state.candidate_name);
          formData.append("candidate_email", state.candidate_email);
          formData.append("candidate_phone", state.candidate_phone);
          formData.append("job", state.job);
          formData.append("candidate_link", state.candidate_link);
          formData.append("ats", true);
          await axios.post(`${url}create-application`, formData, { headers: {
            'Authorization': `Bearer ${token}`
        }}).then(async res => {
            await dispatch({type: ACTIONS.RESET_ADD_CANDIDATE_FORM})
            isLoading(false)
            await notify("success", "Candidate created successfully")
            

          })
    } catch (error) {
        if(error.response){
            isLoading(false)
            notify("error", "Unable to create candidate")
        }else{
            isLoading(false)
            notify("error", "An error occured")
        }
    }

}

const fetchUserJobs = async (data, addData) => {
    try {
        isLoading(true)
        if(addData){
            dispatch({type: ACTIONS.SET_JOB_FILTER_TEXT, payload: addData})
        }
    const token = sessionStorage.getItem('token')
    await axios.get(`${url}get-employer-jobs/${data !== undefined ? data : ""}?searchText=${state.search_job_query}&dateRange=${addData}`,{ headers: {
                 'Authorization': `Bearer ${token}`
             }}).then(
      data => {
          dispatch({type: ACTIONS.SET_USER_JOBS, payload: data.data})
          isLoading(false)
        }
          
    )
    } catch (error) {
     console.log(error)
    }
}


const fetchCandidate = async (data, addData) => {
    try {
    isLoading(true)
    if(addData){
        dispatch({type: ACTIONS.SET_CANDIDATE_FILTER_TEXT, payload: addData})
    }
    const get_id_job = addData.id && addData.type === "job" ? addData.id : ""
    const get_id_stage = addData.id && addData.type === "stage" ? addData.id : ""

    const token = sessionStorage.getItem('token')
    const limit = data !== undefined ? `limit=${data}` : `limit=""`
    await axios.get(`${url}get-ats-candidates?${limit}&searchText=${state.search_candidate_query}&job=${get_id_job}&stage=${get_id_stage}`,{ headers: {
                 'Authorization': `Bearer ${token}`
             }}).then(
      data => {
         dispatch({type: ACTIONS.SET_CANDIDATES, payload: data.data.data})
         dispatch({type: ACTIONS.SET_TOTAL_CANDIDATE, payload: data.data.total})
       if(!state.candidate_filter_text.id) {dispatch({type: ACTIONS.SET_PIPELINE_LIST, payload: data.data.pipelines})}
         isLoading(false)
        })
    } catch (error) {
        isLoading(false)
     console.log(error)
    }
}

const fetchOneCandidate = async (data) => {
    try {
        isLoading(true)
    const token = sessionStorage.getItem("token")
    await axios.get(`${url}fetch-one-ats-candidate?candidate=${data}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(
       async res => {
          await dispatch({type: ACTIONS.SET_CANDIDATE, payload: res.data.application})
          await dispatch({type: ACTIONS.SET_CANDIDATE_EMAIL, payload: res.data.email})
          await dispatch({type: ACTIONS.SET_CANDIDATE_FILE, payload: res.data.file})
          await dispatch({type: ACTIONS.SET_CANDIDATE_ACTIVITY, payload: res.data.activity})
          await dispatch({type: ACTIONS.SET_CANDIDATE_NOTES, payload: res.data.notes})
          
    // {application, email, file, activity}

          isLoading(false)
           
    }
    )
    } catch (error) {
        console.log(error)
    }
}

const addNote = async (job) => {
    try {
        const token = sessionStorage.getItem("token")
        if(!state.note_text) return notify("warning", "Please type in note");
        await axios.post(`${url}create-tag`, {application: state.note_id, text: state.note_text},
        {headers: {"Authorization": `Bearer ${token}`}}).then(
            res => {
                notify("success", "Note Added")
                connectSocket(job)
                dispatch({type: ACTIONS.SET_NOTE_TEXT, payload: ""})
                dispatch({type: ACTIONS.SET_NOTE_MODAL, payload: false})
            }
        )
    } catch (error) {
        notify("error", "Could not add note")
    }
}
const disqualify_application = async (id, data, dataReason, job) => {
        try {
        isLoading(true)
        const token = sessionStorage.getItem("token")
        const postData = data === "withdraw" ? {withdraw : true, withdraw_reason: dataReason, id} : data === "reject" ? {rejected : true, rejection_reason: dataReason, id} : data === "delete" ? {delete: true, id} : {rejected : false, rejection_reason: "N/A", withdraw : false, withdraw_reason: "N/A", id} 
        await axios.put(`${url}update-application/${id}`, postData, {
            headers : { "Authorization" : `Bearer ${token}`}
        }).then(res => {
            dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: []})
            fetchCandidate(state.candidates.length, state.candidate_filter_text ? state.candidate_filter_text : "" )
            isLoading(false)
            dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})
            dispatch({type: ACTIONS.SET_DISQUALIFY_MODAL, payload: false})
            dataReason === "goback" && navigate("/candidates")
            dataReason !== "goback" && fetchOneCandidate(state.candidate._id)
            job && connectSocket(job)
            notify("success", data === "withdraw" ? "Application has been withdrawn": data === "reject" ? "Application has been disqualify" : data === "delete" ? "Application has been deleted" : "Application has been requalify")
        })      
        } catch (error) {
            isLoading(false)
            notify("error", data === "withdraw" ? "Application could not withdrawn": data === "reject" ? "Application could not disqualify" : data === "delete" ? "Application could not be deleted" : "Application could not be requalify")
        }
}

const close_job = async (id, data) => {
    try {
    isLoading(true)
    const token = sessionStorage.getItem("token")
    const postData = data === "close" ? {closed : true, id} : data === "delete" ? {isDeleted: true, id} : data === "open" ? {closed: false, id} : {}
    await axios.put(`${url}update-job/${id}`, postData, {
        headers : { "Authorization" : `Bearer ${token}`}
    }).then(res => {
        dispatch({type: ACTIONS.SET_SELECTED_JOBS, payload: []})
        fetchUserJobs(state.jobs.length, state.job_filter_text ? state.job_filter_text : "")
        isLoading(false)
        dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})
        notify("success", data === "close" ? "Job has been closed": data === "delete" ? "Job has been deleted" : data === "open" ? "Job has been opened" : "Action successfully done")
    })
        
    } catch (error) {
        isLoading(false)
        notify("error", data === "close" ? "Job could not be closed": data === "delete" ? "Job could not be deleted" : data === "open" ? "Job could not be opened" : "An error occured")
    }
}

const signIn = async (e) => {
    e.preventDefault()
        try {
        const { data } = await postt(`${url}userlogintoken?ats=${true}`, {email: state.email, password: state.password})
        const token = data
           await axios.get(`${url}verifytokenandgetuser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }}).then( async (response) => {
                setIsLoggedIn(true)
                await sessionStorage.setItem('token', token)
                const user = response.data.user
                dispatch({type: ACTIONS.SET_EMAIL, payload: ""})
                dispatch({type: ACTIONS.SET_PASSWORD, payload: ""})
                await setCurrentUser(user)
                await setLoading(false)
                await navigate("/dashboard")

           })  
        } catch (error) {
            if(error.response){
                if(error.response.status === 404){
                    notify("error", "Email not found")
                }else if(error.response.status === 403){
                    notify("error", "You are not an ATS user, Please sign up")
                }
                else if(error.response.status === 401){
                    notify("error", "Password not correct")
                }else if(error.response.status === 500){
                    notify("error", "An error occured")
                }
            }else{
                    notify("error", "Unable to connect to server")
            }
        }
         
}

const signEmailIn = async (email, token) => {
        try {
           await axios.post(`${url}user-email-auhenticate`, {email, emailId: token}).then( async (response) => {  
                await sessionStorage.setItem("temp_token", response.data)
                await dispatch({type: ACTIONS.SET_GOOGLE_LOGGED_IN, payload: true})
                await navigate(`/emailreply/${token}`)
                isLoading(false)
           })  
        } catch (error) {
            if(error.response){
                if(error.response.status === 403){
                    notify("error", "Email not provided")
                }else if(error.response.status === 400){
                    notify("error", "You have no access to this email")
                }
                else if(error.response.status === 401){
                    notify("error", "You have no proper access")
                }
            }else{
                    notify("error", "Unable to connect to server")
            }
        }
         
}

var gapi = window.gapi
var CLIENT_ID = "186240833315-c9hqbdv82mei9iuvcf91bffk4gjlgh0h.apps.googleusercontent.com";
var API_KEY = "AIzaSyCio72DmdvHtTXIkxbviJYym5YLPwyWIZc";
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
var SCOPES = "https://www.googleapis.com/auth/calendar";

   

const createEvent = async() =>{
   // e.preventDefault();
   try {
       if(!state.event_title || !state.event_type || !state.event_description || !state.event_start_date || !state.event_end_date)
       return notify("warning", "Please fill all space");
       const getCandidateEmail = state.candidate.owner ? state.candidate.owner.email : state.candidate.candidate_email
       const attenders = [{label: getCandidateEmail, value: getCandidateEmail}, ...state.selectedEmails]
       
    await gapi.load('client:auth2', async () => {
        gapi.client.init({
            apiKey: API_KEY,
            clientId: CLIENT_ID,
            discoveryDocs: DISCOVERY_DOCS,
            scope: SCOPES
        })
        await gapi.client.load('calender', 'v3', () => console.log('bam!'))
        await gapi.auth2.getAuthInstance().signIn().then(async(res) => {
            const token = sessionStorage.getItem("token")
            await axios.post(`${url}create-event`, {accesstoken: res.wc.access_token, summary: state.event_title, description: state.event_description, attendees: attenders, startDate: new Date(state.event_start_date), endDate: state.event_end_date, eventType: state.event_type, candidate: state.candidate._id},{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(
               async res => {
            notify("success", "Event successfully scheduled")
            dispatch({type: ACTIONS.SET_EVENT_TITLE, payload: ""})
            dispatch({type: ACTIONS.SET_EVENT_DESCRIPTION, payload: ""})
            dispatch({type: ACTIONS.SET_EVENT_TYPE, payload: ""})
            dispatch({type: ACTIONS.SET_SELECTED_EMAILS, payload: []})
            dispatch({type: ACTIONS.SET_EVENT_TITLE, payload: ""})
            dispatch({type: ACTIONS.SET_EVENT_DATE, payload: new Date()})
            dispatch({type: ACTIONS.SET_EVENT_TIME, payload: new Date()})
            dispatch({type: ACTIONS.SET_EVENT_DURATION, payload: ""})
            dispatch({type: ACTIONS.SET_EVENT_END_DATE, payload: null}) 
            dispatch({type: ACTIONS.SET_EVENT_START_DATE, payload: null})
            


                  isLoading(false)
                   
            }
            )
        })
    })
   } catch (error) {
       console.log(error)
   }
   
}

const update_password = async (e) => {
    e.preventDefault()
    try {
        const token = sessionStorage.getItem("token")
        if(state.confirm_new_password !== state.new_password)
        return notify("warning", "New Password does not match");
        isLoading(true)
        await axios.put(`${url}update-password/${currentUser._id}`, {email: state.email, password: state.password, newPassword: state.new_password}, {
            headers: { "Authorization": `Bearer ${token}`}
        }).then(res => {
            isLoading(false)
            notify("success", "Password updated successfully")
            dispatch({type: ACTIONS.SET_EMAIL, payload: ""})
            dispatch({type: ACTIONS.SET_PASSWORD, payload: ""})
            dispatch({type: ACTIONS.SET_NEW_PASSWORD, payload: ""})
            dispatch({type: ACTIONS.SET_CONFIRM_NEW_PASSWORD, payload: ""})
        })
    } catch (error) {
        isLoading(false)
        if(error && error.response){
        if(error.response.status === 404 || error.response.status === 403){
            notify("error", "Email not valid, please login again")
        }else if(error.response.status === 401){
            notify("error", "Password not correct")
        }else if(error.response.status === 400){
            notify("error", "New password is the same with old password")
        }else{
            notify("error", "Could not update your password")
        }
    }else {
        notify("error", "An error occured")
    }}
}
const getUser = () => {
        const token = sessionStorage.getItem("token") || sessionStorage.getItem("temp_token")
        axios.get(`${url}verifytokenandgetuser`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }}).then( (response) => {
                if(response.data.user.temp_user){
                    console.log(response)
                    dispatch({type: ACTIONS.SET_GOOGLE_LOGGED_IN, payload: true})
                    isLoading(false)
                    setLoading(false)
                }else{
                 const user = response.data.user
                 setIsLoggedIn(true)
                 setCurrentUser(user)
                 setLoading(false)}
           }).catch(err => {
               if(err)
               setIsLoggedIn(false)
               setLoading(false)
           })
    }

    function signOut(){
    sessionStorage.removeItem('token')
    return(setIsLoggedIn(false))}

    

    useEffect(() => {
        getUser();

    }, [])



const value = {
    currentUser,
    signIn,
    changeStage,
    count: state.count,
    dispatch,
    signOut,
    handleSelectedDocuments,
    isLoggedIn,
    fetchEmails,
    sendInbox,
    createTemplate,
    fetchTemplates,
    getOneTemplate,
    isLoading,
    onEditorStateChange,
    fetchUserJobs,
    deleteEmail,
    templateDefault,
    fetchCandidate,
    handleSelectedApplications,
    get_dasboard_data,
    uploadFile,
    defaultEmailValue,
    close_job,
    update_password,
    addPlaceholder,
    onEditorTemplateStateChange,
    fetchOneCandidate,
    handleSelectRecruiter,
    connectSocket,
    sendRecruiterLink,
    deletePipeline,
    sentCurrentSent,
    set_event_duration,
    getPipelineJobs,
    createEvent,
    handleSelectedEmail,
    create_recruiter,
    fetch_recruiters,
    completeTodo,
    toggleActivePipeline,
    togglePipeline,
    searchEmails,
    sendEmail,
    createPipeline,
    addNote,
    removeDocument,
    signEmailIn,
    updatePipeline,
    disqualify_application,
    dragData,
    set_new_password,
    dropData,
    state,
    disconnectSocket,
    time: generateGreetings(),
    create_application
} 

return (
    
    <AuthContext.Provider value={value}>
        {!loading && children}
    </AuthContext.Provider>
)
}
