import React from 'react'
import Billing from './settings/Billing'
import Pipeline from './settings/pipeline'
//import { Link } from 'react-router-dom'
//import { Icon } from '@iconify/react'
import { useAuths } from '../../context'
import Profile from './settings/profile'
import Security from './settings/Security'

export default function Settings() {
    const {currentUser} = useAuths()
    return (
        <main className="nopad-main">
            <div>
                <div className="mb-7">
                    <h1 className="main-title">Settings</h1>
                </div>
                <div className=" d-flex border align-items-stretch settings">
                    <div className="sidemenu  p-0 col-lg-2  border-right">
                        <div className="nav  tab-link mt-5 flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                          
                              <div className="pt-2 pb-2 ml-4">
                                    <p>Account</p>
                                </div>
                                {currentUser.isUserAdmin ? <a className="nav-link active" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="true">Profile</a> : null}
                                <a className={!currentUser.isUserAdmin ? "nav-link active" : "nav-link"} id="v-pills-billing-tab" data-toggle="pill" href="#v-pills-billing" role="tab" aria-controls="v-pills-billing" aria-selected="false">Billing</a>
                                <a className="nav-link" id="v-pills-security-tab" data-toggle="pill" href="#v-pills-security" role="tab" aria-controls="v-pills-security" aria-selected="false">Security</a>  
                           
                                <hr/>
                                <div className="pt-2 pb-2 ml-4">
                                    <p>Workflow</p>
                                </div>
                                <a className="nav-link" id="v-pills-pipeline-tab" data-toggle="pill" href="#v-pills-pipeline" role="tab" aria-controls="v-pills-pipeline" aria-selected="false">Pipeline</a>
                                {/*<a className="nav-link" id="v-pills-tag-tab" data-toggle="pill" href="#v-pills-tag" role="tab" aria-controls="v-pills-tag" aria-selected="false">Tags</a>*/}
                                <hr/>
                        </div>
                    </div>
                    <div className="">
                     <div className="tab-content p-5" id="v-pills-tabContent">
                        <div className={currentUser.isUserAdmin ? "tab-pane fade show active" : "tab-pane fade"} id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"><Profile/></div>
                        <div className={!currentUser.isUserAdmin ? "tab-pane fade show active" : "tab-pane fade"}  id="v-pills-billing" role="tabpanel" aria-labelledby="v-pills-billing-tab"><Billing/></div>
                        <div className="tab-pane fade" id="v-pills-security" role="tabpanel" aria-labelledby="v-pills-security-tab"><Security/></div>
                        <div className="tab-pane fade" id="v-pills-pipeline" role="tabpanel" aria-labelledby="v-pills-pipeline-tab"><Pipeline/></div>
                        {/*<div className="tab-pane fade" id="v-pills-tag" role="tabpanel" aria-labelledby="v-pills-tag-tab">Tag</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </main>
       
    )
}
