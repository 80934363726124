import React, { useEffect } from 'react'
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import { EditorState } from 'draft-js';
import { InputGroup, Input} from 'reactstrap'
//import profile from "../../../assets/profile-img.png"
//import  { useAuths }  from '../../../context';
import empty from "../../../assets/empty.png"
import { format } from 'timeago.js';
import { useAuths, ACTIONS } from '../../../context'
  import CreatableSelect from 'react-select/creatable';
  import { Editor } from "react-draft-wysiwyg";
  import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function My_inbox() {
  const {dispatch, state, sentCurrentSent, handleSelectedEmail, handleSelectedDocuments, removeDocument, onEditorStateChange, sendEmail, deleteEmail} = useAuths()
  useEffect(() => {
    state.draft_emails.length > 0 && sentCurrentSent(state.draft_emails[0]._id)
  
    return() => {
      dispatch({type: ACTIONS.SET_EMAIL_SUBJECT, payload: ""})
      dispatch({type: ACTIONS.SET_EMAIL_RECEIPENT, payload: []})
      dispatch({type: ACTIONS.SET_SELECTED_DOCUMENTS, payload: []})
      dispatch({type: ACTIONS.SET_EDITOR_STATE, payload: EditorState.createEmpty()})
    }
  }, [])
    return (
        <div className="new-mailbox-items">
        <div className="new-mailbox-list  mb-4 border-right bg-white">
                <div className="border-bottom pb-3 pt-3 px-3">
                  <div className="search-wrapper w-100 lf">
                        <InputGroup>
                        <span className="iconify-inline"><Icon icon="bx:bx-search"/></span>
                            <Input placeholder="Search mail"/>
                        </InputGroup>
                         </div>

                </div>

                <div className="scroll_segment pt-3 p-2">
                {state.draft_emails.map(i => ( i.application ?<div className={i._id === state.current_draft._id ? "mail-list opened-mail border-bottom mail-list-active" : "mail-list opened-mail border-bottom"} onClick={e => {e.preventDefault(); sentCurrentSent(i._id)}}>
                 <div className="sender-img">
                 <img width="43px" height="43px" style={{borderRadius: "20px"}} src={i.application.owner && i.application.owner.image ? i.application.owner.image : i.application.candidate_image ? i.application.candidate_image : empty} alt="" />
                 </div>
                  <div className="content">
                    <p className="sender-name">{i.application.owner ? i.application.owner.first_name+' '+i.application.owner.last_name : i.application.candidate_name}</p>
                    <p className="mail-subject">{i.subject}</p>
                    <p className="message_text" >{i.thread.length > 0 ? <div dangerouslySetInnerHTML={{ __html: i.thread[0].text }} /> : null}</p>
                  </div>
                  <div className="details">
                    <p className='date'>{format(i.createdAt)}</p>
                  </div>
                </div>: null))}
                </div>
              </div>



              
              <div className='p-5 new-mailbox-opened'>
              {state.email_loading ? <div className='email-loading-top text-center'>Loading...</div> : null}
           
            <div className='message-content'>
                <div className='row justify-content-between m-1'>
                  <div>
                    <h2>Draft Emails</h2>
                    </div>
                    <div className='mr-4'>
                    {state.current_draft._id && <a  className='btn btn-dark' href=" " onClick={e => {e.preventDefault(); deleteEmail("", state.current_draft._id)}}>Delete <span><Icon icon="fluent:delete-32-filled"/></span>  </a>}
                    </div>
                </div>
                {/* <div>
                    <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div> */}
            </div>
            <div className=''>

  <div className='pipe-add-input pt-3'>
               <p><b>Recipients</b></p>
   <CreatableSelect
    isMulti
    value={state.email_receipent}
    onChange={handleSelectedEmail}
    options={state.emails}
    isClearable={false}
    isSearchable
    placeholder="Search or type Email"
/>
  </div>
  </div>
            <div className='pt-3'>
            </div>
               <div className='pipe-add-input pt-3 mb-3'>
               <p><b>Subject</b></p>
  <input type="text" placeholder='Email Subject' value={state.email_subject} onChange={e => {dispatch({type: ACTIONS.SET_EMAIL_SUBJECT, payload: e.target.value})}}/>
  </div>
  <p><b>Body</b></p>
  <Editor
    wrapperClassName="wrapper-class border-right border-left"
    editorClassName="editor-class m-2"
    toolbarClassName="toolbar-class"
    editorState={state.editorState}
    toolbar={{
      options: [
        'inline',
        'fontSize',
        'fontFamily',
        'textAlign',
        'list',
        'link'
      ],
      inline: {
        options: ['italic', 'bold'],
      },
      blockType: {
        className: 'demo-option-custom-wide',
        dropdownClassName: 'demo-dropdown-custom',
      },
      fontSize: { className: 'demo-option-custom-medium' },
    }}
    toolbarCustomButtons={[<label htmlFor='select' className='btn mt-2'> <Icon icon="akar-icons:file" fontSize={18} /></label>]}
    onEditorStateChange={ editorState => {onEditorStateChange(editorState)}}
    />
    <div className=''>
     <div>
  <input className='m-1' type="file" hidden id='select' onChange={e => {handleSelectedDocuments(e.target.files)}}/>
  </div>
  <div>
  {
    state.selected_documents.map(i => (
       <div className='border p-2 mb-2 rounded d-flex justify-content-between' key={Math.random()}><a href={URL.createObjectURL(i[0])}>{i[0].name}</a> <a onClick={e => {e.preventDefault(); removeDocument(i[0].name)}} href=''> <Icon fontSize={25} className='ml-3 ' icon="bi:x"/></a></div> 
    ))
  }</div>
    </div>
  <div className='pt-3'>
<button className='btn  btn-dark' onClick={(e) => {e.preventDefault(); sendEmail(false, "")}}>Send</button>
  </div>

        </div>
              </div>
    ) }