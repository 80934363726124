import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import  { useAuths }  from './context';
import Navbar from './components/layouts/navbar';
import SideBar from './components/layouts/sidebar';
import LoadingOverlay from 'react-loading-overlay';

export default function PrivateRoute(){
    const {isLoggedIn, state} = useAuths()
return isLoggedIn ? 
<div>    

<input type="checkbox" id="nav-toggle"/>
          <SideBar/>
            <div className="main-content">
           <Navbar/>
           {/* <div className="protected-route"> */}
           <LoadingOverlay
            active={state.loading}
            spinner
            text='Loading...'
            >
           <Outlet/>
           </LoadingOverlay>
           {/* </div> */}
           </div>
</div> : <Navigate to="/login"/>
}