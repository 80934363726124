import React from 'react'
import { Icon } from '@iconify/react';
import {Link } from 'react-router-dom'
import snip from "../../../assets/resume.png"
import { useAuths } from '../../../context';

export default function Overview_tab() {
    const {state} = useAuths()
    return (
        <div>
        <div className="bg-whitee p-3">
            <div className="d-flex border-bottom pt-2 pb-3 mb-3">
             <p className="font-weight-bold col-3"><span><Icon icon="eva:email-fill"/></span> Email</p> 
             <p className="">{state.candidate.owner ? state.candidate.owner.email : state.candidate.candidate_email ? state.candidate.candidate_email : "Not Available"}</p>
            </div>
            <div className="d-flex border-bottom pt-2 pb-3 mb-3">
             <p className="font-weight-bold col-3"><span><Icon icon="clarity:phone-handset-solid"/></span> Phone</p> 
             <p className="">{state.candidate.owner ? state.candidate.owner.phone : state.candidate.candidate_phone ? state.candidate.candidate_phone : "Not Available"}</p>
            </div>
            <div className="d-flex pt-2 pb-3">
             <p className="font-weight-bold col-3"><span><Icon icon="ant-design:link-outlined"/></span> Links</p> 
             <p className="">{state.candidate.owner ? state.candidate.owner.web_link : state.candidate.candidate_link ? state.candidate.candidate_link : "Not Available"}</p>
            </div>
        </div>
        <div className="bg-whitee p-3 mt-4">
            <div className="d-flex justify-content-between">
                <h3 className="main-sub-title"> CV/Resume</h3>
                {/*<div className="d-flex">
                <Link className="btn btn-transparent icon-btn"to=""><span><Icon icon="ant-design:cloud-download-outlined"/></span></Link>
                <Link className="btn btn-transparent icon-btn"to=""><span><Icon icon="bi:cloud-upload"/></span></Link>
                </div>*/}
            </div>
            <div className="mt-2 img-container">
                <object data={state.candidate.resume ? state.candidate.resume : state.candidate.candidate_resume} type="application/pdf" className="mt-2 img-container" width="17%" height="50%">
                           <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
                    </object> 
            </div>
         </div>

         {state.candidate.owner && state.candidate.owner.skill ? <div className="bg-whitee p-3 mt-4">
            <div className="d-flex justofy-content-between">
              <h3 className="main-sub-title"> Skills</h3>
            </div>
            <div className="mt-2 ">
                <ul className="d-flex flex-wrap ">
                    {state.candidate.owner.skill.map(i => (<li key={i._id} className=" mr-3 bg-soft px-5 p-2  mt-2">{i.title}</li>))}
                    
                </ul>
            </div>
         </div> : null}
    </div>
    )
}