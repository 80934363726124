import React from 'react';
import {Pie} from 'react-chartjs-2';
import { useAuths } from '../../../context';


const Pie_Chart = () => {
  const {state} = useAuths()
  const states = {
    labels: [
      // 'Red',
      // 'Blue',
      // 'Yellow'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [300, 50, 100, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)',
        'rgb(48, 217, 159)'
      ],
      hoverOffset: 4
    }]
  };

  return(
    <div className='barchart'>
       <Pie
          data={states}
          options={{
            title:{
              display:true,
              text:'Top companies and their job posts',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
    </div>
  )
}

export default Pie_Chart