import React from 'react'
import { Icon } from '@iconify/react';
import { useAuths } from '../../../context';
import moment from 'moment';
//import {Link } from 'react-router-dom'

export default function Activities_tab() {
    const { state } = useAuths()
    return (
        <div className="activities mt-4">
        <h2 className="main-sub-title">
            Activities
        </h2>
        <div className="activity bg-whitee">
            <ul>
                {state.candidate_activity.map(i => (<>
                <div className="card-title activity-date">
                    <span>{moment(i.createdAt).format("D MMM Y")}</span>
                </div>
                <li>
                    <div className="activity-tag">
                        <div className="activity-icon">
                            <span className="iconify-inline">{i.type == "email" ? <Icon icon="carbon:chat"/> : <Icon icon="bi:send-check"/>}</span>
                        </div>
                        <div className="activity-text">
                        {i.narration}
                        </div>
                    </div>
                </li>
                </>))}
            </ul>
        </div>
    </div>
    )
}