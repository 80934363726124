import React from 'react'
import { Icon } from '@iconify/react'
import { format } from 'timeago.js';
import {
  Modal,
  ModalBody,
  ModalFooter, Collapse, CardBody, Card, ModalHeader, Spinner  } from "reactstrap"
import { Link } from 'react-router-dom';
import { useAuths, ACTIONS, rejection_reason_list, withdraw_reason_list } from '../../context';
import Dropdowns from 'react-multilevel-dropdown';
import empty from "../../assets/empty.png"
import { Draggable } from 'react-beautiful-dnd';



const Dragable = ({ name, id, job, img, appliedDate, tags, index}) => {
  const [selectedCard, setSelectedCard] = React.useState("")
 const { state, dispatch, disqualify_application, handleSelectedApplications, addNote } = useAuths()

 /*   const [{isDragging}, drag] = useDrag(() => ({
        type : "div",
        item: { id: id },
       // canDrag: () => dragData(id, exId),
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    }))
*/
function toggle(id) {
  if(selectedCard === id){
    setSelectedCard("")
  }else{
    setSelectedCard(id)
  }
}
    return (
        <>
        <Draggable
        draggableId={id}
        index={index}

        //style={{ border:"1px solid pink", borderRadius: "5px"}}
        >
          {(provided) => (
            
            <div 
            className="kanban-item"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            >
            <div className='kanban-item-head'>
            <div><img className="pr-2 kanban-img" alt="img" src={img ? img : empty}/></div>
            <Link className="kanban-name" to="/candidate_profile" state={{candidate : id}}> <h4> {name}</h4></Link>
          </div>
          <div className='kanban-meta'>
            <div className='Kanban-icon'>
              <Dropdowns
              wrapperClassName=""
              position='right'
              className="bg-transparent text-dark border-0"
              title={<Icon icon="icons8:cancel-2"/>} >                            
              <Dropdowns.Item onClick={(e) => { e.preventDefault(); dispatch({type: ACTIONS.SET_NOTE_ID, payload: id}); dispatch({type: ACTIONS.SET_DISQUALIFY_MODAL, payload: true})}}>
              Disqualify                                  
             </Dropdowns.Item> 
             <Dropdowns.Item onClick={(e) => { e.preventDefault(); handleSelectedApplications(id, "application"); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}} >
              Delete
            </Dropdowns.Item>
            </Dropdowns>
              <a href="?" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_NOTE_ID, payload: id}); dispatch({type: ACTIONS.SET_NOTE_MODAL, payload: true})}}><Icon icon="ic:outline-speaker-notes"/> </a>  
            </div>
            <a href="?" onClick={e => {e.preventDefault(); toggle(id)}}><Icon icon="carbon:view"/> </a>  
            <div className='kanban-date'>
                <p>{format(appliedDate)}</p>
            </div>
            
          </div>
          <Collapse isOpen={selectedCard === id}>
          <Card>
          {tags.map(i => (<CardBody key={i._id}>
            <p className='kanban-date'> 
           
           <div className='d-flex justify-content-between'><p>By: {i.creator && i.creator.isUserAdmin ? "Admin": i.creator && i.creator.recruiter_name ? i.creator.recruiter_name : "No name"}</p>  <p> {format(i.createdAt)}</p></div> 
            <p className=''>Note: {i.text}</p>
             </p>
            </CardBody>))}
          </Card>
        </Collapse>
          </div>
          )}
        </Draggable>
        <Modal
        size="md"
        isOpen={state.note_id === id}
        centered
        backdrop="static"
        keyboard={false}
        onClosed={() => {dispatch({type: ACTIONS.SET_NOTE_ID, payload: ""})}}
      >
        <ModalBody>
        <div className="">
                        <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h2>Add Note</h2>
                                </div>
                                <div>
                                    <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_NOTE_ID, payload: ""})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                  <div className='pipe-add-input'>
                  <p><b>Note</b></p>
                  <input type="text" placeholder='' value={state.note_text} onChange={e => {dispatch({type: ACTIONS.SET_NOTE_TEXT, payload: e.target.value})}}/>
                  </div>
                  <div className='pt-3 text-center'>
            <button className='btn btn-primary' onClick={e => {e.preventDefault(); addNote(job)}}>Add Note</button>
                  </div>

                        </div>

                    </div>

        </ModalBody>
        <ModalFooter>
        <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_NOTE_ID, payload: ""})}}>Close</a>
        </ModalFooter>
      </Modal>
      <Modal
        size="sm"
        isOpen={state.disqualify_modal}
        centered
        backdrop="static"
        keyboard={false}
        onClosed={() => {dispatch({type: ACTIONS.SET_NOTE_ID, payload: ""})}}
      >
        <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_DISQUALIFY_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
        </ModalHeader>
        <ModalBody className='text-center'>
        <Dropdowns position="right"
        title="Select Reason"
        >
                                          <Dropdowns.Item  isActive={false}>
                                           Applicant Rejected
                                           <Dropdowns.Submenu position="top" className='submenu'>
                                           { rejection_reason_list.map(r => (<Dropdowns.Item isActive={false} key={r} onClick={e=> {e.preventDefault(); disqualify_application(state.note_id, "reject", r, job)}}>
                                            {r}
                                           </Dropdowns.Item>))}
                                           </Dropdowns.Submenu>
                                            </Dropdowns.Item>
                                           <Dropdowns.Item>
                                           Applicant Withdraw
                                           <Dropdowns.Submenu position="buttom" className='submenu'>
                                           { withdraw_reason_list.map(w => (<Dropdowns.Item key={w} onClick={e=> {e.preventDefault(); disqualify_application(state.note_id, "withdraw", w, job)}}>
                                            {w}
                                           </Dropdowns.Item>))}
                                           </Dropdowns.Submenu>
                                            </Dropdowns.Item>
                                            </Dropdowns>

        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>
      <Modal
      size="sm"
      isOpen={state.openEmailDeleteModal}
      centered
      backdrop="static"
      keyboard={false}>
          <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: []}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
        </ModalHeader>
        <ModalBody>
        <div>
               {!state.loading ? <div className="modal-content p-2 border-0 text-center">
                            <div >
                                <div className='mb-4'>
                                    <h4>Delete Application ?</h4>
                                </div>
                                <div className='d-flex justify-content-between'>
                                   
                                <button className='btn btn-dark' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: []}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}}>Abort</button>
                                    <button className='btn btn-danger' style={{borderRadius: "20px"}} onClick={e => {e.preventDefault(); disqualify_application(state.selected_applications, "delete", "", job)}}>Confirm</button>
                                </div></div></div>: <Spinner/>}
             </div>
        </ModalBody>
      </Modal>
        </>
    )
}

export default Dragable