import React from 'react'
import { Editor } from "react-draft-wysiwyg";
import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useAuths} from "../../context"

function Emailreply() {
const {state, onEditorStateChange, handleSelectedDocuments, removeDocument, sendInbox} = useAuths()
const param = useParams()
const token = param.token

  return (
    <div className='fullwidth'>
        <div className='email-container'>

       <h2 className='pb-3'> Please add your reply here. </h2>
          <div className='interview-wysiwyg bg-whitee replyedit'>
                        
                  {/*<div className='row d-flex justify-content-between'>
                
                  <div className='pipe-add-input col-12 pt-3'>
                               <p><b>Recipients</b></p>
                   <CreatableSelect
                    isMulti
                    value={state.email_receipent}
                    onChange={handleSelectedEmail}
                    options={state.emails}
                    isClearable={false}
                    isSearchable
                    placeholder="Search or type Email"
                />
                  </div>
                 
                  </div>*/}
                  <Editor
                    wrapperClassName="wrapper-class border-right border-left"
                    editorClassName="editor-class mr-2 ml-2"
                    toolbarClassName="toolbar-class"
                    editorState={state.editorState}
                    toolbar={{
                      options: [
                        'inline',
                        'fontSize',
                        'fontFamily',
                        'textAlign',
                        'list',
                        'link'
                      ],
                      inline: {
                        options: ['italic', 'bold'],
                      },
                      blockType: {
                        className: 'demo-option-custom-wide',
                        dropdownClassName: 'demo-dropdown-custom',
                      },
                      fontSize: { className: 'demo-option-custom-medium' },
                    }}
                    toolbarCustomButtons={[<label htmlFor='select' className='btn mt-2'> <Icon icon="akar-icons:file" fontSize={18} /></label>]}
                    onEditorStateChange={ editorState => {onEditorStateChange(editorState)}}
                    />
                    <div className=''>
                    <div className='border-right border-bottom border-left' style={{minHeight: "50px"}}>
                  <div>
                  <input className='m-1' type="file" hidden id='select' onChange={e => {handleSelectedDocuments(e.target.files)}}/>
                  </div>
                  <div>
                  {
                    state.selected_documents.map(i => (
                       <div className='border p-2 mb-2 rounded d-flex justify-content-between' key={Math.random()}><a href={URL.createObjectURL(i[0])}>{i[0].name}</a> <a onClick={e => {e.preventDefault(); removeDocument(i[0].name)}} href=''> <Icon fontSize={25} className='ml-3 ' icon="bi:x"/></a></div> 
                    ))
                  }</div>
                    </div>
                    </div>
                  <div className='pt-3'>
            <button className='btn btn-primary' onClick={(e) => {e.preventDefault(); sendInbox(token)}}>Send</button>
                  </div>

                  </div>
        </div>
    </div>
  )
}

export default Emailreply