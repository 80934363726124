//import './App.css';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import PrivateRoute from './private_routes';
import EmailRoute from './email_routes';
import { AuthProvider } from "./context"
import Test from './components/employers/test'
import Candidates from './components/employers/candidates';
import Settings from './components/employers/settings';
import Pipeline from './components/employers/pipeline';
import Mailbox from './components/employers/mailbox';
import Reports from './components/employers/reports';
import Jobs from './components/employers/jobs';
import Signin from './components/authentications/signin';
import Dashboard from './components/employers/dashboard';
import CandidateProfile from './components/employers/candidate_profile';
import AddCandidate from './components/employers/add_candidate';
import Calendar from './components/employers/calendar';
import SetPassword from './components/authentications/setpassword';
import Emailreply from './components/employers/Emailreply';
import AuthEmailreply from './components/employers/AuthEmail';
import Error404 from './components/employers/Page-not-found';


function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
      <AuthProvider>
        <div className="">
       <Routes>
         <Route>
          <Route path="/" element={<PrivateRoute/>} >
          <Route path="/" element={<Navigate replace to="/dashboard"/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/candidates" element={<Candidates/>} />
          <Route path="/jobs" element={<Jobs/>} />
          <Route path="/reports" element={<Reports/>} />
          <Route path="/mailbox" element={<Mailbox/>} />
          <Route path="/create/candidate" element={<AddCandidate/>} />
          <Route path="/settings" element={<Settings/>} />
          <Route path="/pipeline" element={<Pipeline/>} />
          <Route path="/candidate_profile" element={<CandidateProfile/>} />
          <Route path="/add_candidate" element={<AddCandidate/>} />
          <Route path="/calendar" element={<Calendar/>} />
          <Route path="/manage/application" element={<Test/>} />
          </Route>
         </Route>
         <Route>
         <Route path="/" element={<EmailRoute/>} >
          <Route path="/emailreply/:token" element={<Emailreply/>} />
          </Route>
         </Route>
          <Route path="/login" element={<Signin/>} />
          <Route path="/set/new-password/:token" element={<SetPassword/>} />
          <Route path="/authemailreply/:token" element={<AuthEmailreply/>} />
          <Route path="*" element={<Error404/>} />
       </Routes>
       </div>
       </AuthProvider>
       </Router>
    </DndProvider>
  );
}

export default App;
