import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useAuths, ACTIONS } from "../../context"
import logo from '../../assets/logo-main-black.png'
import { useParams } from "react-router-dom"



const SetPassword = () => {
    const { dispatch, set_new_password } = useAuths()
    const param = useParams()
    const token = param.token

    return (
      <div className='loginpage'>

      <div className="m-auto mt-5 h-100 col-xxl-6 col-xl-5 col-lg-8 ">
          <div className="h-100 row align-items-center ">
            <div className="container">
              <div className="logo text-center p-3">
                <img src={logo} alt=""/>
              </div>
              <div className="bg-whitee p-5">
                <h2>Set Password</h2>
                <p>Logged in before ?<span>Sign in</span> </p>
                <Form className="m-4">
            <FormGroup>
              <Label for="exampleEmail">Password</Label>
              <Input onChange={e => {dispatch({type: ACTIONS.SET_NEW_PASSWORD, payload: e.target.value})}} type="password" name="password" id="examplePassword" placeholder="Enter Password Here" required/>
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Confirm Password</Label>
              <Input onChange={e => {dispatch({type: ACTIONS.SET_CONFIRM_NEW_PASSWORD, payload: e.target.value})}} type="password" name="password" id="examplePassword" placeholder="Confirm Password Here" required/>
            </FormGroup>
            <Button type="submit" onClick={e => {e.preventDefault(); set_new_password(token)}} color="#1E1E2D" block className='p-3 mt-3'>Set Password</Button>
            </Form>
              </div>

            </div>
          </div>
        </div>

      </div>
    )
}

export default SetPassword