import React, { useEffect } from 'react'
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom'
import { useAuths, ACTIONS} from '../../context';


export default function Add_candidate() {
    const { create_application, dispatch, state, fetchUserJobs } = useAuths()
    useEffect(() =>{
        fetchUserJobs("all", "")

        return()=> {
        fetchUserJobs(undefined, "")
        }
    },[])

    return (
        <main>
            <div className="mb-7">
                <h1 className="main-title">Add Candidate</h1>
            </div>
            <div className="row add-container  col-8 bg-whitee pt-5 m">
                <div className="col p-2">
                    <form onSubmit={(e) => {e.preventDefault(); create_application()}}>
                        <div className="form-group">
                            {state.candidate_image ? <div className='d-flex justify-content-center'><label className='d-flex justify-content-center' htmlFor='imageFileUpload'>
                                <img alt='img' style={{margin: "auto", height: "120px", width: "120px", borderRadius: "60px"}} src={URL.createObjectURL(state.candidate_image)}/></label>
                                <a onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_CANDIDATE_IMAGE, payload: undefined})}} href="/"><h2> <Icon icon="bi:x"/></h2></a>
                                </div>  : <div><label htmlFor='imageFileUpload' className="upload-image text-center p-5 mb-5">
                                <span className="iconify-inline"><Icon icon="bi:card-image"/></span>
                            </label>
                        <input type="file" id="imageFileUpload" hidden onChange={(e) => {dispatch({type: ACTIONS.SET_CANDIDATE_IMAGE, payload: e.target.files[0]}) }} />
                            </div>}
                            <div className="add-flex pt-2 pb-3">
                                <label className="add-left">Full Name</label>
                                <input required value={state.candidate_name} type="text" className="form-control h-px-48" id="" placeholder="eg. Johnson Bolaji" onChange={e => {dispatch({type: ACTIONS.SET_CANDIDATE_NAME, payload: e.target.value})}}/>
                            </div>
                            <div className="add-flex pt-2 pb-3 border-bottom pb-5">
                                <label className="add-left">Assign Job</label>
                                <select  className="form-select form-control h-px-48" onChange={e => {dispatch({type: ACTIONS.SET_JOB, payload: e.target.value})}}>
                                {state.jobs.map(i => (<option key={i._id} value={i._id}>{i.jobrole}</option>))}
                                </select>
                            </div>
                           <div className="pt-5 border-bottom pb-5">
                            <div className="add-flex pt-2 pb-3">
                                    <label className="add-left al-center"> 
                                        <p className="h3 pr-2 "><Icon icon="eva:email-fill"/></p>
                                        <p>Email</p>
                                     </label>
                                    <input required value={state.candidate_email} type="text" className="form-control h-px-48" id="" placeholder="" onChange={e => {dispatch({type: ACTIONS.SET_CANDIDATE_EMAIL, payload: e.target.value})}}/>
                                    <a onClick={e => {e.preventDefault()}} href="/"><h2> <Icon icon="bi:x"/></h2></a>
                                </div>

                                <div className="add-flex pt-2 pb-3">
                                    <label className="add-left al-center"> 
                                        <p className="h3 pr-2 "><Icon icon="clarity:phone-handset-solid"/></p>
                                        <p>Phone</p>
                                     </label>
                                    <input required value={state.candidate_phone} type="text" className="form-control h-px-48" id="" placeholder="" onChange={e => {dispatch({type: ACTIONS.SET_CANDIDATE_PHONE, payload: e.target.value})}}/>
                                    <a onClick={e => {e.preventDefault()}} href="/"><h2> <Icon icon="bi:x"/></h2></a>
                                </div>

                                <div className="add-flex pt-2 pb-3">
                                    <label className="add-left al-center"> 
                                        <p className="h3 pr-2 "><Icon icon="ant-design:link-outlined"/></p>
                                        <p>Link</p>
                                     </label>
                                    <input required value={state.candidate_link} type="text" className="form-control h-px-48" id="" placeholder="" onChange={e => {dispatch({type: ACTIONS.SET_CANDIDATE_LINK, payload: e.target.value})}}/>
                                    <a onClick={e => {e.preventDefault()}} href="/"><h2> <Icon icon="bi:x"/></h2></a>
                                </div>
                                
                           </div>
                           <div className="pt-5 pb-5 border-bottom">
                           {state.candidate_resume ? 
                           <>
                           <a onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_CANDIDATE_RESUME, payload: undefined})}} href="/"><h2> <Icon icon="bi:x"/></h2></a>
                           <object data={URL.createObjectURL(state.candidate_resume)} type="application/pdf" width="17%" height="50%">
                           <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
                       </object></>: null}
                                <h4 className="pt-2 pb-2">CV/Resume</h4>
                            <input type="file" id="resumeFileUpload" hidden onChange={(e) => { dispatch({type: ACTIONS.SET_CANDIDATE_RESUME, payload: e.target.files[0]}) }} />
                                <label htmlFor='resumeFileUpload' className="btn btn-dark"><span><Icon icon="dashicons:upload"/></span>Upload File</label>
                           </div>

                           <div className="pt-5 pb-5 col">
                                <div className="float-right">
                                <Link to="" className="btn btn-transparent">Cancel</Link>
                                <button type='submit' className="btn btn-dark"> <span className="iconify-inline"><Icon icon="bi:plus-lg"/></span>Add</button>
                                </div>
                           </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
}
