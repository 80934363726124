import React, { useEffect } from 'react'
import { Icon } from '@iconify/react';
import Interview from './Templates/Interview';
import { useAuths } from '../../../context';

export default function Templates_tag() {
    const {fetchTemplates, state, getOneTemplate, templateDefault} = useAuths()

    useEffect(() => {
        fetchTemplates()
    }, [])
    return (
        <div className="activities ">
            <div className="email_template">

                <div className="border-right temp-1">
                    <div className='p-3 pt-5'>
                        <h3>Email Templates</h3>
                        <p className='pt-2'>
                        Create and manage email templates used in your company.
                        </p>
                        <a onClick={templateDefault} href="/" className="btn btn-dark mt-2"><span className="iconify-inline"><Icon icon="bi:plus-lg"/></span>New Template</a>
                    </div>

                    <div className="scroll_segment pt-3  mt-5">
                        <h5>System Templates</h5>
                        <ul>
                            {state.templates.map(i => (<li key={i._id} className='temp_btn'><a onClick={e => {e.preventDefault(); getOneTemplate(i._id, "")}} href="/" className={i._id === state.selected_template._id ? "nav-link active-temp" : "nav-link"}>{i.title}</a></li>))}
                        </ul>
                    </div>

                </div>

                <div className='temp-2'>
                    <Interview/>
                </div>
            </div>
        </div>
    )}