import { Icon } from '@iconify/react'
import React, { useEffect } from 'react'
import empty from "../../../assets/empty.png"
import {useAuths, ACTIONS} from "../../../context"
import {
    Modal,
    ModalBody,
    ModalFooter,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner } from "reactstrap"

function Profile() {
    const {state, create_recruiter, fetch_recruiters, currentUser, dispatch, handleSelectRecruiter, sendRecruiterLink} = useAuths()
    useEffect(() => {
        fetch_recruiters()
    }, [])
    return (
        <div className='profile-page'>
            <div className='profile-tab'>
                <img src={currentUser.image ? currentUser.image : empty} alt='company logo'></img>
                <div className='profile-tab-info'>
                    <h3>{currentUser.company_name}</h3>
                    <h4><span></span>|<span>{currentUser.email}</span> | <span>{currentUser.isUserAdmin ? "Administrator": "Recruiter"}</span></h4>
                </div>
            </div>
            <div className='pt-5'>
                <h4 className='border-bottom'>Recruiters</h4>
                <div className='recruiter_set'>
                    <div className='addrecruiter p-3 '>
                    <a href='' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_ADD_RECRUITER_MODAL, payload: true})}} class="btn btn-dark"  data-toggle="modal" data-target=".bd-example-modal-lg"><Icon icon="bi:plus-lg"/>Add Recruiter</a>
                    </div>
                    <div className='recruiters_list'>
                        <ul>
                            {state.recruiters.map(i => (<li>
                            <div  className='active-job-card'>
                                <div className='active-job-title'>
                                <div>
                                <div className="mailer-icon rounded-circle bg-primary text-white p-3 mr-2">
                                    <h3>{i.recruiter_name ? i.recruiter_name.split(" ")[1] ? i.recruiter_name.split(" ")[0].split("")[0]+''+i.recruiter_name.split(" ")[1].split("")[0] : i.recruiter_name.split(" ")[0]: null}</h3>
                                </div>
                                </div>
                                <div>
                                    <h4>{i.recruiter_name}</h4>
                                    <p>{i.position}</p>
                                </div>
                                </div>
                                <div className="dropdown pt-2">
                                       
                                        <Dropdown isOpen={i._id === state.selected_recruiter ? true : false} toggle={() => {handleSelectRecruiter(i._id)}}>
                            <DropdownToggle className="bg-transparent drop-down-icon text-dark border-0">
                            <div className='more-btn'>
                                            <Icon  icon="carbon:overflow-menu-vertical"/>
                                        </div>
                            </DropdownToggle>
                            <DropdownMenu
                                modifiers={{
                                setMaxHeight: {
                                    enabled: true,
                                    order: 890,
                                    fn: (data) => {
                                    return {
                                        ...data,
                                        styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: '200px',
                                        },
                                    };
                                    },
                                },
                                }}
                            >
                                <DropdownItem className='btn btn-secondary' onClick={() => {sendRecruiterLink(i._id)}}>Resend Link</DropdownItem>
                                <DropdownItem className='btn btn-danger' onClick={() => {}}>Delete</DropdownItem>

                            </DropdownMenu>
                            </Dropdown>
                                        </div>
                                </div>
                            </li>))}
                            
                        </ul>
                    </div>
                </div>
            </div>
                    <Modal
                    size="lg"
                    isOpen={state.openAddRecruiterModal}
                    centered
                    backdrop="static"
                    keyboard={false}
                    >
                    <ModalBody>
                        <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h2>Invite Recruiter</h2>
                                </div>
                                <div>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <form onSubmit={create_recruiter}>
                            <div className='pr-5 mt-5'>
                                   <p><b>Name</b></p>
                                   <input value={state.recruiter_name} className='form-control' type="text"  placeholder='Samuel Peter' required onChange={e => {dispatch({type: ACTIONS.SET_RECRUITER_NAME, payload: e.target.value})}}/>
                               </div>
                            <div className='pt-5 '>
                               <div className='pr-5'>
                                   <p><b>Choose a role</b></p>
                                   <select className='form-control  h-px-48' required onChange={e => {dispatch({type: ACTIONS.SET_RECRUITER_ROLE, payload: e.target.value})}}>
                                    <option  selected disabled>Select Role</option>
                                    <option value="Administrator"> Administrator</option>
                                    <option value="Recruiter"> Recruiter</option>
                                   </select>
                                  
                               </div>
                               <div className='pr-5 mt-5'>
                                   <p><b>Position</b></p>
                                   <input className='form-control' value={state.recruiter_position} type="text"  placeholder='HR Analyst' required onChange={e => {dispatch({type: ACTIONS.SET_RECRUITER_POSITION, payload: e.target.value})}}/>
                               </div>
                               <div className='pr-5 mt-5'>
                                   <p><b>Send Invite to:</b></p>
                                   <input className='form-control' value={state.recruiter_email} type="email"  placeholder='sam@strivehumanresources.com' required onChange={e => {dispatch({type: ACTIONS.SET_RECRUITER_EMAIL, payload: e.target.value})}}/>
                               </div>
                           

                               <div className='pr-5 mt-5'>
                                   <button className="btn btn-dark" type="submit">Send Invitation</button>
                               </div>
                               
                            </div>
                            </form>
                        </div></ModalBody>
                        <ModalFooter>
                        <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_ADD_RECRUITER_MODAL, payload: false}); dispatch({type: ACTIONS.SET_RECRUITER_EMAIL, payload: ""}); dispatch({type: ACTIONS.SET_RECRUITER_NAME, payload: ""}); dispatch({type: ACTIONS.SET_RECRUITER_POSITION, payload: ""}); dispatch({type: ACTIONS.SET_RECRUITER_ROLE, payload: ""})}}>Close</a>
                        </ModalFooter>
                        </Modal>
                   
        </div>
    )
}

export default Profile
