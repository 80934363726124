import React from 'react'
//import { Button } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/logo-main-black.png'
import { Icon } from '@iconify/react';
// import  { useAuths }  from '../../context';



export default function SideBar() {
    // const { currentUser} = useAuths()
    const location = useLocation();

    return(
    <div className="sidebar">
        <div className="sidebar-brand">
            <h2> <span> <img src={logo} alt=""/></span></h2>
            
        </div>

        <div className="sidebar-menu">
            <ul>
                <li>
                    <Link to="/dashboard" className={location.pathname === "/dashboard" ? "sidebar-active" : null}> <span className="iconify-inline"><Icon icon="bx:bxs-bar-chart-square" /></span> 
                    <span>Dashboard</span></Link>
                </li>
                <li>
                    <Link to="/candidates" className={location.pathname ===  "/candidates" ? "sidebar-active" : null}><span className="iconify-inline"><Icon icon="bx:bxs-user-account"/></span> 
                    <span>Candidate</span></Link>
                </li>
                <li>
                    <Link to="/jobs" className={location.pathname === "/jobs" ? "sidebar-active" : null}><span className="iconify-inline"><Icon icon="fa-solid:suitcase"/></span>
                    <span>Jobs</span></Link>
                </li>
                <li>
                    <Link to="/mailbox" className={location.pathname === "/mailbox" ? "sidebar-active" : null}><span className="iconify-inline"><Icon icon="fluent:mail-24-filled"/></span>
                    <span>Mailbox</span></Link>
                </li>
                <li>
                    <Link to="/reports" className={location.pathname === "/reports" ? "sidebar-active" : null}><span className="iconify-inline"><Icon icon="bi:bar-chart-fill"/></span>
                    <span>Report</span></Link>
                </li>
                <li>
                    <Link to="/settings" className={location.pathname === "/settings" ? "sidebar-active" : null}><span className="iconify-inline"><Icon icon="eva:settings-fill"/></span>
                    <span>Settings</span></Link>
                </li>
            </ul>
        </div>

        <div className="sidebar-help">
            <div className="help-box">
                <div className="help-icon">
                   <Link to=""><span className="iconify-inline"> <Icon icon="bx:bxs-help-circle" /> </span></Link> 
                </div>
            </div>
            <div className="dark-card ">
                <h4>Need help with the ATS?</h4>
                <Link to="" className="btn btn-white">Go to help center</Link>
            </div>
        </div>

    </div>
    )}