import React from 'react'
import Active from './Pipeline/active'
import Inactive from './Pipeline/inactive'

function pipeline() {
  return (
    <div className='scrollstuff'>
        <div>
            <h3>Job List</h3>
        </div>

        <div className='mt-5'>
        <ul className="nav nav-pills  transparent mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-active-tab" data-toggle="pill" href="#pills-active" role="tab" aria-controls="pills-active" aria-selected="true">Active</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-inactive-tab" data-toggle="pill" href="#pills-inactive" role="tab" aria-controls="pills-inactive" aria-selected="false">Inactive</a>
                    </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab"><Active/></div>
                    <div className="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab"><Inactive/> </div>
        </div>
        </div>
    </div>
  )
}

export default pipeline