import React from 'react'
import {Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import { useAuths, ACTIONS } from '../../../context';
import { format } from 'timeago.js';
import {
    Modal,
    ModalBody,
    ModalFooter,
    Spinner } from "reactstrap"

export default function Files_tab() {
    const {state, dispatch, removeDocument, handleSelectedDocuments, uploadFile} = useAuths()
    return (
        <div>
            <div className="d-flex justify-content-between">
                <div>

                </div>
                <div>
                <a className="btn btn-dark" href='' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_FILE_MODAL, payload: true})}}><span><Icon icon="dashicons:upload"/></span>Upload File</a>
                </div>
            </div>
        <div>
            <ul className="row mt-2 files-tab">
                {state.candidate_file.map(i => (<li key={i._id} className=" m-2">
                    <div className="mt-2 file-container ">
                        <object data={i.path} type="application/pdf" width={226} height={130}></object>
                    </div>
                    <div className="p-3 ">
                        <p className="font-weight-bold">{i.name}</p>
                        <p>Uploaded {format(i.createdAt)}</p>
                        <br/>
                        <div className="text-right">
                            <a className="btn" href={i.path} target="_blank" rel="noreferrer" download={true}><span className="circle"  ><Icon icon="dashicons:upload"/> </span></a>
                            <span> <a className="btn btn-dark" href={i.path} target="_blank" rel="noreferrer">View</a> </span>
                        </div>
                    </div>
                </li>))}
            </ul>
        </div>
        <Modal
        size="lg"
        isOpen={state.openFileModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
        <div className="modal-dialog modal-lg">
                        {!state.loading ? <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h2>Add Files</h2>
                                </div>
                                <div>
                                    <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_FILE_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className='d-flex row justify-content-end'>
                  <label htmlFor='select' className='btn btn-dark m-3'><span>Select File <Icon icon="akar-icons:file" fontSize={18} /></span></label>
                  <input type="file" hidden id='select' onChange={e => {handleSelectedDocuments(e.target.files)}}/>
                  </div>
                  <p>Note: You can select multiple files (pdf, word)</p>
                    <div className='border rounded' style={{minHeight: "50px"}}>
                  
                  <div>
                  {
                    state.selected_documents.map(i => (
                       <div className='border p-2 mb-2 rounded d-flex justify-content-between' key={Math.random()}><a href={URL.createObjectURL(i[0])}>{i[0].name}</a> <a onClick={e => {e.preventDefault(); removeDocument(i[0].name)}} href=''> <Icon fontSize={25} className='ml-3 ' icon="bi:x"/></a></div> 
                    ))
                  }</div>
                    </div>
                  <div className='pt-3'>
            <button className='btn btn-primary' onClick={uploadFile}>Upload</button>
                  </div>

                        </div>: <Spinner/>}

                    </div>

        </ModalBody>
        <ModalFooter>
        <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_FILE_MODAL, payload: false})}}>Close</a>
        </ModalFooter>
      </Modal>
    </div>
    )
}