import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { useAuths, ACTIONS } from "../../context"
import logo from '../../assets/logo-main-black.png'



const Signin = () => {
    const { signIn, dispatch } = useAuths()

    return (
      <div className='loginpage'>

      <div className="m-auto mt-5 h-100 col-xxl-6 col-xl-5 col-lg-8 ">
          <div className="h-100 row align-items-center ">
            <div className="container">
              <div className="logo text-center p-3">
                <img src={logo} alt=""/>
              </div>
              <div className="bg-whitee p-5">
                <h2> Log in</h2>
                <a href='https://jobboard.strivehumanresources.com/employer-sign-up' target="_blank">Don't have an account?<span>Create One</span> </a>
                <Form onSubmit={signIn} className="m-4">
            <FormGroup>
              <Label for="exampleEmail">Email</Label>
              <Input onChange={e => {dispatch({type: ACTIONS.SET_EMAIL, payload: e.target.value})}} type="email" name="email" id="exampleEmail" placeholder="Enter Email Address" required/>
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input onChange={e => {dispatch({type: ACTIONS.SET_PASSWORD, payload: e.target.value})}} type="password" name="password" id="examplePassword" placeholder="Enter Password Here" required/>
            </FormGroup>
            <Button type="submit" color="#1E1E2D" block className='p-3 mt-3'>Login</Button>
            </Form>
              </div>

            </div>
          </div>
        </div>

      </div>
    )
}

export default Signin