import { Icon } from '@iconify/react'
import React, {useEffect} from 'react'
import { useAuths, ACTIONS } from "../../../../context"
import {
  Modal,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner } from "reactstrap"

function Active() {
  const {state, fetchUserJobs, getPipelineJobs, dispatch, createPipeline, updatePipeline, toggleActivePipeline, deletePipeline} = useAuths()
  useEffect(() => {
    fetchUserJobs(undefined, "")

    return()=> {
    fetchUserJobs(undefined, "")
    }
}, [])
  return (
    <div>
      {state.jobs.map(i => {if(!i.closed)  return(
        <div key={i._id} className='active-job-card'>
        <div className='active-job-title'>
          <div>
          <span className="active-label mr-2"></span>
          </div>
          <div>
            <h4>{i.jobrole}</h4>
            <p>{i.type.type}</p>
          </div>
        </div>
        <div>
          <a href='#' type="button" className="" data-toggle="modal" data-target="#exampleModalCenter" onClick={e => {e.preventDefault(); getPipelineJobs(i._id); dispatch({type: ACTIONS.SET_OPEN_ACTIVE_PIPELINE_MODAL, payload: true})}}>
        <Icon icon="eva:settings-2-fill"/>
        </a>
        {/* <button >
          Launch demo modal
        </button> */}
        </div>
        </div>
      )})}
       
       <Modal
        size="md"
        isOpen={state.openActivePipelineModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
        
        <div className="modal-dialog modal-22 modal-dialog-centered" role="document">
            <div className="modal-content">
              {state.pipelines.length > 0 ? <>
              <div className="modal-header">
                <h4 className="modal-title" id="exampleModalLongTitle">{state.pipelines[0].job.jobrole}</h4>
              </div>
              <div className="modal-body">
                <div className='pipe-container'>
                  
                  {state.pipelines.map(i => (<div className='pipe-item' key={i._id}>
                    <div className='pipe-title'>
                      <div className='mr-2'>
                       <a href='' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_PIPELINE_NAME, payload: i.name}); dispatch({type: ACTIONS.SET_PIPELINE_ID, payload: i._id})}}> <Icon icon="akar-icons:drag-vertical-fill"/></a>
                      </div>
                      <div>
                        <h5>{i.name}</h5>
                      </div>
                    </div>
                    <Dropdown isOpen={i._id === state.pipelineActiveDropDown ? true : false} toggle={() => {toggleActivePipeline(i._id)}}>
  <DropdownToggle className="bg-transparent drop-down-icon text-dark border-0">
  <Icon icon="carbon:overflow-menu-vertical"/>
  </DropdownToggle>
  <DropdownMenu
    modifiers={{
      setMaxHeight: {
        enabled: true,
        order: 890,
        fn: (data) => {
          return {
            ...data,
            styles: {
              ...data.styles,
              overflow: 'auto',
              maxHeight: '200px',
            },
          };
        },
      },
    }}
  >
    <DropdownItem className='btn btn-danger' onClick={() => { deletePipeline(i._id, state.pipelines[0].job._id)}}>Delete</DropdownItem>
  </DropdownMenu>
  </Dropdown>
                  </div>))}
                </div>
              </div>
              <div className='pipe-add'>
                  <div className='pipe-add-title text-center'>
                    <p>{state.pipeline_id ? `Edit Selected Stage` : "Add new Stage"}</p>
                  </div>
                  <div className='pipe-add-input'>
                  <input type="text" placeholder='add new stage' value={state.pipeline_name} onChange={e => {dispatch({type: ACTIONS.SET_PIPELINE_NAME, payload: e.target.value})}}/>
                  </div>
                  <div className='pipe-add-btn'>
                    <a href='' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_PIPELINE_NAME, payload: ""}); dispatch({type: ACTIONS.SET_PIPELINE_ID, payload: ""})}}><Icon icon="bi:plus-lg"/>New</a>
                  </div>
              </div>
              
              <div className="modal-footer">
        
                <button type="button" className="btn btn-dark" onClick={e => {e.preventDefault(); if(state.pipeline_id){updatePipeline(state.pipeline_id, state.pipelines[0].job._id)}else{createPipeline(state.pipelines[0].job._id)}}}>{state.pipeline_id ? "Update" : "Save"}</button>
              </div> </>: <div>
              <div className="d-flex justify-content-center mt-5">
              <Spinner className="" color="#230096" />
              </div>
                </div>}
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
        <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_PIPELINE, payload: []}); dispatch({type: ACTIONS.SET_OPEN_ACTIVE_PIPELINE_MODAL, payload: false}); dispatch({type: ACTIONS.SET_PIPELINE_NAME, payload: ""})}}>Close</a>
        </ModalFooter>
      </Modal>

        {/*<div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
        {state.pipelines.length > 0 ?  : null}
        </div> */}
        <button className='btn btn-dark' onClick={async e => {e.preventDefault(); fetchUserJobs(state.jobs.length+5, "")}}>Load More</button>
    </div>
  )
}


export default Active