import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import  { useAuths }  from './context';
import LoadingOverlay from 'react-loading-overlay';

export default function PrivateRoute(){
    const {state} = useAuths()
return state.google_logged_in ? 
           <LoadingOverlay
            active={state.loading}
            spinner
            text='Loading...'
            >
           <Outlet/>
           </LoadingOverlay>: <Navigate to="/authemailreply/:token"/>
}