import React from 'react'
import { useAuths, ACTIONS } from "../../../context"

function Security() {
  const {dispatch, state, update_password} = useAuths()
  return (
    <div className='scrollstuff'>
    <div>
        <h3>Security</h3>
    </div>

      <div className="container pt-5">
         
             
              <form onSubmit={update_password}>
                    <fieldset>
                      <div className="row mb-xl-1 mb-9">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="email"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Verify Email</label>
                            <input
                            required
                              type="email"
                              value={state.email}
                              onChange={e => { dispatch({type: ACTIONS.SET_EMAIL, payload: e.target.value})}}
                              className="form-control h-px-48"
                              placeholder="John@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="currentpassword"
                              className="
                                d-block
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Current Password</label>
                            <input
                            required
                            value={state.password}
                              onChange={e => { dispatch({type: ACTIONS.SET_PASSWORD, payload: e.target.value})}}
                              type="password"
                              className="form-control h-px-48"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="newpassword"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              New Password</label>
                            <input
                            required
                            value={state.new_password}
                              onChange={e => { dispatch({type: ACTIONS.SET_NEW_PASSWORD, payload: e.target.value})}}
                              type="password"
                              className="form-control h-px-48"
                              id="newpassword"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label
                              for="repeatpassword"
                              className="
                                d-block
                                text-black-2
                                font-size-4 font-weight-semibold
                                mb-4
                              "
                            >
                              Repeat New Password</label>
                            <input
                            required
                            value={state.confirm_new_password}
                              type="password"
                              onChange={e => { dispatch({type: ACTIONS.SET_CONFIRM_NEW_PASSWORD, payload: e.target.value})}}
                              className="form-control h-px-48"
                              id="repeatpassword"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <button
                            type='submit'
                            className="
                              btn btn-dark
                            ">
                              Save Changes
                          </button>
                        </div>
                        </fieldset>
                        </form>
              </div>
              </div>
  )
}

export default Security