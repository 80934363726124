import React from 'react'
//import logo from '../../assets/logo-main-black.png'
import {
  InputGroup,
  //InputGroupText,
  Input,
} from 'reactstrap'
import profile from "../../assets/profile-img.png"
import { Link } from 'react-router-dom'
import  { useAuths }  from '../../context';
import { Icon } from '@iconify/react';


export default function Navbar () {

const {isLoggedIn, signOut, currentUser} = useAuths()

if(!isLoggedIn){
    return(
      <header>
      <div className="header-left">
              <label className="nav-label" for="nav-toggle">
                  <span className="iconify-inline"><Icon icon="bx:bx-menu"/></span>
              </label>
      </div>

      <div className="header-right">
          <div className="lf">
              <Link to="/login" className="btn btn-transparent">Log In</Link>
          </div>
          <div className="jobboard-btn lf">
              <Link to="" className="btn btn-dark"> Try for free </Link>
          </div>
      </div>
      </header>
    )
}else{
    return(
        <header>
        <div className="header-left">
                <label className="nav-label" for="nav-toggle">
                    <span className="iconify-inline"><Icon icon="bx:bx-menu"/></span>
                </label>

            {/*<div className="search-wrapper lf">
              <InputGroup >
               <span className="iconify-inline"><Icon icon="bx:bx-search"/></span>
                <Input placeholder="Search..."/>
              </InputGroup>
            </div>*/}
      
            <div className="trial-btn lf">
                <Link to="" className="btn btn-dark"> Trial 14 Days left <span className="iconify-inline"><Icon icon="bx:bx-chevron-right"/></span> </Link>
            </div>
        </div>
        <div className="header-right">
            <div className="lf">
                <Link to="/add_candidate" className="btn btn-transparent"><span className="iconify-inline"><Icon icon="bi:plus-lg"/></span>New</Link>
            </div>
            <div className="jobboard-btn lf">
                <a href='https://jobboard.strivehumanresources.com/dashboard' target="_blank" className="btn btn-dark"> Jobboard<span className="iconify-inline"><Icon icon="bx:bx-chevron-right"/></span> </a>
            </div>
            <div className="lf">
                <div className="dropdown">
                  <div className="dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {currentUser.image ?  <img  className="rounded-circle" width="40px" height="40px" src={currentUser.image} alt="" /> : <img width="40px" height="40px" src={profile} alt="" />}
                  </div>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <Link to="/dashboard" className="dropdown-item">Dashboard</Link>
                    <Link to="/settings" className="dropdown-item">Settings</Link>
                    <Link to="/settings" className="dropdown-item">Edit Profile</Link>
                    <Link onClick={signOut} to="/dashboard" className="dropdown-item text-danger">Logout</Link>
                  </div>
                </div>
            </div>
        </div>
        </header>
    )}
} 