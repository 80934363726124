import React from 'react'

function Billing() {
  return (
    <div className='scrollstuff'>
    <div>
        <h3>Billing</h3>
    </div>
    
        <div className='container pt-5'>
            <div className='active_plan  bg-whitee d-flex justify-content-between'>
                <div>
                    <p className='pb-2'>Premium Package</p>
                    <h2>$5000 </h2>
                    <p className='pt-2'>Valid till 23, October 2022</p>
                </div>
                <div>
                    <button className='btn btn-dark'> Change Plan</button>
                </div>
            </div>

            <div className='pay_history pt-5'>
                <h3 className='pb-3'>Payment History</h3>

                <div className="row">
                    <div className="col-12 col-md-12 col-xs-12">
                       <div className="table-responsive">
                         <table className="table">
                          <thead>
                            <tr>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Ref</th>
                            </tr>
                          </thead>
                          <tbody className="">
                                <tr>
                                    <td>$400</td>
                                    <td>Success</td>
                                    <td>Today</td>
                                    <td>23wsds</td>
                                    <td></td>
                                </tr>
                               
                            </tbody>
                         </table>
                         
                       </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Billing