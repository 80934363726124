//import { width } from 'dom-helpers'
import React from 'react'
import empty from "../../../assets/empty.png"
import { format } from 'timeago.js';
import { useAuths } from '../../../context'


export default function Profile_tag() {
    const {state, changeStage} = useAuths()
    return (
        <div className="bg-whitee">
            <div className="text-center pt-2 pb-2 border-bottom profile-tag">
                <div className="pb-4">
                    <img width="70px" height="70px" style={{borderRadius: "35px"}} src={state.candidate.owner && state.candidate.owner.image ? state.candidate.owner.image : state.candidate.candidate_image ? state.candidate.candidate_image : empty} alt="" />
                    <h3>{state.candidate.owner ? state.candidate.owner.first_name+' '+state.candidate.owner.last_name : state.candidate.candidate_name}</h3>
                    <p>Applied {format(state.candidate.createdAt)}</p>
                </div>
            <div className="pt-4">
                <div className="pt-2 pb-4">
                    <p>change stage</p>

                    <select className="form-select mt-2 btn-dark " aria-label="chart" onChange={e => { changeStage(state.candidate.pipeline._id, e.target.value, state.candidate._id)}}>
                         {state.candidate.job ? state.candidate.job.pipeline.map(i => (<option selected={i._id === state.candidate.pipeline._id} value={i._id} >{i.name}</option>)) : <option>No Stage yet</option>}
                    </select>
                </div>
             </div>
            </div>
            <div className="pt-2 pb-2  profile-tag">
                <h3>Applied Job</h3>
                <div className="applied-jobs bg-neutral p-3 ">
                  <h3><span className="active-label mr-1"></span>{state.candidate.job ? state.candidate.job.jobrole : "Not Available"}</h3>
                  <p>{state.candidate.job ? state.candidate.job.type.type : "Not Available"}</p>
                  <div className="stages-tag p-2">
                    <div className="d-flex justify-content-between flex-end pb-3">
                        <p>Stage</p>
                        <p className="ml-auto"><span className="active-stage mr-1" > </span>{state.candidate.pipeline ? state.candidate.pipeline.name : "Not Available"}</p>
                    </div>
                    <div className="progress mb-2">
                            {state.candidate.pipeline && state.candidate.job ? state.candidate.job.pipeline.map((i, idx) => {return (<div className={state.candidate.pipeline._id == i._id ? "progress-bar mr-1 text-primary" : "progress-bar mr-1"}  role="progressbar" style={{minwidth: "100%", width: '100%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">{idx + 1}</div>)}) : null}
                            
                         </div>
                  </div>
                </div>
            </div>
        </div>
    )
}
