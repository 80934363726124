import React, { useEffect } from 'react'
import {useNavigate, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react';
import ProfileTag from './candidate/profile_tag';
import NoteTab from './candidate/note_tab';
import  Dropdowns from "react-multilevel-dropdown";
import OverviewTab from './candidate/overview-tab';
import EmailTab from './candidate/email_tab';
import CreatableSelect from 'react-select/creatable';
import FilesTab from './candidate/files_tab';
import ActivitiesTab from './candidate/activities_tab';
import { useAuths, ACTIONS, withdraw_reason_list, rejection_reason_list } from '../../context';
import {
    Modal,
    ModalBody,
    ModalHeader,
    Spinner,
    ModalFooter } from "reactstrap"

export default function Candidate_Profile() {
    const {state, fetchOneCandidate, dispatch, createEvent, handleSelectedEmail, set_event_duration, sendEmail, defaultEmailValue, disqualify_application} = useAuths()
    const location = useLocation()
    const navigate = useNavigate();

    if(state.email_text || state.email_subject || state.email_receipent.length){
    window.onbeforeunload = async () => {
            const e = window.event || state.email_text || state.email_subject || state.email_receipent.length;
            e.preventDefault();
           if (e) {
            await sendEmail(true, location.state.candidate)
            }
           return ''; 
        
      }}
    useEffect(() => {
        fetchOneCandidate(location.state.candidate)

        return() => {
            if(state.email_text || state.email_subject || state.email_receipent.length > 0){
                sendEmail(true, "")
            }else{
            
        dispatch({type: ACTIONS.SET_CURRENT_SENT_EMAIL, payload: {}})
        defaultEmailValue()
            dispatch({type: ACTIONS.SET_CANDIDATE, payload: {}})
        }}
    }, [])
    return (
        <main>
            <div>
            <div className="mb-7">
            <button onClick={(e) => {e.preventDefault(); navigate(-1)}} className="btn btn-dark"to="/candidates"><span><Icon icon="bx:bx-chevron-left"/></span> back </button>
            </div>
            <div className="row mt-2">
                <div className=" col-lg-3 mt-5">
                    <ProfileTag/>
                </div>
                <div className="col-lg-6 ">
                    <ul className="nav nav-pills  transparent mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="pills-overview-tab" data-toggle="pill" href="#pills-overview" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-email-tab" data-toggle="pill" href="#pills-email" role="tab" aria-controls="pills-email" aria-selected="false">Emails</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-files-tab" data-toggle="pill" href="#pills-files" role="tab" aria-controls="pills-files" aria-selected="false">Files</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-activity-tab" data-toggle="pill" href="#pills-activity" role="tab" aria-controls="pills-activity" aria-selected="false">Activity</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="pills-notes-tab" data-toggle="pill" href="#pills-notes" role="tab" aria-controls="pills-notes" aria-selected="false">Notes</a>
                    </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab"><OverviewTab></OverviewTab></div>
                    <div className="tab-pane fade" id="pills-email" role="tabpanel" aria-labelledby="pills-email-tab"><EmailTab candidateId={location.state.candidate} /></div>
                    <div className="tab-pane fade" id="pills-files" role="tabpanel" aria-labelledby="pills-files-tab"><FilesTab></FilesTab></div>
                    <div className="tab-pane fade" id="pills-activity" role="tabpanel" aria-labelledby="pills-activity-tab"><ActivitiesTab></ActivitiesTab></div>
                    <div className="tab-pane fade" id="pills-notes" role="tabpanel" aria-labelledby="pills-notes-tab"><NoteTab/></div>
                    </div>
                </div>
                <div className=" text-center d-flex">
                {/* <Link className="btn btn-transparent"to=""><span><Icon icon="bi:calendar" className='mr-2'/>Schedule</span></Link> */}
                <div>
                <a type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EVENT_MODAL, payload: true})}} href="/" className="btn btn-transparent" ><Icon icon="bi:calendar" className='mr-2'/>Schedule</a>
                </div>
                    <div className="dropdown">
                        <Dropdowns
                wrapperClassName=""
                className="btn btn-dark"
                title={<div>More<span><Icon icon="bx:bx-chevron-down"/></span></div>}
                >
                {!state.candidate.rejected && !state.candidate.withdraw ? <Dropdowns.Item>
                Disqualify Selected
                <Dropdowns.Submenu position='left'>
                <Dropdowns.Item >
                Applicants Rejected
                <Dropdowns.Submenu className='submenu'> 
                { rejection_reason_list.map(r => (<Dropdowns.Item key={r} onClick={e=> {e.preventDefault(); disqualify_application(location.state.candidate, "reject", r, "")}}>
                {r}
                </Dropdowns.Item>))}
                </Dropdowns.Submenu>
                </Dropdowns.Item>
                <Dropdowns.Item>
                Applicants Withdraw
                <Dropdowns.Submenu className='submenu'>
                { withdraw_reason_list.map(w => (<Dropdowns.Item key={w} onClick={e=> {e.preventDefault(); disqualify_application(location.state.candidate, "withdraw", w, "")}}>
                {w}
                </Dropdowns.Item>))}
                </Dropdowns.Submenu>
                </Dropdowns.Item>
                </Dropdowns.Submenu>
                </Dropdowns.Item> :
                <Dropdowns.Item onClick={e=> {e.preventDefault(); disqualify_application(location.state.candidate, "requalify", "", "")}}>
                Requalify Selected
                </Dropdowns.Item>}
                <Dropdowns.Item onClick={(e) => { e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_APP, payload: true})}}>
                Delete Selected
                </Dropdowns.Item>
                        </Dropdowns>
                    </div>
                </div>
                <Modal
        size="lg"
        isOpen={state.openEventModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
        <div className="modal-dialog modal-lg">
                        <div className="modal-content p-5">
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h2>Schedule Event</h2>
                                </div>
                                <div>
                                    <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EVENT_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>

                            <div className='pt-5 border-bottom'>
                            </div>
                               <div className='pipe-add-input pt-3'>
                               <p><b>Event Title</b></p>
                  <input type="text" placeholder='Event Title' value={state.event_title} onChange={e => {dispatch({type: ACTIONS.SET_EVENT_TITLE, payload: e.target.value})}}/>
                  </div>
                  <div className='row d-flex justify-content-between'>
                  <div className='pipe-add-input col-lg-6 col-md-12 pt-3'>
                  <p><b>Date</b></p>
                  <input type="date" placeholder='' value={state.event_date} onChange={e => {dispatch({type: ACTIONS.SET_EVENT_DATE, payload: e.target.value})}}/>
                  </div>
                  <div className='pipe-add-input col-lg-6 col-md-12 pt-3'>
                  <p><b>Time</b></p>
                  <input type="time" placeholder='' value={state.event_time} onChange={e => {dispatch({type: ACTIONS.SET_EVENT_TIME, payload: e.target.value})}}/>
                  </div>
                  <div className='pipe-add-input col-lg-6 col-md-12 pt-3'>
                  <p><b>Duration</b></p>
                                   <select name='duration' id="duration" className='form-group' onChange={e => { set_event_duration(e.target.value)}}>
                                       <option disabled selected>Select Duration</option>
                                        <option> 30 Minutes</option>
                                        <option> 1 Hour</option>
                        </select>
                  </div>
                  <div className='pipe-add-input col-lg-6 col-md-12 pt-3'>
                  <p><b>Type</b></p>
                  <select name='type' id="type" className='form-group' onChange={e => {dispatch({type: ACTIONS.SET_EVENT_TYPE, payload: e.target.value})}}>
                  <option disabled selected>Select Type</option>
                                        <option value="On site Interview"> On site Interview</option>
                                        {/*<option value="Zoom"> Zoom</option>*/}
                                        <option value="Google Meet"> Google Meet</option>
                                   </select>
                  </div>
                  <div className='pipe-add-input col-12 pt-3'>
                               <p><b>Event Description</b></p>
                    <textarea className="form-control" value={state.event_description} aria-label="With textarea" onChange={e => {dispatch({type: ACTIONS.SET_EVENT_DESCRIPTION, payload: e.target.value})}}></textarea>
                  </div>
                  <div className='pipe-add-input col-12 pt-3'>
                               <p><b>Attendies</b></p>
                   <CreatableSelect
                    isMulti
                    value={state.selectedEmails}
                    onChange={handleSelectedEmail}
                    options={state.emails}
                    isClearable={false}
                    isSearchable
                    placeholder="Search or type Email"
                />
                  </div>
                  
                  </div>
                  <div className='pt-3'>
            <button className='btn btn-primary' onClick={createEvent}>Create event</button>
                  </div>

                        </div>

                    </div>

        </ModalBody>
        <ModalFooter>
        <a href="" type="button" className="btn btn-transparent" data-dismiss="modal" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EVENT_MODAL, payload: false})}}>Close</a>
        </ModalFooter>
      </Modal>
      <Modal
      size="sm"
      isOpen={state.openEmailDeleteApp}
      centered
      backdrop="static"
      keyboard={false}>
          <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: []}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_APP, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
        </ModalHeader>
        <ModalBody>
        <div>
               {!state.loading ? <div className="modal-content p-2 border-0 text-center">
                            <div >
                                <div className='mb-4'>
                                    <h4>Delete Candidate?</h4>
                                </div>
                                <div className='d-flex justify-content-between'>
                                   
                                <button className='btn btn-dark' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_APP, payload: false})}}>Abort</button>
                                    <button className='btn btn-danger' style={{borderRadius: "20px"}} onClick={e => {e.preventDefault(); disqualify_application(location.state.candidate, "delete", "goback")}}>Confirm</button>
                                </div></div></div>: <Spinner/>}
             </div>
        </ModalBody>
      </Modal>
            </div>
            </div>
        </main>
    )
}
