import React, { useEffect } from 'react'
import { useAuths } from '../../context';
import { Icon } from '@iconify/react';
import LineChart from './Charts/Line_chart';
import Piechart from './Charts/pie_chart'
import Barchart from './Charts/bar_chart'
import Doughnut_Chart from './Charts/Doughnut_chart';

export default function Reports() {

    const {get_dasboard_data, state} = useAuths()

    return (
        <main>
        <div>
            <div className="mb-7">
                <h1 className="main-title">Reports</h1>
            </div>
            <div className='row'>
                 <div className="col-12 col-md-4 col-xs-12 ">
                     <div className='bg-whitee '>
                        <h2>Source</h2>
                        <Doughnut_Chart/>
                     </div>
                 </div>
                <div className="col-12 col-md-8 col-xs-12">
                     {/* cards  */}
                    <div className="cards">
                        <div className="card-single " >
                            <div className="lf card-icon cl-blue">
                                <span className="iconify-inline"><Icon icon="gridicons:multiple-users"/></span>
                              </div>
                            <div className="card-title lf">
                                <h1>{state.total_candidates}</h1>
                                <span>Total Candidates</span>
                            </div>
                        </div>
                        
                        <div className="card-single" >
                            <div className="lf card-icon cl-green">
                                <span className="iconify-inline"><Icon icon="fa6-solid:suitcase"/></span>
                              </div>
                            <div className="card-title lf">
                                <h1>14</h1>
                                <span>Active Jobs</span>
                            </div>
                        </div>

                        <div className="card-single" >
                            <div className="lf card-icon cl-yellow">
                                <span className="iconify-inline"><Icon icon="fluent:person-feedback-24-filled"/></span>
                              </div>
                            <div className="card-title lf">
                                <h1>7</h1>
                                <span>Hired Candidates</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className='cards'>
                    <div className="col-12 bg-whitee" >
                            
                            <div className="card-title lf">
                            <Barchart/>
                            </div>
                          
                        </div>
                        <div className="col-12 bg-whitee" >
                            
                            <div className="card-title lf">
                            <Piechart/>
                            </div>
                          
                        </div>
                        
                    </div>

                </div>
            </div>
          </div>
        </main>
    )
}
