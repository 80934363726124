import React from 'react'
import { Icon } from '@iconify/react';
import { useAuths } from '../../../context';
import moment from 'moment';
//import {Link } from 'react-router-dom'

export default function Activities_tab() {
    const { state } = useAuths()
    return (
        <div className="activities mt-4">
        <h2 className="main-sub-title">
            Notes
        </h2>
        <div className="activity bg-whitee">
            <ul>
                {state.candidate_notes.map(i => (<>
                <div className="card-title activity-date d-flex justify-content-between">
                <span>By {i.creator ? i.creator.isUserAdmin ? "Admin" : i.creator.recruiter_name : "N/A"}</span>
                    <span>{moment(i.createdAt).format("D MMM Y")}</span>
                </div>
                <li>
                    <div className="activity-tag">
                        <div className="activity-icon">
                            <span className="iconify-inline"><Icon icon="carbon:chat"/> </span>
                        </div>
                        <div className="activity-text">
                        {i.text}
                        </div>
                    </div>
                </li>
                </>))}
            </ul>
        </div>
    </div>
    )
}