import React from 'react';
import {Line} from 'react-chartjs-2';
import { useAuths } from '../../../context';



const Chart = () => {
  const {state} = useAuths()
  const states = {
    labels: state.dashboard_labels,
    datasets: [{
      label: state.dashboard_label,
      data: state.dashboard_data,
      backgroundColor:  'rgba(37, 75, 209, 0.5)',
      borderColor: [
          '#254BD1',
      ],
      borderWidth: 0.4,
      fill: true, // 3: no fill
      pointBorderWidth: 3,
      pointRadius: [4, 4, 4, 4, 4,4, 4, 4, 4, 4,4, 4, 4],
      pointHoverRadius: [2, 2, 2, 2, 2,2, 2, 2, 2, 2,2, 2, 2],
      pointBackgroundColor: ['#254BD1)', '#254BD1', '#254BD1', '#254BD1','#254BD1)', '#254BD1', '#254BD1', '#254BD1','#254BD1)', '#254BD1', '#254BD1', '#254BD1','#254BD1)'],
      pointBorderColor: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff',],
  }]
  }
    return (
      <div class="line-chart">
        <Line
          data={states}
          options={{
            maintainAspectRatio: false,
                scales:{
                    x: {
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        grid: {
                            display: false
                        }
                    }
                }
                
          }}
        />
      </div>
    );
  }

  export default Chart