import React, {useState} from 'react'
import Dragable from './draggleble';
import { useDrop } from 'react-dnd'



function Test() {
const [interview, setInterView] = useState([])
const [dragList, setDragList] = useState([
                {
                    id: 1,
                    name: "Fatunde Damilare"
                },
                {
                    id: 2,
                    name: "Fatunde Darasimi"
                },
                {
                    id: 3,
                    name: "Debby Fatunde"
                }
            ])
const [application, setApplication] = useState(dragList)


 const [{isApplicationOver}, dropApplication] = useDrop(() => ({
      accept: "div",
      drop: (item) => addApplication(item.id),
      collect: (monitor) => ({
            isApplicationOver: !!monitor.isOver()
        })
    }))

const [{isInterViewOver}, dropInterView] = useDrop(() => ({
      accept: "div",
      drop: (item) => addInterView(item.id),
      collect: (monitor) => ({
            isInterViewOver: !!monitor.isOver()
        })
    }))
 const addApplication = async (id) => {
    const dragLists =  await dragList.filter((i) => id === i.id);
    const dragListsUpdate = await dragList.filter((i) => id !== i.id);

    await setApplication((data) => [...data, dragLists[0]])
    await setDragList(dragListsUpdate)
    await setDragList((data) => data.filter((i) => id !== i.id))

 }
const addInterView = async (id) => {
    const dragLists =  await dragList.filter((i) => id === i.id);
    const dragListsUpdate = await dragList.filter((i) => id !== i.id);

    await setInterView((data) => [...data, dragLists[0]])
    await setDragList(dragListsUpdate)
    await setDragList((data) => data.filter((i) => id !== i.id))

 }

  return (
    <>
    
    <div className="row m-3">
    <div className="boards m-3" style={{border: isApplicationOver ? '2px solid green' : '2px solid #dee2e6'}} ref={dropApplication}>
    <div className="boarder-header">Applications</div>
        {
         application.map(i => {
            return (
                <Dragable key={i.id} id={i.id} name={i.name}/>
            )
        })
    }</div>  
        
    <div className="boards m-3" style={{border: isInterViewOver ? '2px solid green' : '2px solid #dee2e6'}} ref={dropInterView}>
    <div className="boarder-header">Interviews</div>
    {
         interview.map(i => {
            return (
                <Dragable id={i.id} name={i.name}/>
            )
        })
    }</div></div>
    </>
  );
}

export default Test;
