import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import { useAuths } from '../../../context';


const Doughnut_Chart = () => {
    const {state} = useAuths()
    const states = {
        labels: [
        //   'Red',
        //   'Blue',
        //   'Yellow'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [80, 20],
          backgroundColor: [
            '#1790FF',
            '#6FF1CA'
          ],
          hoverOffset: 4
        }]
      };

  return(
    <div className='barchart'>
       <Doughnut
          data={states}
          options={{
            title:{
              display:true,
              text:'Top companies and their job posts',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
    </div>
  )
}

export default Doughnut_Chart