import React from 'react'
import { GoogleLogin } from 'react-google-login';
import { useParams } from 'react-router-dom';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useAuths} from "../../context"

const AuthEmailreply = () =>{
    const {signEmailIn} = useAuths()
    const param = useParams()
    const token = param.token
   
    const failureGoogleLogin = () => {
       alert("could not login")
    }

    return(
        <GoogleLogin
        clientId="186240833315-c9hqbdv82mei9iuvcf91bffk4gjlgh0h.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={(res) => signEmailIn(res.profileObj.email, token)}
        onFailure={() => failureGoogleLogin}
        cookiePolicy={'single_host_origin'}
      /> )
    }
    
  
export default AuthEmailreply