import React, { useEffect, useState } from 'react'
import { InputGroup, Input, Form, Fade, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import _ from "lodash"
import Dropdowns from 'react-multilevel-dropdown';
import { useAuths, ACTIONS, withdraw_reason_list, rejection_reason_list } from '../../context'
import Paginating from 'components/pagination'
import empty from "../../assets/empty.png"
import moment from 'moment'



export default function Candidates() {
const {state, fetchCandidate, dispatch, fetchUserJobs, disqualify_application, handleSelectedApplications} = useAuths()

const [dataPerPage] = React.useState(5)
const [currentPage, setCurrentPage] = React.useState(1)
const indexOfLastPost = currentPage * dataPerPage;
const indexOfFirstPage = indexOfLastPost - dataPerPage;
const currentData = state.candidates.slice(indexOfFirstPage, indexOfLastPost);
  
const paginate = (pageNumber) => {
  setCurrentPage(pageNumber)
}
const previousPage = () => {
  if(currentPage - 1 > 0){
    setCurrentPage(currentPage - 1)
  }
}
const nextPage = (dataNumber) => {
  if(currentPage + 1 <= dataNumber){
    setCurrentPage(currentPage + 1)
  }
}



    useEffect(() => {
        fetchCandidate(undefined, "")
        fetchUserJobs("all", "")
        return() => {
        fetchCandidate(undefined, "")
        fetchUserJobs("all", "")
        }
    }, [])

    return (
        <main>
        <div>
                <div className="mb-7">
                    <h1 className="main-title">Candidates</h1>
                </div>
                
                <div className="d-flex justify-content-between align-items-center">
                    <div className="candidate-meta">
                        <p>{`1 - ${state.candidates.length} of ${state.total_candidates}` } candidates</p>
                    </div>
                    <div className="candidate-toolbar d-flex">
                        <div className="lf">
                            <Link to="/add_candidate" className="btn btn-transparent"><span className="iconify-inline"><Icon icon="bi:plus-lg"/></span>Add Candidate</Link>
                        </div>
                        <div className="search-wrapper w-100 lf">
                            <Form onSubmit={(e) => {e.preventDefault(); fetchCandidate(undefined, "")}}>
                        <InputGroup >
                        <span className="iconify-inline"><Icon icon="bx:bx-search"/></span>
                            <Input onChange={(e) => {dispatch({type: ACTIONS.SET_SEARCH_CANDIDATE_QUERY, payload: e.target.value})}} on placeholder="Search..." required />
                        </InputGroup>
                        </Form>
                         </div>
                         <div className="trial-btn lf">
                   
  <Dropdowns
  wrapperClassName=""
  className="btn btn-dark"
  title={<div><span className="iconify-inline"><Icon icon="bx:bx-filter"/></span>  Filter <span className="iconify-inline"><Icon icon="bx:bx-chevron-down"/></span></div>}
>
    {
        state.candidate_filter_text.id ? <Dropdowns.Item onClick={e => {e.preventDefault(); fetchCandidate(undefined, ""); dispatch({type: ACTIONS.SET_CANDIDATE_FILTER_TEXT, payload : ""})}}>None</Dropdowns.Item> : null
    }
  <Dropdowns.Item>
    Job
    <Dropdowns.Submenu 
  position='top'
  >
    {state.jobs.map(i => (<Dropdowns.Item key={i._id} className='over-flo' onClick={e => {e.preventDefault(); fetchCandidate(undefined, {id: i._id, name: i.jobrole, type: "job"})}}>
       {i.jobrole}
      </Dropdowns.Item>))}
      </Dropdowns.Submenu>
  </Dropdowns.Item>
  <Dropdowns.Item>
    Stages
    <Dropdowns.Submenu>
    {state.pipeline_list.map(i => (<Dropdowns.Item key={i._id} className='over-flo' onClick={e => {e.preventDefault(); fetchCandidate(undefined, {id: i._id, name: i.name, type: "stage"})}}>
       {i.name}
      </Dropdowns.Item>))}
    </Dropdowns.Submenu>
  </Dropdowns.Item>
</Dropdowns>
 <div></div>

                        </div>
                    </div>
                </div>
                <Fade in={state.candidate_filter_text.name ? true : false}><span> You are filtering with {state.candidate_filter_text.name}</span></Fade>
              <Fade className='mt-3' in={state.selected_applications.length > 0 ? true : false}><Dropdowns
                wrapperClassName=""
                className="btn btn-dark"
                title={<div><span className="iconify-inline"></span> Bulk Action <span className="iconify-inline"><Icon icon="bx:bx-chevron-down"/></span></div>}
                >
    <Dropdowns.Item>
     Disqualify Selected
    <Dropdowns.Submenu position='right'>
    <Dropdowns.Item >
    Applicants Rejected
    <Dropdowns.Submenu className='submenu'> 
    { rejection_reason_list.map(r => (<Dropdowns.Item key={r} onClick={e=> {e.preventDefault(); disqualify_application(state.selected_applications, "reject", r, "")}}>
    {r}
    </Dropdowns.Item>))}
    </Dropdowns.Submenu>
    </Dropdowns.Item>
    <Dropdowns.Item>
    Applicants Withdraw
    <Dropdowns.Submenu className='submenu'>
    { withdraw_reason_list.map(w => (<Dropdowns.Item key={w} onClick={e=> {e.preventDefault(); disqualify_application(state.selected_applications, "withdraw", w, "")}}>
    {w}
    </Dropdowns.Item>))}
    </Dropdowns.Submenu>
    </Dropdowns.Item>
    </Dropdowns.Submenu>
    </Dropdowns.Item>
    <Dropdowns.Item onClick={e=> {e.preventDefault(); disqualify_application(state.selected_applications, "requalify", "", "")}}>
    Requalify Selected
    </Dropdowns.Item>
    <Dropdowns.Item onClick={(e) => { e.preventDefault(); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}}>
    Delete Selected
    </Dropdowns.Item>
            </Dropdowns> </Fade> 
                <div className="row">
                    <div className="col-12 col-md-12 col-xs-12 bg-whitee">
                       <div className="table-responsive">
                         <table className="table">
                          <thead>
                            <tr>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold">
                                <input className="checkbox " checked={state.selected_applications.length > 0 && state.selected_applications.length === state.candidates.length ? true : false} type="checkbox" onChange={() => {handleSelectedApplications("all", "application")}} /> 
                                </th>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold">Name</th>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold">Job</th>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold">Stage</th>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold">Applied Date</th>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold"> Tags</th>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold">Recruiter</th>
                                <th scope="col" className="border-0 font-size-4 font-weight-bold"></th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {currentData.map(i => (<tr key={i._id}>
                                <td><input checked={state.selected_applications.includes(i._id)} onChange={() => {handleSelectedApplications(i._id, "application")}} className="checkbox"  type="checkbox" /> </td>
                                <td><Link to="/candidate_profile" state={{candidate : i._id}}>{i.owner ? i.owner.first_name +' '+i.owner.last_name : i.candidate_name} </Link></td>
                                <td>{i.job.jobrole}</td>
                                <td><button className={i.rejected || i.withdraw ? "rejected-btn tag-btn pill" : "stage-btn applied-btn pill"}><Icon icon={i.withdraw ? "clarity:employee-line" : i.rejected ? "icons8:cancel-2" : "mdi:file-send-outline"}/><span className="ml-2">{i.withdraw ? "Withdrawn" : i.rejected ? "Disqualified" : i.pipeline ? i.pipeline.name : "Not Added"}</span></button></td>
                                <td>{moment(i.createdAt).format("D MMM YYYY")}</td>
                                <td><button className={i.rejected || i.withdraw ? "rejected-btn tag-btn pill" : "tag-btn applied-btn pill"}>{i.withdraw ? i.withdraw_reason : i.rejected ? i.rejection_reason : i.tag.length > 0 ? i.tag[0].text : "Not Available"}</button></td>
                                <td>{i.owner ? _.uniqBy(i.owner.application, function (e) {return e.company.company_name}).slice(0, 3).map(c => (
                                <img src={c.company.image ? c.company.image : empty} style={{width: "30px", height: "30px", margin: "-2px", border: "5px black", borderRadius: "15px"}} alt="recruiter-img" className="recruiter-img"/>
                                )): null}</td>
                                <td>
                                 <div class="">
                                    <Dropdowns
                                        wrapperClassName=""
                                        className="bg-transparent text-dark border-0"
                                        title={<Icon icon="carbon:overflow-menu-vertical"/>}
                                        >
                                        {!i.rejected && !i.withdraw ? <Dropdowns.Item>
                                            Disqualify
                                            <Dropdowns.Submenu 
                                            position='top'
                                            >
                                          <Dropdowns.Item >
                                           Applicant Rejected
                                           <Dropdowns.Submenu className='submenu'>
                                           { rejection_reason_list.map(r => (<Dropdowns.Item key={r} onClick={e=> {e.preventDefault(); disqualify_application(i._id, "reject", r, "")}}>
                                            {r}
                                           </Dropdowns.Item>))}
                                           </Dropdowns.Submenu>
                                            </Dropdowns.Item>
                                           <Dropdowns.Item>
                                           Applicant Withdraw
                                           <Dropdowns.Submenu className='submenu'>
                                           { withdraw_reason_list.map(w => (<Dropdowns.Item key={w} onClick={e=> {e.preventDefault(); disqualify_application(i._id, "withdraw", w, "")}}>
                                            {w}
                                           </Dropdowns.Item>))}
                                           </Dropdowns.Submenu>
                                            </Dropdowns.Item>
                                            </Dropdowns.Submenu>
                                        </Dropdowns.Item> :
                                        <Dropdowns.Item onClick={e=> {e.preventDefault(); disqualify_application(i._id, "requalify", "", "")}}>
                                            Requalify
                                        </Dropdowns.Item>}
                                        <Dropdowns.Item onClick={(e) => { e.preventDefault(); handleSelectedApplications(i._id, "application"); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: true})}} >
                                            Delete
                                        </Dropdowns.Item>
                                        </Dropdowns>
                                    
                                    </div>
                                </td>
                            </tr>))}
                            {/*<tr>
                                <td><input className="checkbox " type="checkbox" /> </td>
                                <td>Hassan Fatimah</td>
                                <td>Senior Marketing Manager</td>
                                <td><button className="stage-btn interview-btn pill"><Icon icon="carbon:chat"/><span className="ml-2">Interview</span></button></td>
                                <td>26 Oct 2021</td>
                                <td><button className="tag-btn interview-btn pill">A good fit</button></td>
                                <td><img src={Recruiter} alt="recruiter-img" className="recruiter-img" /></td>
                                <td><div class="dropdown">
                                    <div type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Icon icon="carbon:overflow-menu-vertical"/>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <Link to="" className="dropdown-item">Disqualify</Link>
                                        <Link to="" className="dropdown-item">Requalify</Link>
                                        <Link to="" className="dropdown-item text-danger">Delete</Link>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><input className="checkbox " type="checkbox" /> </td>
                                <td>Sunday Omoh</td>
                                <td>Senior Marketing Manager</td>
                                <td><button className="stage-btn rejected-btn  pill"><Icon icon="icons8:cancel-2"/><span className="ml-2">Rejected</span></button></td>
                                <td>27 Oct 2021</td>
                                <td><button className="rejected-btn tag-btn pill">Lack of Skill</button></td>
                                <td><img src={Recruiter2} alt="recruiter-img" className="recruiter-img"/><img src={Recruiter3} alt="recruiter-img" className="recruiter-img"/></td>
                                <td><div class="dropdown">
                                    <div type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Icon icon="carbon:overflow-menu-vertical"/>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <Link to="" className="dropdown-item">Disqualify</Link>
                                        <Link to="" className="dropdown-item">Requalify</Link>
                                        <Link to="" className="dropdown-item text-danger">Delete</Link>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><input className="checkbox " type="checkbox" /> </td>
                                <td>Kola Jimoh</td>
                                <td>Frontend Developer</td>
                                <td><button className="hired-btn stage-btn pill"><Icon icon="clarity:employee-line"/><span className="ml-2">Hired</span></button></td>
                                <td>11 Oct 2021</td>
                                <td><button className="hired-btn tag-btn pill">Potential</button></td>
                                <td><img src={Recruiter} alt="recruiter-img" className="recruiter-img"/><img src={Recruiter2} alt="recruiter-img" className="recruiter-img"/><img src={Recruiter3} alt="recruiter-img" className="recruiter-img"/></td>
                                <td><div class="dropdown">
                                    <div type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Icon icon="carbon:overflow-menu-vertical"/>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <Link to="" className="dropdown-item">Disqualify</Link>
                                        <Link to="" className="dropdown-item">Requalify</Link>
                                        <Link to="" className="dropdown-item text-danger">Delete</Link>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><input className="checkbox " type="checkbox" /> </td>
                                <td>Sunday Omoh</td>
                                <td>Senior Marketing Manager</td>
                                <td><button className="rejected-btn stage-btn pill"><Icon icon="icons8:cancel-2"/><span className="ml-2">Rejected</span></button></td>
                                <td>27 Oct 2021</td>
                                <td><button className="rejected-btn tag-btn pill">Lack of Skill</button></td>
                                <td><img src={Recruiter2} alt="recruiter-img" className="recruiter-img"/><img src={Recruiter3} alt="recruiter-img" className="recruiter-img"/></td>
                                <td><div class="dropdown">
                                    <div type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Icon icon="carbon:overflow-menu-vertical"/>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <Link to="" className="dropdown-item">Disqualify</Link>
                                        <Link to="" className="dropdown-item">Requalify</Link>
                                        <Link to="" className="dropdown-item text-danger">Delete</Link>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><input className="checkbox " type="checkbox" /> </td>
                                <td>Sunday Omoh</td>
                                <td>Senior Marketing Manager</td>
                                <td><button className="rejected-btn stage-btn pill"><Icon icon="icons8:cancel-2"/><span className="ml-2">Rejected</span></button></td>
                                <td>27 Oct 2021</td>
                                <td><button className="rejected-btn tag-btn pill">Lack of Skill</button></td>
                                <td><img src={Recruiter2} alt="recruiter-img" className="recruiter-img"/><img src={Recruiter3} alt="recruiter-img" className="recruiter-img"/></td>
                                <td><div class="dropdown">
                                    <div type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Icon icon="carbon:overflow-menu-vertical"/>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <Link to="" className="dropdown-item">Disqualify</Link>
                                        <Link to="" className="dropdown-item">Requalify</Link>
                                        <Link to="" className="dropdown-item text-danger">Delete</Link>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><input className="checkbox " type="checkbox" /> </td>
                                <td>Sunday Omoh</td>
                                <td>Senior Marketing Manager</td>
                                <td><button className="rejected-btn stage-btn pill"><Icon icon="icons8:cancel-2"/><span className="ml-2">Rejected</span></button></td>
                                <td>27 Oct 2021</td>
                                <td><button className="rejected-btn tag-btn pill">Lack of Skill</button></td>
                                <td><img src={Recruiter2} alt="recruiter-img" className="recruiter-img"/><img src={Recruiter3} alt="recruiter-img" className="recruiter-img"/></td>
                                <td>
                                    <div class="dropdown">
                                    <div type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <Icon icon="carbon:overflow-menu-vertical"/>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                        <Link to="" className="dropdown-item">Disqualify</Link>
                                        <Link to="" className="dropdown-item">Requalify</Link>
                                        <Link to="" className="dropdown-item text-danger">Delete</Link>
                                    </div>
                                    </div>
                                </td>
                            </tr>*/}
                        </tbody>
                         </table>
                       </div>
                       <Paginating dataPerPage={dataPerPage} totalData={state.candidates.length} paginate={paginate} currentPage={currentPage} nextPage={nextPage} previousPage={previousPage} />
                {/*<button className='btn btn-dark' onClick={async e => {e.preventDefault(); fetchCandidate(state.candidates.length+5, state.candidate_filter_text ? state.candidate_filter_text : "" )}}>Load More</button>*/}

                    </div>
                </div>
                {/*<div className="d-flex justify-content-between">
                    <div>

                    </div>
                    <div>
                    <nav aria-label="Page navigation">
                        <ul className="pagination mb-0">
                            <li className="page-item">
                                <a className="page-link" onClick={e => {e.preventDefault()}} href="/">Previous</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" onClick={e => {e.preventDefault()}} href="/">1</a>
                            </li>
                            <li className="page-item active">
                                <a className="page-link" onClick={e => {e.preventDefault()}} href="/">2</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" onClick={e => {e.preventDefault()}} href="/">3</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" onClick={e => {e.preventDefault()}} href="/">4</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" onClick={e => {e.preventDefault()}} href="/">5</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" onClick={e => {e.preventDefault()}} href="/">Next</a>
                            </li>
                        </ul>
                    </nav>
                    </div>
                </div>*/}
                  <Modal
      size="sm"
      isOpen={state.openEmailDeleteModal}
      centered
      backdrop="static"
      keyboard={false}>
          <ModalHeader>
        <button type="button" onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: []}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
        </ModalHeader>
        <ModalBody>
        <div>
               {!state.loading ? <div className="modal-content p-2 border-0 text-center">
                            <div >
                                <div className='mb-4'>
                                    <h4>Delete Candidate?</h4>
                                </div>
                                <div className='d-flex justify-content-between'>
                                   
                                <button className='btn btn-dark' onClick={e => {e.preventDefault(); dispatch({type: ACTIONS.SET_SELECTED_APPLICATIONS, payload: []}); dispatch({type: ACTIONS.SET_OPEN_EMAIL_DELETE_MODAL, payload: false})}}>Abort</button>
                                    <button className='btn btn-danger' style={{borderRadius: "20px"}} onClick={e => {e.preventDefault(); disqualify_application(state.selected_applications, "delete", "", "")}}>Confirm</button>
                                </div></div></div>: <Spinner/>}
             </div>
        </ModalBody>
      </Modal>
            </div>

        </main>
    )
}
